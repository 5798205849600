import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import { KTSVG, GenericPieChart, encryptId } from '../../../_metronic/helpers';
import { formattedCurrency } from '../../../_metronic/helpers/FormatCurrency';
import BarChart from '../../../_metronic/helpers/components/GenericBarChart';
import {
  useGetProjections,
  useGetAllSeller,
  useGetDataSelect,
  useGetAccountSelect,
} from './hooks/DashboardHook';
import { Filter } from './components/Filter';
import StackedBarChart from '../../../_metronic/helpers/components/GenericStackedChart';
import { TableList } from '../../../_metronic/helpers/components/TableList';
import { EmptyResult } from '../../../_metronic/helpers/components/EmptyResult';

// SE INICIALIZA EL OBJETO DE FILTROS
const initFilters = {
  tipo_oportunidad: null,
  label_oportunidad: '',
  agente: null,
  label_agente: '',
  cuenta: null,
  label_cuenta: '',
  filter: 0,
};

// SE CREA EL COMPONENTE A EXPORTAR
const DashboardPage: FC = () => {
  // GRAFICA 1
  const [seriesData, setSeriesData] = useState<any[]>([]);
  const [categoriesData, setCategoriesData] = useState<any[]>([]);
  const [percentageData, setPercentageData] = useState<any[]>([]);
  const [montoTotalVentas, setMontoTotalVentas] = useState(0);

  // GRAFICA 2
  const [linesData, setLinesData] = useState<any[]>([]);
  const [percentageLinesData, setPercentageLinesData] = useState<any[]>([]);

  // GRAFICA 3
  const [agentesData, setAgentesData] = useState<any[]>([]);
  const [percentageAgenteData, setPercentageAgenteData] = useState<any[]>([]);

  // GRAFICA 4
  const [agentesProyData, setAgentesProyData] = useState<any[]>([]);
  const [agentesProyCountData, setAgentesProyCountData] = useState<any[]>([]);
  const [totalProyectos, setTotalProyectos] = useState('');

  // GRAFICA 5
  const [agentesSalesData, setAgentesSalesData] = useState<any[]>([]);
  const [agentesMontoSalesData, setAgentesMontoSalesData] = useState<any[]>([]);

  // GRID
  const [gridData, setGridData] = useState<any[]>([]);

  // SE INICIALIZA EL ESTADO DEl PRELOAD
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // SE INICIALIZA EL ESTADO DE LA CARGA DE LOS FILTROS
  const [initFilterValues, setInitFilterValues] = useState(initFilters);

  // SE OBTIENE LA INFORMACION DE LOS SELECT A FILTRAR
  const { data: sellers } = useGetAllSeller();
  const { data: typeOfOpportunities } = useGetDataSelect('TOP');
  const { data: accounts } = useGetAccountSelect();

  // OBTIENE LA INFORMACION DE LAS GRAFICAS
  const { dataChart, loadingRequest } = useGetProjections(initFilterValues);

  useEffect(() => {
    if (loadingRequest > 0) {
      setIsLoading(false);
    }
  }, [loadingRequest]);

  useEffect(() => {
    if (dataChart) {
      const {
        proyecciones: { series, categories, porcentajeCambio, total },
        businessLine: { lineasNegocio, porcentajes: perc_line },
        distByAgent: { agentes, porcentajes },
        countByAgent: { agentes: agentesCount, countVentas, totalProyectos },
        salesByAgent: { montoVentas, agentes: _agen },
        dataGrid,
      } = dataChart;

      // GRAFICA 1
      setSeriesData(series);
      setCategoriesData(categories);
      setPercentageData(porcentajeCambio);
      setMontoTotalVentas(total);

      // GRAFICA 2
      setLinesData(lineasNegocio);
      setPercentageLinesData(perc_line);

      // GRAFICA 3
      setAgentesData(agentes);
      setPercentageAgenteData(porcentajes);

      // GRAFICA 4
      setAgentesProyData(agentesCount);
      let countData = [];
      countData.push(countVentas);
      setAgentesProyCountData(countData);
      setTotalProyectos(totalProyectos.toString());

      // GRAFICA 5
      setAgentesSalesData(_agen);
      let montosData = [];
      montosData.push(montoVentas);
      setAgentesMontoSalesData(montosData);

      // GRID
      setGridData(dataGrid);
    }
  }, [dataChart]);

  // MANEJADOR DE INFORMACIÓN A BUSCAR
  const onSearchFilter = (values: any) => {
    setIsLoading(true);
    if (values.filter === 1) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues(initFilters);
    }
  };

  // MANEJADOR DE LIMPIEZA EN LOS FILTROS
  const onCleanFilter = () => {
    setIsLoading(true);
    setInitFilterValues(initFilters);
  };

  const columns: any = [
    {
      id: 'folio',
      name: <div className="fw-bolder text-muted me-5">Folio</div>,
      selector: (row: any) => row.id,
      cell: (row: any) => (
        <Link to={`/oportunidades_edit/${encryptId(row.id.toString())}`}>
          {row.id}
        </Link>
      ),
      sortable: true,
      width: '100px',
    },
    {
      id: 'oportunidad',
      name: <div className="fw-bolder text-muted me-5">Oportunidad</div>,
      selector: (row: any) => row.nombre,
      cell: (row: any) => (
        <Link to={`/oportunidades_edit/${encryptId(row.id.toString())}`}>
          {row.nombre}
        </Link>
      ),
      sortable: true,
      width: '250px',
    },
    {
      id: 'cuenta',
      name: <div className="fw-bolder text-muted me-5">Cuenta</div>,
      selector: (row: any) => row.cuenta.nombre,
      sortable: true,
      width: '250px',
    },
    {
      id: 'sub_cuenta',
      name: <div className="fw-bolder text-muted me-5">Subcuenta</div>,
      selector: (row: any) => (row.subCuenta ? row.subCuenta.nombre : 'N/A'),
      sortable: true,
      width: '250px',
    },
    {
      id: 'tipo',
      name: <div className="fw-bolder text-muted me-5">Tipo</div>,
      selector: (row: any) => row.tipoOportunidad?.name,
      sortable: true,
      width: '350px',
    },
    {
      id: 'etapa',
      name: <div className="fw-bolder text-muted me-5">Etapa</div>,
      selector: (row: any) => row.etapa?.name,
      sortable: true,
      width: '250px',
    },
    {
      id: 'estatus',
      name: <div className="fw-bolder text-muted me-5">Estatus</div>,
      selector: (row: any) => row.status?.name,
      sortable: true,
      width: '200px',
    },
    {
      id: 'vendedor',
      name: <div className="fw-bolder text-muted me-5">Vendedor</div>,
      selector: (row: any) =>
        `${row.vendedorComercial.nombre} ${row.vendedorComercial.apellido_paterno} ${row.vendedorComercial.apellido_materno}`,
      sortable: true,
      width: '250px',
    },
    {
      id: 'anio',
      name: <div className="fw-bolder text-muted me-5">Año</div>,
      selector: (row: any) => row.anio,
      sortable: true,
      width: '100px',
    },
  ];

  return (
    <>
      <h1 className="fw-semibold text-gray-800 text-center lh-lg">
        {
          isLoading ? (
            <span className="indicator-progress text-primary" style={{ display: 'block' }}>
              <div className="text-center mt-3">
                <span className="spinner-border spinner-border-lg align-middle ms-2"></span>
              </div>
            </span>
          ) : (
            <>
              Proyección total de ventas
              <span className="text-primary "> {categoriesData[0]} </span>a
              <span className="text-primary "> {categoriesData[11]}</span>.
            </>
          )
        }


      </h1>
      <div className="row">
        <div className="col-10">
          <div className="d-flex align-items-center">
            {initFilterValues.filter === 1 && (
              <>
                {initFilterValues.tipo_oportunidad != null && (
                  <a
                    href="#/"
                    className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4"
                  >
                    <span
                      onClick={() => {
                        setIsLoading(true);
                        setInitFilterValues({
                          ...initFilterValues,
                          tipo_oportunidad: null,
                        });
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen040.svg"
                        className="svg-icon-3"
                      />
                    </span>

                    {initFilterValues.label_oportunidad}
                  </a>
                )}
                {initFilterValues.cuenta !== null && (
                  <a
                    href="#/"
                    className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4"
                  >
                    <span
                      onClick={() => {
                        setIsLoading(true);
                        setInitFilterValues({
                          ...initFilterValues,
                          cuenta: null,
                        });
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen040.svg"
                        className="svg-icon-3"
                      />
                    </span>

                    {initFilterValues.label_cuenta}
                  </a>
                )}
                {initFilterValues.agente !== null && (
                  <a
                    href="#/"
                    className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4"
                  >
                    <span
                      onClick={() => {
                        setIsLoading(true);
                        setInitFilterValues({
                          ...initFilterValues,
                          agente: null,
                        });
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen040.svg"
                        className="svg-icon-3"
                      />
                    </span>

                    {initFilterValues.label_agente}
                  </a>
                )}
              </>
            )}
          </div>
        </div>
        <div className="col-2">
          <Filter
            initFilterValues={initFilterValues}
            onCleanFilter={onCleanFilter}
            onSearchFilter={onSearchFilter}
            filterSellers={sellers}
            filterTypes={typeOfOpportunities}
            filterAccounts={accounts}
          />
        </div>
      </div>
      <div className={`row g-5 g-xl-10 mb-5 mb-xl-10`}>
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-9">
          {!isLoading ? (
            <StackedBarChart
              categories={categoriesData}
              series={seriesData}
              porcentajes={percentageData}
              title={`${formattedCurrency(montoTotalVentas)}`}
              description=""
            />
          ) : (
            <EmptyResult title="" loading={isLoading} />
          )}
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
          {!isLoading ? (
            <GenericPieChart
              type="donut"
              className="mb-0 "
              title={'Líneas de negocio'}
              description={''}
              series={percentageLinesData}
              labels={linesData}
              height={250}
              legend={false}
            />
          ) : (
            <EmptyResult title="" loading={isLoading} />
          )}
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
          {!isLoading ? (
            <GenericPieChart
              type="donut"
              className="mb-0 "
              title={'Distribución por agente'}
              description={''}
              series={percentageAgenteData}
              labels={agentesData}
              height={250}
              legend={true}
            />
          ) : (
            <EmptyResult title="" loading={isLoading} />
          )}
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
          {!isLoading ? (
            <BarChart
              categories={agentesProyData}
              series={agentesProyCountData}
              title="Proyectos por agente"
              description={totalProyectos}
              isPrice={false}
              position={true}
              montos={[]}
              showSubtitle={false}
            />
          ) : (
            <EmptyResult title="" loading={isLoading} />
          )}
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
          {!isLoading ? (
            <BarChart
              categories={agentesSalesData}
              series={agentesMontoSalesData}
              title="Proyecciones por agente"
              description=""
              isPrice={true}
              position={true}
              montos={[]}
              showSubtitle={false}
            />
          ) : (
            <EmptyResult title="" loading={isLoading} />
          )}
        </div>
      </div>
      <div className={`row gx-5 gx-xl-10`}>
        <div className="col-xl-12">
          <div className={`card mb-0 mb-xxl-8 h-100`}>
            {!isLoading ? (
              <TableList data={gridData} columns={columns}></TableList>
            ) : (
              <EmptyResult title="" loading={isLoading} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const DashboardProyeccionesWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Proyecciones'}</PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardProyeccionesWrapper };
