import React, { useState, useEffect } from "react";
import { CategoryModel, UpdServiceModel } from "../models/QuotationModel";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  getCategories,
  insertCategoryService,
  createServiceShorcut,
  updService,
  getFilterSupplier,
  getTaxes,
  uploadImageService,
  uploadDocService,
  removeDocService,
  getAllServices,
  removeService,
} from "../../../service/services/Services";

export const useGetCategoryServ = (realoadGrid: number) => {
  const [dataCatServ, setDataCatServ] = useState<CategoryModel[]>([]);
  const [loadingRequestServ, setLoadingRequestServ] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequestServ(0);
      const res = await getCategories()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataCatServ(res.data);
        setLoadingRequestServ(1);
      }
    };
    // fetchPost();
    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid]);

  return { dataCatServ, loadingRequestServ };
};

export const useCreateCategoryServices = () => {
  const [loadingCreateCategoryServ, setLoadingCreateCategoryServ] =
    useState<boolean>(false);
  const [isCorrectInsertCS, setIsCorrectInsertCS] = useState<boolean>(false);

  const createCategoryService = async (category: CategoryModel) => {
    setLoadingCreateCategoryServ(true);
    try {
      await insertCategoryService(category).then(() => {
        setLoadingCreateCategoryServ(false);
        setIsCorrectInsertCS(true);
      });
    } catch (err: any) {
      setIsCorrectInsertCS(false);
      let resMessageToast: string = "";
      const {
        data: { message },
      } = err.response;
      resMessageToast = `${message}`;
      toast.error(resMessageToast, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingCreateCategoryServ(false);
    }
  };
  return {
    createCategoryService,
    loadingCreateCategoryServ,
    isCorrectInsertCS,
  };
};

export const useInsertServiceShortcut = () => {
  const [loadingInsertserviceShort, setLoadingServiceShort] =
    useState<boolean>(false);
  const [isCorrectInsert, setIsCorrectInsert] = useState<boolean>(false);

  const insertServiceShort = async (
    serviceName: string,
    categoryId: number
  ) => {
    setLoadingServiceShort(true);
    try {
      await createServiceShorcut(serviceName, categoryId).then(() => {
        setLoadingServiceShort(false);
        setIsCorrectInsert(true);
      });
    } catch (err: any) {
      setIsCorrectInsert(false);

      let resMessageToast: string = "";
      const {
        data: { message },
      } = err.response;
      resMessageToast = `${message}`;
      toast.error(resMessageToast, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingServiceShort(false);
      throw resMessageToast;
    }
  };
  return { insertServiceShort, loadingInsertserviceShort, isCorrectInsert };
};

export const useUpdateServiceShortcut = () => {
  const [loadingUpdateServShort, setLoadingUpdateServShort] =
    useState<boolean>(false);
  const [isCorrectUpdateS, setIsCorrectUpdateS] = useState<boolean>(false);
  const errorResponse = {
    error: true,
  };

  const updateServiceShort = async (body: UpdServiceModel) => {
    setLoadingUpdateServShort(true);
    try {
      await updService(body).then(() => {
        setLoadingUpdateServShort(false);
        setIsCorrectUpdateS(true);
      });
    } catch (err: any) {
      setIsCorrectUpdateS(false);

      let resMessageToast: string = "";
      const {
        data: { message },
      } = err.response;
      resMessageToast = `${message}`;
      toast.error(resMessageToast, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingUpdateServShort(false);
      throw errorResponse;
    }
  };
  return { updateServiceShort, loadingUpdateServShort, isCorrectUpdateS };
};

const formmatedDataToSelect = (data: any) => {
  const selectOptions = data.map((option: any) => ({
    value: option.id,
    label: option.nombre,
  }));

  return selectOptions;
};

export const useGetProveedorFilter = () => {
  const [suppDataSelect, setSuppDataSelect] = useState<any[]>([
    {
      value: "new",
      label: "Agregar Proveedor",
    },
  ]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      const res = await getFilterSupplier()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setLoadingRequest(false);
        let options: any = [];

        options = formmatedDataToSelect(res.data);
        setSuppDataSelect((prevState) => [
          {
            value: "new",
            label: "Agregar Proveedor",
          },
          ...options,
        ]);
        setLoadingRequest(true);
      }
    };

    fetchPost();
  }, []);

  return { suppDataSelect, loadingRequest };
};

export const useGetTaxes = () => {
  const [data, setData] = useState<any[]>([]);
  const [loadingGetTaxes, setLoadingGetTaxes] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingGetTaxes(false);
      const res = await getTaxes()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setData(res);
        setLoadingGetTaxes(false);
      }
    };
    fetchPost();
  }, [loadingGetTaxes]);

  return { data, loadingGetTaxes };
};

export const useUploadImageServ = () => {
  const [loadingUploadImg, setLoadingUploadImg] = useState<boolean>(false);
  const [uploadImgCorrect, setUploadImgCorrect] = useState<boolean>(false);

  const insertImageServ = async (file: any, id: any) => {
    const _file = file[0];
    setLoadingUploadImg(true);
    try {
      await uploadImageService(id, _file);
      setLoadingUploadImg(false);
      setUploadImgCorrect(true);
    } catch (err: any) {
      setUploadImgCorrect(false);
      let resMessageToast: string = "";
      const {
        data: { message },
      } = err.response;
      resMessageToast = `${message}`;
      toast.error(resMessageToast, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingUploadImg(false);
    }
  };
  return { insertImageServ, loadingUploadImg, uploadImgCorrect };
};

export const useUploadDocServ = () => {
  const [loadingUploadDoc, setLoadingUploadDoc] = useState<boolean>(false);
  const history = useHistory();
  const insertDocService = async (file: any, id: any) => {
    const _file = file[0];
    setLoadingUploadDoc(false);
    try {
      await uploadDocService(id, _file);
      setLoadingUploadDoc(false);
    } catch (err: any) {
      let resMessageToast: string = "";
      const {
        data: { message },
      } = err.response;
      resMessageToast = `${message}`;
      toast.error(resMessageToast, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingUploadDoc(false);
    }
  };
  return { insertDocService, loadingUploadDoc };
};

export const removeFile = async (fileId: number, event: any) => {
  event.preventDefault();
  event.stopPropagation();

  await removeDocService(fileId)
    .then((res) => {
      toast.success(`${res.data.message}`, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    .catch((err) => {
      let resMessageToast: string = "";
      const {
        data: { message },
      } = err.response;
      resMessageToast = `${message}`;
      // setUploadLoading(false);
      toast.error(resMessageToast, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
};

export const useGetAllServ = (realoadGrid: number) => {
  const [dataService, setDataService] = useState<CategoryModel[]>([]);
  const [loadingRequestServices, setLoadingRequestServices] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequestServices(0);
      const res = await getAllServices()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataService(res.data);
        setLoadingRequestServices(1);
      }
    };
    // fetchPost();
    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid]);

  return { dataService, loadingRequestServices };
};

export const useRemoveService = () => {
  const [isDeletedCorrect, setIsDeletedCorrect] = useState<boolean>(false);

  const removeServices = async (service: number) => {
    try {
      await removeService(service)
        .then((res) => {
          setIsDeletedCorrect(true);
          toast.success(`${res.data.message}`, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((err) => {
          setIsDeletedCorrect(false);
          let resMessageToast: string = "";
          const {
            data: { message },
          } = err.response;
          resMessageToast = `${message}`;
          toast.error(resMessageToast, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } catch (err: any) {
      setIsDeletedCorrect(false);
      toast.error(err.message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return { removeServices, isDeletedCorrect };
};
