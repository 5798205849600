import axios from "axios";
import { formattCoin } from "../../../_metronic/helpers/FormatCurrency";
import {
  CategoryModel,
  UpdProductsModel,
} from "../../pages/cotizacion/models/QuotationModel";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { ProductModel } from "../../pages/products/models/ProductModel";
import { NoteProductModel } from "../../pages/products/models/NoteProductModel";
import { SelectFormat } from "../../../_metronic/helpers/models/GenericModel";

const API_URL = process.env.REACT_APP_API;
const API_URL_IMAGE_PUBLIC = `${process.env.REACT_APP_PUBLIC}producto/`;

export async function getCategories(filters: any = null) {
  let campo_id = null;
  let valor = null;
  if (filters) {
    if (filters.campo_id) {
      campo_id = filters.campo_id;
    }
    if (filters.valor) {
      valor = filters.valor;
    }
  }
  const response = await axios.post(`${API_URL}productos/group/category`, {
    campo_id,
    valor,
  });
  const data = response.data.doc.data;
  data.forEach((categoria: any) => {
    categoria.items.forEach((item: any) => {
      item.precio_venta = formattCoin(item.precio_venta);
    });
  });
  return {
    data: data,
  };
}

export async function insertCategoryProducts(category: CategoryModel) {
  const response = await axios.post(`${API_URL}categoria/productos`, {
    nombre: category.nombre,
  });
  return response;
}

export async function createProductShorcut(
  productName: string,
  categoryId: number
) {
  const response = await axios.post(`${API_URL}productos/shortcut/add`, {
    nombre: productName,
    categoria_id: categoryId,
  });
  return response;
}

export async function updProducts(body: UpdProductsModel) {
  const {
    id,
    nombre = null,
    unidad_medida_venta_id = null,
    unidad_moneda_venta_id = null,
    impuesto_id = null,
    impuesto_siglas = null,
    impuesto_descripcion = null,
    impuesto_porcentaje = null,
    precio_fijo_venta = null,
    inventario = null,
    proveedor_id = null,
    proveedor = null,
    nombre_proveedor = null,
  } = body;

  const data = {
    nombre,
    unidad_medida_venta_id,
    unidad_moneda_venta_id,
    impuesto_id,
    impuesto_siglas,
    impuesto_descripcion,
    impuesto_porcentaje,
    precio_fijo_venta,
    inventario,
    proveedor_id,
    proveedor,
    nombre_proveedor,
  };

  const filteredData = Object.entries(data).reduce((acc: any, [key, value]) => {
    if (value !== null && value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {});

  const response = await axios.patch(
    `${API_URL}productos/shortcut/update/${id}`,
    filteredData
  );
  return response;
}

export async function getFilterSupplier() {
  const response = await axios.get(`${API_URL}proveedores/`);

  const data = response.data.doc.data;
  return {
    data: data,
  };
}

export async function getTaxes() {
  const response = await axios.get(`${API_URL}catalogs/cotizaciones/impuestos`);
  const data = response.data.doc.data;
  // const count = response.data.doc.data.count;
  return data;
}

export const uploadImageProduct = async (id: number, file: any) => {
  const formData = new FormData();
  formData.append("image", file);

  const response = await axios.post(
    `${API_URL}productos/shortcut/image/${id}`,
    formData
  );
  return response;
};

export const uploadDocProduct = async (id: number, file: any) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await axios.post(
    `${API_URL}productos/shortcut/documento/${id}`,
    formData
  );
  return response;
};

export const downloadDoc = (fileId: number, fileName: string, event: any) => {
  event.preventDefault();
  event.stopPropagation();

  axios({
    url: `${process.env.REACT_APP_API}productos/shortcut/download/documento/${fileId}`,
    method: "GET",
    responseType: "blob", // Asegura que la respuesta se maneje como un Blob
  })
    .then((response) => {
      // Asegúrate de obtener el tipo de contenido del archivo desde el encabezado de la respuesta
      const contentType = response.headers["content-type"];
      const urlBlob = window.URL.createObjectURL(
        new Blob([response.data], { type: contentType })
      );
      const link = document.createElement("a");
      link.href = urlBlob;
      link.setAttribute("download", fileName);
      document.body.appendChild(link); // Esto es necesario para que el link funcione en ciertos navegadores
      link.click();
      window.URL.revokeObjectURL(urlBlob);
      document.body.removeChild(link); // Limpieza después de la descarga
    })
    .catch(async (error) => {
      // Manejo de errores
    });
};

export const removeDocProduct = async (docId: number) => {
  const response = await axios.delete(
    `${API_URL}productos/shortcut/documento/${docId}`
  );
  return response;
};

export async function getAllProducts(filters: any = null) {
  let campo_id = null;
  let valor = null;
  if (filters) {
    if (filters.campo_id) {
      campo_id = filters.campo_id;
    }
    if (filters.valor) {
      valor = filters.valor;
    }
  }
  const response = await axios.post(`${API_URL}productos/`, {
    campo_id,
    valor,
  });
  const data = response.data.doc.data;
  // const count = response.data.doc.data.count;
  return {
    data: data,
  };
}

// -----CATALOGO CATEGORIAS-------
export async function getCategoriesGrid() {
  const response = await axios.get(`${API_URL}categoria/productos`);
  const data = response.data.doc.data;
  return data;
}

export async function getDataCategoryById(id_row: number) {
  const response = await axios.get(`${API_URL}categoria/productos/${id_row}`);
  const data = response.data.doc.data;
  return data;
}

export async function addValueCategory(body: any) {
  const response = await axios.post(`${API_URL}categoria/productos`, {
    nombre: body.nombre,
    orden: body.orden,
  });
  return response;
}

export async function updValueCategory(body: any) {
  const data = {
    nombre: body.nombre,
    orden: body.orden,
  };

  const response = await axios.patch(
    `${API_URL}categoria/productos/${body.id}`,
    data
  );
  return response;
}

export async function removeRegisterCategory(id: number) {
  const response = await axios.delete(`${API_URL}categoria/productos/${id}`);

  return response;
}

// ------------------CATALOGO subCATEGORIAS------------------

export async function getAllSubCategoriesProducts() {
  const response = await axios.get(`${API_URL}subcategoria/productos`);
  const data = response.data.doc.data;
  return data;
}

export async function getSubCategoriesGrid(categoryId?: number) {
  let cat: any = "";

  if (categoryId) {
    cat = categoryId;
  }

  const response = await axios.get(
    `${API_URL}subcategoria/productos/categoria/${cat}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function getAllSubCategoriesGrid() {
  const response = await axios.get(`${API_URL}subcategoria/productos/`);
  const data = response.data.doc.data;
  return data;
}

export async function getDataSubCategoryById(id_row: number) {
  const response = await axios.get(
    `${API_URL}subcategoria/productos/${id_row}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function addValueSubCategory(body: any) {
  const response = await axios.post(`${API_URL}subcategoria/productos`, {
    nombre: body.nombre,
    orden: body.orden,
    categoria_id: body.categoria_id,
  });
  return response;
}

export async function updValueSubCategory(body: any) {
  const data = {
    nombre: body.nombre,
    orden: body.orden,
    categoria_id: body.categoria_id,
  };

  const response = await axios.patch(
    `${API_URL}subcategoria/productos/${body.id}`,
    data
  );
  return response;
}

export async function removeRegisterSubCategory(id: number) {
  const response = await axios.delete(`${API_URL}subcategoria/productos/${id}`);

  return response;
}

//--------------- Esto pertenece a paquetes ----------------//

export async function getCategoriesPackage() {
  const response = await axios.get(
    `${API_URL}cotizacion/paquete/group/category`
  );
  const data = response.data.doc.data;
  // const count = response.data.doc.data.count;
  return {
    data: data,
  };
}

export async function insertCategoryPackage(category: CategoryModel) {
  const response = await axios.post(
    `${API_URL}cotizacion/catalogo/categoria/paquete`,
    {
      nombre: category.nombre,
    }
  );
  return response;
}

export async function getAllPackages() {
  const response = await axios.post(`${API_URL}cotizacion/paquete`);
  const data = response.data.doc.data.rows;
  // const count = response.data.doc.data.count;
  return {
    data: data,
  };
}

//---------------------------------------------------//

export async function getAllQuotation() {
  const response = await axios.post(`${API_URL}cotizaciones/`);
  const data = response.data.doc.data;
  // const count = response.data.doc.data.count;
  return {
    data: data,
  };
}

export const removeProduct = async (productId: number) => {
  const response = await axios.delete(`${API_URL}productos/${productId}`);
  return response;
};

export const addUpdateProduct = async (productData: any) => {
  try {
    const {
      id,
      nombre,
      descripcion,
      categoria_id,
      unidad_moneda_id,
      unidad_negocio_id,
      tipo_id,
      image,
      impuesto_id,
      sku,
      inventario,
      sub_categoria_id,
      tipo_venta_id,
      unidad_medida_venta_id,
      unidad_moneda_venta_id,
      tipo_precio_venta_id,
      precio_fijo_venta,
      aplica_impuesto_precio_venta,
      porcentaje_beneficio_precio_venta,
      tipo_compra_id,
      unidad_medida_compra_id,
      unidad_moneda_compra_id,
      tipo_precio_compra_id,
      precio_fijo_compra,
      precio_proveedor_compra,
      porcentaje_beneficio_precio_compra,
      porcentaje_conversion_compra,
      tipo_cambio_compra,
      proveedor_id,
    } = productData;

    const formData = new FormData();

    formData.append("nombre", nombre);
    formData.append("sku", sku ? sku : "");
    formData.append("inventario", inventario ? inventario : 0);
    formData.append("descripcion", descripcion ? descripcion : "");
    formData.append(
      "precio_fijo_venta",
      precio_fijo_venta ? precio_fijo_venta : 0
    );
    formData.append(
      "aplica_impuesto_precio_venta",
      aplica_impuesto_precio_venta
    );
    formData.append(
      "porcentaje_beneficio_precio_venta",
      porcentaje_beneficio_precio_venta ? porcentaje_beneficio_precio_venta : 0
    );
    formData.append(
      "precio_fijo_compra",
      precio_fijo_compra ? precio_fijo_compra : 0
    );
    formData.append(
      "precio_proveedor_compra",
      precio_proveedor_compra ? precio_proveedor_compra : 0
    );
    formData.append(
      "porcentaje_conversion_compra",
      porcentaje_conversion_compra ? porcentaje_conversion_compra : 0
    );
    formData.append(
      "porcentaje_beneficio_precio_compra",
      porcentaje_beneficio_precio_compra || 0
    );

    if (proveedor_id > 0) {
      formData.append("proveedor_id", proveedor_id);
    }
    if (tipo_cambio_compra > 0) {
      formData.append("tipo_cambio_compra", tipo_cambio_compra);
    }
    if (tipo_venta_id > 0) {
      formData.append("tipo_venta_id", tipo_venta_id);
    }
    if (tipo_precio_compra_id > 0) {
      formData.append("tipo_precio_compra_id", tipo_precio_compra_id);
    }
    if (tipo_compra_id > 0) {
      formData.append("tipo_compra_id", tipo_compra_id);
    }
    if (unidad_medida_venta_id > 0) {
      formData.append("unidad_medida_venta_id", unidad_medida_venta_id);
    }
    if (unidad_moneda_venta_id > 0) {
      formData.append("unidad_moneda_venta_id", unidad_moneda_venta_id);
    }
    if (unidad_medida_compra_id > 0) {
      formData.append("unidad_medida_compra_id", unidad_medida_compra_id);
    }
    if (unidad_moneda_compra_id > 0) {
      formData.append("unidad_moneda_compra_id", unidad_moneda_compra_id);
    }
    if (tipo_precio_venta_id > 0) {
      formData.append("tipo_precio_venta_id", tipo_precio_venta_id);
    }
    if (categoria_id > 0) {
      formData.append("categoria_id", categoria_id);
    }
    if (sub_categoria_id > 0) {
      formData.append("subcategoria_id", sub_categoria_id);
    }
    if (tipo_id > 0) {
      formData.append("tipo_id", tipo_id);
    }
    if (impuesto_id > 0) {
      formData.append("impuesto_id", impuesto_id);
    }

    if (unidad_moneda_id > 0) {
      formData.append("unidad_moneda_id", unidad_moneda_id);
    }
    if (unidad_negocio_id > 0) {
      formData.append("unidad_negocio_id", unidad_negocio_id);
    }
    if (image) {
      formData.append("imagen", image);
    }
    let response;
    if (id) {
      response = await axios.patch(`${API_URL}productos/${id}`, formData);
    } else {
      response = await axios.post(`${API_URL}productos/add`, formData);
    }
    return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar producto:", error);
    throw error;
  }
};

export async function getProductById(
  productId: number
): Promise<ProductModel | undefined> {
  try {
    const random = Math.random() * 40;
    const response = await axios.get(`${API_URL}productos/${productId}`);
    const data = response.data.doc.data;

    const {
      imagen,
      unidadNegocio,
      subcategoria,
      tipo,
      impuesto,
      tipoPrecioCompra,
      tipoCompra,
    } = data;

    data.imagen = data.imagen
      ? `${API_URL_IMAGE_PUBLIC}${imagen}?${random}`
      : toAbsoluteUrl("/media/avatars/photo.png");

    data.tipo_id = tipo ? tipo?.id : null;
    data.unidad_negocio_id = unidadNegocio ? unidadNegocio?.id : null;
    data.impuesto_id = impuesto ? impuesto?.id : null;
    data.impuesto_porcentaje = impuesto ? impuesto.porcentaje : null;
    data.sub_categoria_id = subcategoria ? subcategoria?.id : null;
    data.tipo_precio_compra_id = tipoPrecioCompra ? tipoPrecioCompra?.id : null;
    data.tipo_compra_id = tipoCompra ? tipoCompra : null;

    return data;
  } catch (error) {
    console.error("ERROR:", error);
    return undefined;
  }
}

export async function getHistoryProducts(id: string) {
  const response = await axios.get(`${API_URL}productos/historial/${id}`);

  const data = response.data.doc.data;
  return data;
}

export async function createHistoryMessageProduct(message: string, id: number) {
  const response = await axios.post(
    `${API_URL}productos/historial/comment/add`,
    {
      productId: id,
      comentario: message,
    }
  );
  return response;
}

export async function getDocumentsByProductId(id: number) {
  const response = await axios.get(`${API_URL}productos/documentos/${id}`);
  const data = response.data.doc.data;
  return data;
}

export const uploadFileToProduct = async (file: any, id: string) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await axios.post(
    `${API_URL}productos/documento/${id}`,
    formData
  );
  return response;
};

export const removeFileOfProduct = async (id: number) => {
  const response = await axios.delete(`${API_URL}productos/documento/${id}`);
  return response;
};

export async function getNotesByProductId(id: number) {
  const response = await axios.get(
    `${API_URL}cotizacion/producto/notas/producto/${id}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function insertNoteProduct(note: NoteProductModel, id: number) {
  const response = await axios.post(`${API_URL}cotizacion/producto/notas`, {
    nombre: note.nombre,
    descripcion: note.descripcion,
    producto_id: Number(id),
  });
  return response;
}

export async function copyProduct(id: number) {
  const response = await axios.post(`${API_URL}productos/copy/${id}`);
  return response.data;
}

export const addCategoryProducts = async (category: any) => {
  try {
    const { nombre } = category;

    const response = await axios.post(`${API_URL}categoria/productos`, {
      nombre,
    });
    return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar el registro:", error);
    throw error;
  }
};

export const addSubcategoryProducts = async (
  subcategory: any,
  categoryId: number
) => {
  try {
    const { nombre } = subcategory;

    const response = await axios.post(`${API_URL}subcategoria/productos`, {
      nombre,
      categoria_id: categoryId,
    });
    return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar el registro:", error);
    throw error;
  }
};

export const addProductType = async (category: any) => {
  try {
    const { name } = category;

    const response = await axios.post(`${API_URL}catalogs/add/tprod`, {
      name,
    });
    return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar el registro:", error);
    throw error;
  }
};

export async function getSuppliersList() {
  const response = await axios.get(`${API_URL}proveedores`);
  const data = response.data.doc.data;
  let select: SelectFormat[] = [];
  data?.forEach((item: any) => {
    const selectCustom = {
      id: Number(item?.id),
      value: item?.id,
      label: `${item?.nombre}`,
      data: item,
    };

    select.push(selectCustom);
  });

  select.unshift({
    id: 0,
    value: "0",
    label: `Agregar proveedor`,
    data: {},
  });

  return select;
}

export async function addSupplierToProduct(body: any) {
  const {
    nombre_proveedor,
    producto_id,
    proveedor_id,
    unidad_medida_id,
    unidad_moneda_id,
  } = body;

  const response = await axios.post(`${API_URL}proveedores/producto/add`, {
    nombre_proveedor,
    producto_id,
    proveedor_id,
    unidad_medida_id,
    unidad_moneda_id,
  });

  return response;
}

export async function getSupplierByProduct(productId: number) {
  const response = await axios.get(
    `${API_URL}proveedores/products/${productId}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function updateSupplierToProduct(body: any) {
  const {
    nombre_proveedor,
    producto_id,
    id,
    volumen,
    precio_unitario,
    total,
    unidad_moneda_id,
    unidad_medida_id,
    seleccionado,
  } = body;

  let formatValue;

  const response = await axios.patch(`${API_URL}proveedores/producto/update`, {
    volumen,
    nombre_proveedor,
    producto_id,
    id,
    precio_unitario,
    total,
    unidad_moneda_id,
    unidad_medida_id,
    seleccionado,
  });

  return response;
}
