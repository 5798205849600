export const getExtensionFile = (file: any) => {
  let isExtensionAllowed;
  let extensionsAllowed = ["jpg", "png"];
  const fileName = file?.name;
  const fileExtension = fileName.split(".").pop().toLowerCase();

  if (extensionsAllowed.length) {
    isExtensionAllowed = extensionsAllowed.includes(fileExtension);
  }

  return { isExtensionAllowed, fileExtension };
};
