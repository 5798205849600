import React, { useState, useEffect } from 'react';
import { Collapse } from 'react-bootstrap-v5';
import { KTSVG } from '../../../../_metronic/helpers';
import { CardOpportunities } from './CardOpportunities';
import { ListGrid } from './ListGrid';
import { useLocation, useHistory } from 'react-router-dom';
import { CreateCategoryModal } from '../_modals/CreateCategoryModal';
import CardList from './CardList';
import CardListServices from './CardListServices';
import CardListPackage from './CardListPackages';
import {
  useGetCategoryProd,
  useGetAllProd,
  useGetAllQuo,
  useGetCatTaxes,
  useGetProveedorFilter,
} from '../hooks/ProductsHook';
import { useGetCategoryServ, useGetAllServ } from '../hooks/ServicesHook';
import { useGetCategoryPack, useGetAllPack } from '../hooks/PackagesHook';
import { CardsProducts } from './cards/CardProduct';
import { CardsServices } from './cards/CardsService';
import { CardsPackages } from './cards/CardPackage';
import { useSelectComponentFormat } from '../../catalogos/generico/hooks/genericoHook';
import { randomClass } from '../../../../_metronic/helpers/CustomElements';
import { FilterBoard } from './FilterBoard';
import { ProductsFilterBoard } from '../../home/models/HomeModel';

const View: React.FC = () => {
  const MODULS = {
    PRODUCTOS: 1,
    SERVICIOS: 2,
    PAQUETES: 3,
    COTIZACIONES: 4,
  };

  // FITROS
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues, setInitFilterValues] = useState<ProductsFilterBoard>(
    {
      campo_id: null,
      valor: null,
    }
  );
  // TERMINA FILTROS

  const [categoryIdSelected, setCategoryIdSelected] = useState<number>(0);
  const [categoryNameSelected, setcategoryNameSelected] = useState<string>('');
  const [refreshItemsCatSupplier, setRefreshItemsCatSupplier] =
    useState<number>(0);
  const [makeCatRequests, setMakeCatRequests] = useState<boolean>(false);
  const { optionsSelect: unidad_moneda } = useSelectComponentFormat(
    'UMON',
    makeCatRequests
  );
  const { optionsSelect: unidad_medida } = useSelectComponentFormat(
    'UNMED',
    makeCatRequests
  );
  const { optionsSelect: unidad_medida_servicio } = useSelectComponentFormat(
    'UNDMEDS',
    makeCatRequests
  );
  const { optionsSelectTaxes } = useGetCatTaxes(makeCatRequests);
  const { suppDataSelect } = useGetProveedorFilter(
    makeCatRequests,
    refreshItemsCatSupplier
  );
  const [reloadDataProd, setReloadDataProd] = useState<number>(
    Math.random() * 40
  );
  const [reloadDataService, setReloadDataService] = useState<number>(
    Math.random() * 40
  );
  const [reloadDataPackage, setReloadDataPackage] = useState<number>(
    Math.random() * 40
  );
  const [reloadDataQuotations, setReloadDataQuotations] = useState<number>(
    Math.random() * 40
  );

  // FILTRAR PRODUCTOS SELECCIONANDO UNA CATEGORIA
  const [filterProductsByCategory, setFilterProductsByCategory] = useState<any>(
    []
  );
  const [filterProducts, setFilterProducts] = useState<any>([]);

  // OBTNER PRODUCTOS  POR CATEGORIAS
  const { dataCatProd, loadingRequest } = useGetCategoryProd(
    reloadDataProd,
    initFilterValues
  );
  // OBTENER CATEGORIAS DE PRODUCTOS
  const { dataProducts } = useGetAllProd(reloadDataProd, initFilterValues);

  // FILTRAR SERVICIOS SELECCIONANDO UNA CATEGORIA
  const [filterServicesByCategory, setFilterServicesByCategory] = useState<any>(
    []
  );
  const [filterServices, setFilterServices] = useState<any>([]);

  // OBTNER SERVICIOS POR CATEGORIAS
  const { dataCatServ } = useGetCategoryServ(reloadDataService);
  // OBTNER  CATEGORIAS DE SERVICIOS
  const { dataService } = useGetAllServ(reloadDataService);

  // DATA PAQUETES
  const [filterPackagesByCategory, setFilterPackagesByCategory] = useState<any>(
    []
  );
  const [filterPackages, setFilterPackages] = useState<any>([]);

  const { dataCatPack } = useGetCategoryPack(reloadDataPackage);
  const { dataPackages } = useGetAllPack(reloadDataPackage);

  //DATA COTIZACIONES
  const { dataQuotations } = useGetAllQuo(reloadDataQuotations);

  const history = useHistory();
  let location = useLocation();
  const lastPathSegment: any = location.pathname.split('/').pop();
  const lastSegmentTitle = lastPathSegment
    ? lastPathSegment.charAt(0).toUpperCase() + lastPathSegment.slice(1)
    : '';
  const [totalPages, setTotalPages] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [changeIcon, setChangeIcon] = useState<string>(
    '/media/icons/duotune/ecommerce/ecm008.svg'
  );
  const [opcion, setOpcion] = useState<number>(1);
  const [open, setOpen] = useState(false);
  const [openCat, setOpenCat] = useState(false);
  const [showCat, setShowCat] = useState(false);
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [secction, setSecction] = useState<string | 'BOARD' | 'LIST' | 'ALTER'>(
    'LIST'
  );
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [idSection, setIdSection] = useState<number>(0);
  const [infoCat, setinfoCat] = useState<Array<any>>([]);

  const [senUrlCoti, setSenUrlCoti] = useState<string>('');
  const [formIsEdit, setFormIsEdit] = useState<boolean>(false);

  const cambiarOpcion = (nuevaOpcion: number) => {
    setOpcion(nuevaOpcion);
  };

  const toggleMode = (mode: 'LIST' | 'BOARD' | 'ALTER') => {
    localStorage.setItem('viewMode', mode);
    setSecction(mode);
  };

  const onRefreshDataProducts = () => {
    setReloadDataProd(Math.random() * 100);
  };

  const onRefreshDataService = () => {
    setReloadDataService(Math.random() * 100);
  };

  const onRefreshDataSupplier = () => {
    setRefreshItemsCatSupplier(Math.random() * 100);
  };

  const onRefreshDataPackages = () => {
    setReloadDataPackage(Math.random() * 100);
  };
  const onRefreshDataQuotations = () => {
    setReloadDataQuotations(Math.random() * 100);
  };

  // FITROS
  const onSearchFilter = (values: any) => {
    setLoadingGrid(true);

    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({
        campo_id: null,
        valor: null,
      });
    }
    setReloadGrid(Math.random() * 40);
  };
  const onCleanFilter = () => {
    setLoadingGrid(true);
    setInitFilterValues({
      campo_id: null,
      valor: null,
    });
    setReloadGrid(Math.random() * 40);
  };

  useEffect(() => {
    const storedMode = localStorage.getItem('viewMode');
    if (storedMode) {
      setSecction(storedMode);
    }
  }, []);

  useEffect(() => {
    setOpenCat(false);
    setMakeCatRequests(false);

    switch (lastSegmentTitle) {
      case 'Cotizaciones':
        setMakeCatRequests(true);
        setCategoryIdSelected(0);
        setShowCat(false);
        setChangeIcon('/media/icons/duotune/ecommerce/ecm008.svg');
        setSenUrlCoti('/cotizaciones/add/');
        break;
      case 'Productos':
        setMakeCatRequests(true);
        setCategoryIdSelected(0);
        setMakeCatRequests(true);
        setShowCat(true);
        setChangeIcon('/media/icons/duotune/ecommerce/ecm009.svg');
        setSenUrlCoti('/cotizaciones/productos/add/');
        setinfoCat(dataCatProd);
        setIdSection(MODULS.PRODUCTOS);
        break;
      case 'Servicios':
        setMakeCatRequests(true);
        setCategoryIdSelected(0);
        setcategoryNameSelected('');
        setShowCat(true);
        setChangeIcon('/media/icons/duotune/general/gen032.svg');
        setSenUrlCoti('/cotizaciones/servicios/add/');
        setinfoCat(dataCatServ);
        setIdSection(MODULS.SERVICIOS);
        break;
      case 'Paquetes':
        setCategoryIdSelected(0);
        setShowCat(true);
        setChangeIcon('/media/icons/duotune/ecommerce/ecm005.svg');
        setSenUrlCoti('/cotizaciones/paquetes/add/');
        setinfoCat(dataCatPack);
        setIdSection(MODULS.PAQUETES);
        break;
    }
  }, [lastSegmentTitle, dataCatProd, dataCatServ, dataCatPack]);

  useEffect(() => {
    switch (idSection) {
      case MODULS.PRODUCTOS:
        if (dataProducts && categoryIdSelected) {
          const productsFilter = dataProducts.filter((item: any) => {
            return item.categoria && item.categoria.id === categoryIdSelected;
          });

          setFilterProducts(productsFilter);
        } else {
          setFilterProducts(dataProducts);
        }
        break;
      case MODULS.SERVICIOS:
        if (dataService && categoryIdSelected) {
          const servicesFilter = dataService.filter((item: any) => {
            return item.categoria && item.categoria.id === categoryIdSelected;
          });

          setFilterServices(servicesFilter);
        } else {
          setFilterServices(dataService);
        }
        break;
      case MODULS.PAQUETES:
        if (dataPackages && categoryIdSelected) {
          const packagesFilter = dataPackages.filter((item: any) => {
            return item.categoria && item.categoria.id === categoryIdSelected;
          });

          setFilterPackages(packagesFilter);
        } else {
          setFilterPackages(dataPackages);
        }
        break;

      default:
        break;
    }
  }, [categoryIdSelected, dataProducts, idSection, dataService, dataPackages]);

  useEffect(() => {
    switch (idSection) {
      case MODULS.PRODUCTOS:
        if (dataCatProd && categoryIdSelected) {
          const productsByCategory = dataCatProd.filter(
            (item: any) => item.categoriaId == categoryIdSelected
          );

          setFilterProductsByCategory(productsByCategory);
        } else {
          setFilterProductsByCategory(dataCatProd);
        }
        break;
      case MODULS.SERVICIOS:
        if (dataCatServ && categoryIdSelected) {
          const servicesByCategory = dataCatServ.filter(
            (item: any) => item.categoriaId == categoryIdSelected
          );

          setFilterServicesByCategory(servicesByCategory);
        } else {
          setFilterServicesByCategory(dataCatServ);
        }
        break;
      case MODULS.PAQUETES:
        if (dataCatPack && categoryIdSelected) {
          const packagesByCategory = dataCatPack.filter(
            (item: any) => item.categoriaId == categoryIdSelected
          );

          setFilterPackagesByCategory(packagesByCategory);
        } else {
          setFilterPackagesByCategory(dataCatPack);
        }
        break;

      default:
        break;
    }
  }, [categoryIdSelected, dataCatProd, idSection, dataCatServ, dataCatPack]);

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12 mb-xl-12">
          <div className="card border">
            <div
              className="card-header border-0 cursor-pointer d-flex justify-content-between align-items-center"
              onClick={() => setOpen(!open)}
              aria-controls="collapseExample"
              aria-expanded={open}
            >
              <div className="d-flex align-items-center">
                <KTSVG path={changeIcon} className="svg-icon-3x d-block my-2" />
                <h3 className="fw-bolder m-0 ms-3 ">{lastSegmentTitle}</h3>
              </div>
              <div className="justify-content-center align-items-center">
                {!open ? (
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr072.svg"
                    className="svg-icon-3x d-block my-2 me-3"
                  />
                ) : (
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr073.svg"
                    className="svg-icon-3x d-block my-2"
                  />
                )}
              </div>
            </div>
            <Collapse in={open}>
              <div id="collapseExample">
                <div className="card-body border-top p-9">
                  <div className="row g-6 g-xl-9 w-75">
                    {/* begin::Col */}
                    <div
                      className={`border col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn btn-active-primary px-6 py-8 rounded-2 me-7 mb-7 position-relative ${
                        lastSegmentTitle === 'Cotizaciones' ? 'btn-primary' : ''
                      }`}
                      onClick={() => {
                        cambiarOpcion(1);
                        setShowCat(false);
                        history.push(`/cotizaciones`);
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-start">
                        <div className="d-flex flex-column align-items-start">
                          <KTSVG
                            path="/media/icons/duotune/ecommerce/ecm008.svg"
                            className="svg-icon-3x d-block my-2"
                          />
                          <div className="mt-2">Cotizaciones</div>
                        </div>
                        <div
                          className="d-block my-2 position-absolute top-0 end-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push('/cotizaciones/add/');
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen035.svg"
                            className="svg-icon-2x"
                          />
                        </div>
                      </div>
                    </div>
                    {/* end::Col */}
                    {/* begin::Col */}
                    <div
                      className={`border col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn btn-active-primary px-6 py-8 rounded-2 me-7 mb-7 position-relative ${
                        lastSegmentTitle === 'Productos' ? 'btn-primary' : ''
                      }`}
                      onClick={() => {
                        cambiarOpcion(2);
                        setShowCat(true);
                        history.push(`/cotizaciones/productos`);
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-start">
                        <div className="d-flex flex-column align-items-start">
                          <KTSVG
                            path="/media/icons/duotune/ecommerce/ecm009.svg"
                            className="svg-icon-3x d-block my-2"
                          />
                          <div className="mt-2">Productos</div>
                        </div>
                        <div
                          className="d-block my-2 position-absolute top-0 end-0"
                          onClick={(e) => {
                            e.stopPropagation();

                            history.push('/cotizaciones/productos/add/');
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen035.svg"
                            className="svg-icon-2x"
                          />
                        </div>
                      </div>
                    </div>
                    {/* end::Col */}
                    {/* begin::Col */}
                    <div
                      className={`border col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn btn-active-primary px-6 py-8 rounded-2 me-7 mb-7 position-relative ${
                        lastSegmentTitle === 'Servicios' ? 'btn-primary' : ''
                      }`}
                      onClick={() => {
                        cambiarOpcion(3);
                        setShowCat(true);
                        history.push(`/cotizaciones/servicios`);
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-start">
                        <div className="d-flex flex-column align-items-start">
                          <KTSVG
                            path="/media/icons/duotune/general/gen032.svg"
                            className="svg-icon-3x d-block my-2"
                          />
                          <div className="mt-2">Servicios</div>
                        </div>
                        <div
                          className="d-block my-2 position-absolute top-0 end-0"
                          onClick={(e) => {
                            e.stopPropagation();

                            history.push('/cotizaciones/servicios/add/');
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen035.svg"
                            className="svg-icon-2x"
                          />
                        </div>
                      </div>
                    </div>
                    {/* end::Col */}
                    {/* begin::Col */}
                    <div
                      className={`border col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn btn-active-primary px-6 py-8 rounded-2 me-7 mb-7 position-relative ${
                        lastSegmentTitle === 'Paquetes' ? 'btn-primary' : ''
                      }`}
                      onClick={() => {
                        cambiarOpcion(4);
                        setShowCat(true);
                        history.push(`/cotizaciones/paquetes`);
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-start">
                        <div className="d-flex flex-column align-items-start">
                          <KTSVG
                            path="/media/icons/duotune/ecommerce/ecm005.svg"
                            className="svg-icon-3x d-block my-2"
                          />
                          <div className="mt-2">Paquetes</div>
                        </div>
                        <div
                          className="d-block my-2 position-absolute top-0 end-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push('/cotizaciones/paquetes/add/');
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen035.svg"
                            className="svg-icon-2x"
                          />
                        </div>
                      </div>
                    </div>
                    {/* end::Col */}
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
      {showCat ? (
        <div className="row gy-5 g-xl-8">
          <div className="col-xl-12 mb-xl-12">
            <div className="card border">
              <div
                className="card-header border-0 cursor-pointer d-flex justify-content-between align-items-center"
                onClick={() => setOpenCat(!openCat)}
                aria-controls="collapseExample"
                aria-expanded={openCat}
              >
                <div className="d-flex align-items-center">
                  <KTSVG
                    path="/media/icons/duotune/general/gen008.svg"
                    className="svg-icon-3x d-block my-2"
                  />
                  <h3 className="fw-bolder m-0 ms-3">Mis Categorías</h3>
                  {categoryIdSelected && !openCat ? (
                    <div className="d-flex">
                      <a
                        href="#/"
                        className="btn btn-sm btn-light-info btn-color-info btn-active-light-info px-4 py-2 ms-4"
                        onClick={() => {
                          setCategoryIdSelected(0);
                          setcategoryNameSelected('');
                        }}
                      >
                        <span>
                          <KTSVG
                            path="/media/icons/duotune/general/gen040.svg"
                            className="svg-icon-3"
                          />
                        </span>
                        {categoryNameSelected}
                      </a>
                    </div>
                  ) : null}
                </div>
                <div className="justify-content-center align-items-center">
                  {!openCat ? (
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr072.svg"
                      className="svg-icon-3x d-block my-2 me-3"
                    />
                  ) : (
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr073.svg"
                      className="svg-icon-3x d-block my-2"
                    />
                  )}
                </div>
              </div>
              <Collapse in={openCat}>
                <div id="collapseExample">
                  <div className="card-body border-top p-9">
                    {/* Todas mis categorías */}
                    <div className="mb-10 fv-row ">
                      <div className="col-md-6 col-lg-6">
                        <h4 className="text-primary">
                          {categoryNameSelected
                            ? categoryNameSelected
                            : 'Todas mis categorías'}
                        </h4>
                        {categoryIdSelected ? (
                          <div className="d-flex">
                            <a
                              href="#/"
                              className="btn btn-sm btn-light-info btn-color-info btn-active-light-info px-4 py-2 me-4 mt-5"
                              onClick={() => {
                                setCategoryIdSelected(0);
                                setcategoryNameSelected('');
                              }}
                            >
                              <span>
                                <KTSVG
                                  path="/media/icons/duotune/general/gen040.svg"
                                  className="svg-icon-3"
                                />
                              </span>
                              Quitar Filtro
                            </a>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row g-6 g-xl-9">
                      <div className="col-xxl-11 col-xl-11 col-md-11 col-lg-11">
                        <ul
                          className={`nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder d-flex flex-wrap align-items-center`}
                        >
                          <div
                            className="row mb-2 w-100"
                            data-kt-buttons="true"
                          >
                            {infoCat.map((item: any) => {
                              const randomClassItem = randomClass();
                              return (
                                <div
                                  className="col-md-4 mb-2"
                                  onClick={() => {
                                    const { categoriaId, nombre } = item;
                                    setCategoryIdSelected(categoriaId);
                                    setcategoryNameSelected(nombre);
                                  }}
                                >
                                  <label
                                    className={`btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 ${
                                      categoryIdSelected === item.categoriaId
                                        ? 'btn-light-primary'
                                        : ''
                                    }`}
                                  >
                                    <div
                                      style={{
                                        justifyContent: 'center',
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <i
                                        className={`fa fa-genderless mb-1 fs-3 ${randomClassItem}`}
                                      ></i>
                                    </div>
                                    <span className="large-text fs-7 ">
                                      {item.nombre}
                                    </span>
                                  </label>
                                </div>
                              );
                            })}
                            <div
                              className="col-md-4 mb-2"
                              onClick={() => setShowNoteModal(true)}
                              title="Nueva Categoría"
                            >
                              <label
                                className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
                                htmlFor="kt_account_team_size_select_1"
                              >
                                <span className="large-text fs-7">
                                  <KTSVG
                                    path="/media/icons/duotune/general/gen041.svg"
                                    className="svg-icon-1 svg-icon-gray-500 "
                                  />
                                </span>
                              </label>
                            </div>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row gy-6 g-xl-9">
        <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
          <div className="card card-flush p-5">
            <div className="row">
              <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                  <li className="nav-item cursor-pointer ">
                    <a
                      className={`nav-link me-6 ${
                        secction === 'LIST' ? 'text-active-primary active' : ''
                      } `}
                      onClick={() => {
                        toggleMode('LIST');
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/text/txt001.svg"
                        className="svg-icon-1 fs-7"
                      />
                      <span className="margin-left-05">Lista</span>
                    </a>
                  </li>
                  <li className="nav-item cursor-pointer">
                    <a
                      className={`nav-link me-6  ${
                        secction === 'BOARD' ? 'text-active-primary active' : ''
                      }`}
                      onClick={() => {
                        toggleMode('BOARD');
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/layouts/lay002.svg"
                        className="svg-icon-1 fs-7"
                      />
                      <span className="margin-left-05">Tarjetas</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4 position-end">
                {lastSegmentTitle === 'Cotizaciones' ||
                lastSegmentTitle === 'Paquetes' ||
                lastSegmentTitle === 'Productos' ||
                lastSegmentTitle === 'Servicios' ? (
                  <>
                    <div className="card-title center-items">
                      <a
                        className={
                          'btn btn-sm btn-icon btn-active-light-primary border-btn'
                        }
                        title={lastSegmentTitle}
                        onClick={() => {
                          history.push(senUrlCoti);
                        }}
                      >
                        <KTSVG
                          path="/media/icons/duotune/abstract/abs011.svg"
                          className="svg-icon-5 svg-icon-gray-500 "
                        />
                      </a>
                    </div>

                    {lastSegmentTitle === 'Productos' ? (
                      <>
                        <div className="mr-05"></div>
                        <FilterBoard
                          onCleanFilter={onCleanFilter}
                          onSearchFilter={onSearchFilter}
                          initFilterValues={initFilterValues}
                        ></FilterBoard>
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
            <div className="row">
              {secction === 'BOARD' && lastSegmentTitle === 'Cotizaciones' ? (
                <CardOpportunities
                  board={dataQuotations}
                  onRefreshData={onRefreshDataQuotations}
                ></CardOpportunities>
              ) : null}
              {secction === 'BOARD' && lastSegmentTitle === 'Productos' ? (
                <CardsProducts
                  board={filterProducts}
                  // board={dataProducts}
                  onRefreshData={onRefreshDataProducts}
                />
              ) : null}
              {secction === 'BOARD' && lastSegmentTitle === 'Servicios' ? (
                <CardsServices
                  board={filterServices}
                  onRefreshData={onRefreshDataService}
                />
              ) : null}
              {secction === 'BOARD' && lastSegmentTitle === 'Paquetes' ? (
                <CardsPackages
                  board={filterPackages}
                  onRefreshData={onRefreshDataPackages}
                />
              ) : null}
              {secction === 'LIST' && lastSegmentTitle === 'Cotizaciones' ? (
                <ListGrid
                  data={dataQuotations}
                  count={count}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onRemoveRegister={() => {}}
                  onPageChange={() => {}}
                  onEditRegister={() => {}}
                  onDetail={() => {}}
                  loadingGrid={loadingGrid}
                />
              ) : null}
              {secction === 'LIST' && lastSegmentTitle === 'Productos' ? (
                <CardList
                  listData={filterProductsByCategory}
                  onRefreshData={onRefreshDataProducts}
                  isLoading={loadingRequest}
                  catUnidadMoneda={unidad_moneda}
                  catUnidadMedida={unidad_medida}
                  catTipoImpuesto={optionsSelectTaxes}
                  catProveedores={suppDataSelect}
                  onRefreshDataSupplier={onRefreshDataSupplier}
                ></CardList>
              ) : null}

              {secction === 'LIST' && lastSegmentTitle === 'Servicios' ? (
                <CardListServices
                  listData={filterServicesByCategory}
                  onRefreshData={onRefreshDataService}
                  catUnidadMoneda={unidad_moneda}
                  catUnidadMedida={unidad_medida_servicio}
                  catTipoImpuesto={optionsSelectTaxes}
                  catProveedores={suppDataSelect}
                  onRefreshDataSupplier={onRefreshDataSupplier}
                ></CardListServices>
              ) : null}

              {secction === 'LIST' && lastSegmentTitle === 'Paquetes' ? (
                <CardListPackage
                  listData={filterPackagesByCategory}
                  isInfo={formIsEdit}
                  onRefreshData={onRefreshDataPackages}
                ></CardListPackage>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <CreateCategoryModal
        show={showNoteModal}
        handleClose={(refresh) => {
          setShowNoteModal(!showNoteModal);
          if (refresh) {
            switch (idSection) {
              case 1:
                setReloadDataProd(Math.random() * 40);
                break;
              case 2:
                setReloadDataService(Math.random() * 40);
                break;
              case 3:
                setReloadDataPackage(Math.random() * 40);
            }
          }
        }}
        sectionId={idSection}
      ></CreateCategoryModal>
    </>
  );
};

export { View };
