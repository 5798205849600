import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { NumericFormat } from "react-number-format";
import { KTSVG, formatPrice } from "../../../../../_metronic/helpers";
import {
  styleSelect,
  styleSelectPackages,
} from "../../../../../_metronic/helpers/SelectStyles";
import {
  useCreateEditSupplier,
  useSelectSupplier,
  useUpdateSupplier,
} from "../../hooks/ProductsHook";
import Swal from "sweetalert2";
import { useSelectComponentFormat } from "../../../catalogos/generico/hooks/genericoHook";
import { FormikProps } from "formik";
import { ProductModel } from "../../models/ProductModel";

type Props = {
  data: any[];
  onUpdateSuppliers: () => void;
  supplierData: (data: any) => void;
  IsCheckedSupplierSecction: (isCheck: boolean) => void;
  productId: number;
  currencyData: any;
  measureData: any;
  isSuppPrice: boolean;
  onRefreshData: () => void;
  formik: FormikProps<ProductModel>;
};

const SupplierList: React.FC<Props> = ({
  data,
  onUpdateSuppliers,
  productId,
  supplierData,
  IsCheckedSupplierSecction,
  currencyData,
  measureData,
  isSuppPrice,
  onRefreshData,
  formik,
}) => {
  const [reloadCatSupp, setReloadCatSupp] = useState<number>(
    Math.random() * 50
  );
  const { catalog: suppliers, loadingRequest: loadingSupplier } =
    useSelectSupplier(reloadCatSupp);
  const { optionsSelect: UnitMeasure } = useSelectComponentFormat(
    "UNMED",
    true
  );
  const [dataMemo, setDataMemo] = useState<any[]>(data);
  const [supplierDataSelected, setSupplierDataSelected] = useState<any>({});
  const [isNewSuppler, setIsNewSupplier] = useState<boolean>(false);
  const { createSupplier, errorRequest } = useCreateEditSupplier();
  const {
    updateSupplier,
    errorRequest: errUpd,
    reloadInfo,
  } = useUpdateSupplier();
  const inputNewSupplierRefs = useRef<any>([]);
  const radios = useRef<any>([]);
  const inputsNameRefs = useRef<any>([]);
  const inputsVolRefs = useRef<any>([]);
  const inputsTotRefs = useRef<any>([]);
  const [loadingRows, setLoadingRows] = useState<any>({});
  const [focusedInput, setFocusedInput] = useState<number | null>(null);

  useEffect(() => {
    setDataMemo(data);
  }, [data]);

  const AddRow = () => {
    const cleanedData = dataMemo.filter((item) => item.id !== 0);
    const newRow: any = {
      id: 0,
      proveedor: null,
      nombre: "Nuevo proveedor",
      volumen: 1,
      precio_unitario: 0,
      proveedor_id: null,
      producto_id: productId,
    };

    setDataMemo([...cleanedData, newRow]);
  };

  const addSupplier = async (selectedOption: any) => {
    if (selectedOption) {
      const {
        data: { id },
      } = selectedOption;

      if (id) {
        const updateRow = {
          id: id,
          nombre_proveedor: null,
          proveedor_id: id,
          producto_id: productId,
          unidad_moneda_id: currencyData,
        };

        await createSupplier(updateRow);
        onUpdateSuppliers();
        onRefreshData();
      } else {
        setIsNewSupplier(!id ? true : false);
      }
    }
  };

  const volumeChange = async (
    newVolumen: number,
    rowId: number,
    total: number
  ) => {
    let tot = total ? total : 0;
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === rowId) {
          return {
            ...item,
            volumen: newVolumen,
            precio_unitario: tot / newVolumen,
          };
        }
        return item;
      });

      return updatedDataMemo;
    });
  };

  const handleRadioChange = async (item: any) => {
    const { precio_unitario, id } = item;

    let price = Number(precio_unitario);

    if (!price) {
      Swal.fire({
        title: "¿Estas seguro?",
        html: `
          <div className="text-gray-600">
            Ese proveedor no tiene definido un precio unitario. ¿Aun así lo quieres seleccionar?
          </div>
          `,
        showCancelButton: true,
        padding: "2em",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Aceptar",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light me-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          updateSupplier({
            id: id,
            seleccionado: true,
            producto_id: productId,
          });

          setDataMemo((prevDataMemo) => {
            const updatedDataMemo = prevDataMemo.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  seleccionado: true,
                };
              } else {
                return {
                  ...item,
                  seleccionado: false,
                };
              }
            });

            return updatedDataMemo;
          });
          IsCheckedSupplierSecction(true);
        }

        // else {
        //   setDataMemo((prevDataMemo) => {
        //     const updatedDataMemo = prevDataMemo.map((item) => {
        //       return {
        //         ...item,
        //         seleccionado: false,
        //       };
        //     });

        //     return updatedDataMemo;
        //   });
        //   IsCheckedSupplierSecction(false);
        // }
      });
    } else {
      await updateSupplier({
        id: id,
        seleccionado: true,
        producto_id: productId,
      });
      setDataMemo((prevDataMemo) => {
        const updatedDataMemo = prevDataMemo.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              seleccionado: true,
            };
          } else {
            return {
              ...item,
              seleccionado: false,
            };
          }
        });

        return updatedDataMemo;
      });
      IsCheckedSupplierSecction(true);
    }
  };

  const handleNameSupplier = async (event: any) => {
    const newName = event.target.value;
    if (isNewSuppler && newName) {
      const newRow: any = {
        nombre_proveedor: newName,
        proveedor_id: null,
        producto_id: productId,
        unidad_moneda_id: currencyData,
      };
      await createSupplier(newRow);
      onUpdateSuppliers();
    }
  };

  const onChangeTotal = async (
    newtotal: number,
    rowId: number,
    volumen: number
  ) => {
    let vol = volumen || 0;
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === rowId) {
          return {
            ...item,
            total: newtotal,
            precio_unitario: newtotal && vol ? newtotal / vol : 0,
          };
        }
        return item;
      });

      return updatedDataMemo;
    });
  };

  const changeUnitMeasure = async (selectOption: any, rowId: number) => {
    if (selectOption) {
      const { value } = selectOption;
      await updateSupplier({
        id: rowId,
        unidad_medida_id: value,
        producto_id: productId,
      });
    } else {
      await updateSupplier({
        id: rowId,
        unidad_medida_id: null,
        producto_id: productId,
      });
    }
  };

  useEffect(() => {
    if (supplierDataSelected) {
      supplierData(supplierDataSelected);
    } else {
      supplierData(null);
    }
  }, [supplierDataSelected]);

  useEffect(() => {
    if (currencyData) {
      updateSupplier({
        unidad_moneda_id: currencyData,
        producto_id: productId,
      });
    } else {
      updateSupplier({
        unidad_moneda_id: null,
        producto_id: productId,
      });
    }
  }, [currencyData]);

  useEffect(() => {
    if (errorRequest || errUpd) {
      onUpdateSuppliers();
    }
  }, [errorRequest, errUpd]);

  useEffect(() => {
    if (reloadInfo) {
      onUpdateSuppliers();
    }
  }, [reloadInfo]);

  useEffect(() => {
    if (!isSuppPrice) {
      setDataMemo((prevDataMemo) => {
        const updatedDataMemo = prevDataMemo.map((item) => {
          return {
            ...item,
            seleccionado: false,
          };
        });

        return updatedDataMemo;
      });
    }
  }, [isSuppPrice]);

  useEffect(() => {
    if (dataMemo.length) {
      const filter = dataMemo.filter((item) => item.seleccionado);
      if (filter.length) {
        formik.setFieldValue("proveedor_id", filter[0].id);

        setSupplierDataSelected(filter[0]);
      } else {
        setSupplierDataSelected({});
      }
    }
  }, [dataMemo]);

  return (
    <>
      <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-800 fs-7">
              <th className="w-auto">Proveedor</th>
              <th>Unidad</th>
              <th>Vol/Compra</th>
              <th className="text-center">Moneda</th>
              <th>Total</th>
              <th>Precio unitario</th>
              <th className="text-center">Seleccione</th>
            </tr>
          </thead>
          <tbody>
            {dataMemo.length > 0 ? (
              dataMemo.map((row, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.id === 0 ? (
                          isNewSuppler ? (
                            <>
                              <input
                                className="form-control"
                                ref={(el: any) =>
                                  (inputsNameRefs.current[index] = el)
                                }
                                type="text"
                                placeholder={row.nombre}
                                onKeyDown={async (event) => {
                                  if (event.key === "Enter") {
                                    event.preventDefault();
                                    setLoadingRows({
                                      ...loadingRows,
                                      [`${index}-nombreN`]: true,
                                    });
                                    await handleNameSupplier(event);
                                    setIsNewSupplier(false);
                                    setReloadCatSupp(Math.random() * 50);

                                    setLoadingRows({
                                      ...loadingRows,
                                      [`${index}-nombreN`]: false,
                                    });
                                  }
                                }}
                                onFocus={() =>
                                  setFocusedInput(inputsNameRefs.current[index])
                                }
                              />
                              {loadingRows[`${index}-nombreN`] && (
                                <span
                                  className="indicator-progress text-primary"
                                  style={{ display: "block" }}
                                >
                                  Espere por favor...
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                              )}
                              {focusedInput ===
                              inputsNameRefs.current[index] ? (
                                <small>Presione enter para guardar</small>
                              ) : null}

                              <a
                                className="btn btn-sm"
                                title="Regresar a lista de proveedores"
                                onClick={() => {
                                  setIsNewSupplier(false);
                                }}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/arrows/arr015.svg"
                                  className="svg-icon-1 svg-icon-primary"
                                />
                              </a>
                            </>
                          ) : (
                            <div>
                              <Select
                                options={suppliers}
                                styles={styleSelectPackages}
                                placeholder={"Seleccione"}
                                isSearchable={true}
                                menuPortalTarget={document.body}
                                menuPosition={"fixed"}
                                noOptionsMessage={() => {
                                  return (
                                    <span>{"No se encontraron registros"}</span>
                                  );
                                }}
                                onChange={addSupplier}
                                isLoading={loadingSupplier}
                                backspaceRemovesValue
                              />
                            </div>
                          )
                        ) : (
                          <>
                            <input
                              // style={{ minWidth: "100px" }}
                              ref={(el: any) =>
                                (inputNewSupplierRefs.current[index] = el)
                              }
                              type="text"
                              className={`${"form-control"} ${
                                focusedInput ===
                                inputNewSupplierRefs.current[index]
                                  ? ""
                                  : "border-0 "
                              }`}
                              defaultValue={row.proveedor?.nombre}
                              onKeyDown={async (event: any) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  setLoadingRows({
                                    ...loadingRows,
                                    [`${index}-name`]: true,
                                  });

                                  await updateSupplier({
                                    id: row.id,
                                    nombre_proveedor: event.target.value,
                                    producto_id: productId,
                                  });
                                  setReloadCatSupp(Math.random() * 50);

                                  // onUpdateSuppliers();

                                  setLoadingRows({
                                    ...loadingRows,
                                    [`${index}-name`]: false,
                                  });
                                }
                              }}
                              onFocus={() =>
                                setFocusedInput(
                                  inputNewSupplierRefs.current[index]
                                )
                              }
                            />
                            {loadingRows[`${index}-name`] && (
                              <span
                                className="indicator-progress text-primary"
                                style={{ display: "block" }}
                              >
                                Espere por favor...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                            {focusedInput ===
                            inputNewSupplierRefs.current[index] ? (
                              <small>Presione enter para guardar</small>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                  {row.id ? (
                    <>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                            <Select
                              options={UnitMeasure}
                              styles={styleSelect}
                              placeholder={"Seleccione"}
                              isSearchable={false}
                              noOptionsMessage={() => {
                                return (
                                  <span>{"No se encontraron registros"}</span>
                                );
                              }}
                              value={
                                row?.unidadMedida
                                  ? UnitMeasure.find(
                                      (option) =>
                                        option.value === row?.unidadMedida?.id
                                    )
                                  : null
                              }
                              onChange={(event: any) => {
                                changeUnitMeasure(event, row.id);
                              }}
                              backspaceRemovesValue
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                            <NumericFormat
                              getInputRef={(el: any) =>
                                (inputsVolRefs.current[index] = el)
                              }
                              style={{ width: "100%" }}
                              className="form-control"
                              thousandSeparator={false}
                              allowNegative={false}
                              decimalScale={2}
                              value={row.volumen}
                              onBlur={(e) => {
                                const value = e.target.value;
                                if (!value) {
                                  e.target.value = "1";
                                }
                              }}
                              isAllowed={(values: any) => {
                                const { floatValue } = values;
                                // Verificar que la parte entera no tenga más de 11 dígitos
                                const integerPart = Math.floor(floatValue);
                                return integerPart.toString().length <= 7;
                              }}
                              suffix=""
                              allowLeadingZeros={false}
                              onKeyDown={async (event: any) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();

                                  setLoadingRows({
                                    ...loadingRows,
                                    [`${index}-volumen`]: true,
                                  });

                                  await updateSupplier({
                                    id: row.id,
                                    volumen: event.target.value,
                                    producto_id: productId,
                                  });

                                  setLoadingRows({
                                    ...loadingRows,
                                    [`${index}-volumen`]: false,
                                  });

                                  // onUpdateSuppliers();
                                }
                              }}
                              onValueChange={(values) => {
                                const { floatValue } = values;

                                if (floatValue) {
                                  if (floatValue > 0) {
                                    volumeChange(floatValue, row.id, row.total);
                                  }
                                } else {
                                  volumeChange(1, row.id, row.total);
                                }
                              }}
                              onFocus={() =>
                                setFocusedInput(inputsVolRefs.current[index])
                              }
                            />
                            {loadingRows[`${index}-volumen`] && (
                              <span
                                className="indicator-progress text-primary"
                                style={{ display: "block" }}
                              >
                                Espere por favor...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                            {focusedInput === inputsVolRefs.current[index] ? (
                              <small>Presione enter para guardar</small>
                            ) : null}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                            <span className="text-center">
                              {row?.unidadMoneda?.code}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                            <div className="d-flex align-items-center w-100">
                              <NumericFormat
                                getInputRef={(el: any) =>
                                  (inputsTotRefs.current[index] = el)
                                }
                                style={{ minWidth: "100px" }}
                                className="form-control"
                                thousandSeparator={true}
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                allowLeadingZeros={false}
                                defaultValue={row.total}
                                onKeyDown={async (event: any) => {
                                  if (event.key === "Enter") {
                                    event.preventDefault();

                                    setLoadingRows({
                                      ...loadingRows,
                                      [`${index}-total`]: true,
                                    });
                                    const numericValue = parseFloat(
                                      event.target.value.replace(
                                        /[^0-9.-]+/g,
                                        ""
                                      )
                                    );

                                    await updateSupplier({
                                      id: row.id,
                                      total: numericValue,
                                      producto_id: productId,
                                    });

                                    setLoadingRows({
                                      ...loadingRows,
                                      [`${index}-total`]: false,
                                    });
                                    // onUpdateSuppliers();
                                  }
                                }}
                                onValueChange={(values: any) => {
                                  const { floatValue } = values;
                                  if (floatValue) {
                                    if (floatValue > 0) {
                                      onChangeTotal(
                                        floatValue,
                                        row.id,
                                        row.volumen
                                      );
                                    }
                                  } else {
                                    onChangeTotal(1, row.id, row.volumen);
                                  }
                                }}
                                onFocus={() =>
                                  setFocusedInput(inputsTotRefs.current[index])
                                }
                              />
                            </div>
                            {loadingRows[`${index}-total`] && (
                              <span
                                className="indicator-progress text-primary"
                                style={{ display: "block" }}
                              >
                                Espere por favor...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                            {focusedInput === inputsTotRefs.current[index] ? (
                              <small>Presione enter para guardar</small>
                            ) : null}
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                            {`${formatPrice(
                              row.precio_unitario,
                              "es-MX",
                              "MXN",
                              2,
                              2
                            )}`}
                            {/* / {codeCurrency} */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          className="d-flex align-items-center"
                          style={{
                            justifyContent: "center",
                          }}
                        >
                          <div className="d-flex ">
                            <label className="form-check form-check-custom form-check-solid">
                              <input
                                ref={(el: any) => (radios.current[index] = el)}
                                className="form-check-input"
                                type="radio"
                                checked={row.seleccionado}
                                onClick={async () => {
                                  await handleRadioChange(row);
                                }}
                              />
                            </label>
                          </div>
                        </div>
                      </td>
                    </>
                  ) : null}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center text-muted">
                  Sin proveedores
                </td>
              </tr>
            )}

            <tr>
              <td colSpan={9}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={AddRow}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar Proveedor
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { SupplierList };
