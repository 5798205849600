import React, { useState, useEffect } from "react";
import Select from "react-select";
import { NumericFormat } from "react-number-format";
import {
  KTSVG,
  SimpleAlert,
  formatPrice,
} from "../../../../../_metronic/helpers";
import {
  useSelectPackages,
  useSelectProducts,
  useSelectServices,
} from "../../../packages/hooks/PackagesHook";
import { styleSelect } from "../../../../../_metronic/helpers/SelectStyles";

type Props = {
  indexSection: number;
  data: any[];
  onChangePriceConcepts: (
    price: number,
    tax: number,
    concepts: any,
    indexSection: number
  ) => void;
  codeCurrency: string;
  exchangeRate: number;
  updateCostConcepts: number;
};
const ConceptsList: React.FC<Props> = ({
  indexSection,
  data,
  onChangePriceConcepts,
  codeCurrency,
  exchangeRate,
  updateCostConcepts,
}) => {
  const { catalog: products, loadingRequest: loadingPROD } =
    useSelectProducts();
  const { catalog: services, loadingRequest: loadingSER } = useSelectServices();
  const { catalog: packages, loadingRequest: loadingPAC } = useSelectPackages();

  const [dataMemo, setDataMemo] = useState<any[]>(data);
  const [conceptTypes] = useState<any[]>([
    {
      data: {},
      id: 1,
      label: "Producto",
      value: 1,
    },
    {
      data: {},
      id: 2,
      label: "Servicio",
      value: 2,
    },
    {
      data: {},
      id: 3,
      label: "Paquete",
      value: 3,
    },
  ]);

  const AddRow = () => {
    if (codeCurrency === "") {
      SimpleAlert("Favor seleccione un tipo de moneda!", 3000, "error");
      return false;
    }
    const cleanedData = dataMemo.filter((item) => item.id !== 0);
    const newRow: any = {
      id: 0,
      tipo: 0,
      partida: "N/A",
      nombre: "Nuevo concepto",
      nombre_categoria: "N/A",
      id_unidad_medida: 0,
      name_unidad_medida: "N/A",
      code_unidad_moneda: "N/A",
      volumen: 1,
      precio_sin_impuesto: 0,
      importe_sin_impuesto: 0,
      impuesto: 0,
      importe_impuesto: 0,
      precio_sin_impuesto_conversion: 0,
      importe_sin_impuesto_conversion: 0,
      impuesto_conversion: 0,
      importe_impuesto_conversion: 0,
    };
    setDataMemo([...cleanedData, newRow]);
  };

  const loadConcepts = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setDataMemo((prevDataMemo) => {
        const updatedDataMemo = prevDataMemo.map((item) => {
          if (item.id === 0) {
            return {
              ...item,
              tipo: value,
            };
          }
          return item;
        });
        return updatedDataMemo;
      });
    }
  };

  const addConcept = (selectedOption: any, tipo: number) => {
    if (selectedOption) {
      let dataConcept = {
        id: 0,
        nombre: "",
        nombre_categoria: "",
        unidadMonedaVenta: {
          code: "",
          id: 0,
          name: "",
        },
        unidadMedidaVenta: {
          code: "",
          id: 0,
          name: "",
        },
        precio_fijo_venta: 0,
        monto_impuesto_precio_venta: 0,
      };
      if (tipo === 1 || tipo === 2) {
        const {
          data: {
            id,
            nombre,
            unidadMedidaVenta,
            categoria,
            unidadMonedaVenta,
            precio_fijo_venta,
            monto_impuesto_precio_venta,
          },
        } = selectedOption;

        dataConcept = {
          id: id,
          nombre: nombre,
          unidadMonedaVenta: unidadMonedaVenta,
          unidadMedidaVenta: unidadMedidaVenta,
          nombre_categoria: categoria.nombre,
          precio_fijo_venta: Number(precio_fijo_venta),
          monto_impuesto_precio_venta: Number(monto_impuesto_precio_venta),
        };
      } else {
        const {
          data: {
            id,
            nombre,
            // unidadMedidaVenta,
            categoria,
            moneda,
            precio_sin_impuestos,
            impuestos,
          },
        } = selectedOption;
        dataConcept = {
          id: id,
          nombre: nombre,
          unidadMonedaVenta: moneda,
          unidadMedidaVenta: {
            code: "",
            id: 0,
            name: "",
          },
          nombre_categoria: categoria.nombre,
          precio_fijo_venta: Number(precio_sin_impuestos),
          monto_impuesto_precio_venta: Number(impuestos),
        };
      }
      const {
        id,
        nombre,
        unidadMedidaVenta,
        unidadMonedaVenta,
        precio_fijo_venta,
        monto_impuesto_precio_venta,
        nombre_categoria,
      } = dataConcept;

      const cleanedData = dataMemo.filter((item) => item.id !== 0);
      const existingIndex = dataMemo.findIndex(
        (item) => item.id === id && item.tipo === tipo
      );

      if (existingIndex === -1) {
        const precio_unitario = calculateExchangeRate(
          unidadMonedaVenta ? unidadMonedaVenta.code : "",
          codeCurrency,
          precio_fijo_venta
        );

        const precio_unitario_tax = calculateExchangeRateTax(
          unidadMonedaVenta ? unidadMonedaVenta.code : "",
          codeCurrency,
          monto_impuesto_precio_venta
        );

        const updateRow = {
          id: id,
          tipo: tipo,
          partida: indexSection + 1,
          nombre: nombre,
          nombre_categoria: nombre_categoria,
          id_unidad_medida: unidadMedidaVenta ? unidadMedidaVenta.id : 0,
          name_unidad_medida: unidadMedidaVenta
            ? unidadMedidaVenta.name
            : "N/A",
          code_unidad_moneda: unidadMonedaVenta
            ? unidadMonedaVenta.code
            : "N/A",
          volumen: 1,
          precio_sin_impuesto: precio_fijo_venta,
          importe_sin_impuesto: precio_fijo_venta,
          impuesto: monto_impuesto_precio_venta,
          importe_impuesto: monto_impuesto_precio_venta,
          precio_sin_impuesto_conversion: precio_unitario,
          importe_sin_impuesto_conversion: precio_unitario,
          impuesto_conversion: precio_unitario_tax,
          importe_impuesto_conversion: precio_unitario_tax,
        };

        setDataMemo((prevDataMemo) => {
          const updatedDataMemo = [...cleanedData, updateRow];
          sumAmount(updatedDataMemo);
          return updatedDataMemo;
        });
        // setDataMemo([...cleanedData, updateRow]);
      } else {
        setDataMemo([...cleanedData]);
        SimpleAlert("Ya existe el concepto en esta sección.", 3000, "error");
      }
    }
  };

  const calculateExchangeRate = (
    unidadMoneda: string,
    codeCurrency: string,
    precio_venta: number
  ) => {
    let costCalculate: number = 0;
    switch (unidadMoneda) {
      case "MXN":
        if (codeCurrency === "USD") {
          costCalculate = Number(precio_venta / exchangeRate);
        } else if (codeCurrency === "MXN") {
          costCalculate = precio_venta;
        }
        break;
      case "USD":
        if (codeCurrency === "USD") {
          costCalculate = precio_venta;
        } else if (codeCurrency === "MXN") {
          costCalculate = Number(precio_venta * exchangeRate);
        }
        break;
    }
    return Number(costCalculate);
  };

  const calculateExchangeRateTax = (
    unidadMoneda: string,
    codeCurrency: string,
    monto_impuesto_precio_venta: number
  ) => {
    let taxCalculate: number = 0;
    switch (unidadMoneda) {
      case "MXN":
        if (codeCurrency === "USD") {
          taxCalculate = Number(monto_impuesto_precio_venta / exchangeRate);
        } else if (codeCurrency === "MXN") {
          taxCalculate = monto_impuesto_precio_venta;
        }
        break;
      case "USD":
        if (codeCurrency === "USD") {
          taxCalculate = monto_impuesto_precio_venta;
        } else if (codeCurrency === "MXN") {
          taxCalculate = Number(monto_impuesto_precio_venta * exchangeRate);
        }
        break;
    }
    // return taxCalculate;
    return Number(taxCalculate);
  };

  const sumAmount = (updatedDataMemo: any) => {
    const subtotal = updatedDataMemo.reduce(
      (total: any, item: any) =>
        Number(total) + Number(item.importe_sin_impuesto_conversion),
      0
    );
    const tax = updatedDataMemo.reduce(
      (total: any, item: any) =>
        Number(total) + Number(item.importe_impuesto_conversion),
      0
    );
    onChangePriceConcepts(subtotal, tax, updatedDataMemo, indexSection);
  };

  const deleteRowById = (productId: number, tipo: number) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.filter(
        (item) => item.id !== productId || item.tipo !== tipo
      );
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  const volumeChange = (
    newVolumen: number,
    conceptId: number,
    tipo: number
  ) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === conceptId && item.tipo === tipo) {
          return {
            ...item,
            volumen: newVolumen,
            importe_sin_impuesto_conversion:
              item.precio_sin_impuesto_conversion * newVolumen,
            importe_impuesto_conversion: item.impuesto_conversion * newVolumen,
          };
        }
        return item;
      });
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  useEffect(() => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item: any) => {
        const precio_unitario = calculateExchangeRate(
          item.code_unidad_moneda,
          codeCurrency,
          item.precio_sin_impuesto
        );

        const precio_unitario_tax = calculateExchangeRateTax(
          item.code_unidad_moneda,
          codeCurrency,
          item.impuesto
        );

        return {
          ...item,
          precio_sin_impuesto_conversion: precio_unitario,
          importe_sin_impuesto_conversion: precio_unitario * item.volumen,
          impuesto_conversion: precio_unitario_tax,
          importe_impuesto_conversion: precio_unitario_tax * item.volumen,
        };
      });
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  }, [updateCostConcepts]);

  useEffect(() => {
    const cleanedData = data.filter((item) => item.id !== 0);
    setDataMemo(cleanedData);
  }, [data]);

  return (
    <>
      <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-800 fs-7">
              <th className="w-100px">Partida</th>
              <th className="w-150px">Tipo</th>
              <th className="w-150px">Concepto</th>
              <th className="w-100px">Categoría</th>
              <th className="w-100px">U/M</th>
              <th className="w-150px">Vol.</th>
              <th className="w-150px">Precio s/imp</th>
              <th className="w-150px">Importe s/imp</th>
              <th className="w-100px"></th>
            </tr>
          </thead>
          <tbody className="">
            {dataMemo.length > 0 ? (
              dataMemo.map((row, index) => (
                <tr key={row.id}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.partida} - {index + 1}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                        {row.id === 0 ? (
                          <Select
                            key={row.id}
                            options={conceptTypes}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={styleSelect}
                            placeholder={"Seleccione"}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{"No se encontraron registros"}</span>
                              );
                            }}
                            onChange={loadConcepts}
                            backspaceRemovesValue
                          />
                        ) : (
                          <>
                            {row.tipo === 1 && (
                              <>
                                <div
                                  className={`mt-2 badge badge-light-success w-50`}
                                >
                                  Producto
                                </div>
                              </>
                            )}
                            {row.tipo === 2 && (
                              <>
                                <div
                                  className={`mt-2 badge badge-light-info w-50`}
                                >
                                  Servicio
                                </div>
                              </>
                            )}
                            {row.tipo === 3 && (
                              <>
                                <div
                                  className={`mt-2 badge badge-light-primary w-50`}
                                >
                                  Paquete
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                        {row.tipo === 1 &&
                          (row.id === 0 ? (
                            <Select
                              key={row.id}
                              options={products}
                              styles={styleSelect}
                              menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              placeholder={"Seleccione"}
                              isSearchable={true}
                              noOptionsMessage={() => {
                                return (
                                  <span>{"No se encontraron registros"}</span>
                                );
                              }}
                              onChange={(e) => {
                                addConcept(e, row.tipo);
                              }}
                              isLoading={loadingPROD}
                              backspaceRemovesValue
                            />
                          ) : (
                            <>{row.nombre}</>
                          ))}
                        {row.tipo === 2 &&
                          (row.id === 0 ? (
                            <Select
                              key={row.id}
                              options={services}
                              styles={styleSelect}
                              menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              placeholder={"Seleccione"}
                              isSearchable={true}
                              noOptionsMessage={() => {
                                return (
                                  <span>{"No se encontraron registros"}</span>
                                );
                              }}
                              onChange={(e) => {
                                addConcept(e, row.tipo);
                              }}
                              isLoading={loadingPROD}
                              backspaceRemovesValue
                            />
                          ) : (
                            <>{row.nombre}</>
                          ))}
                        {row.tipo === 3 &&
                          (row.id === 0 ? (
                            <Select
                              key={row.id}
                              options={packages}
                              styles={styleSelect}
                              menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              placeholder={"Seleccione"}
                              isSearchable={true}
                              noOptionsMessage={() => {
                                return (
                                  <span>{"No se encontraron registros"}</span>
                                );
                              }}
                              onChange={(e) => {
                                addConcept(e, row.tipo);
                              }}
                              isLoading={loadingPROD}
                              backspaceRemovesValue
                            />
                          ) : (
                            <>{row.nombre}</>
                          ))}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.nombre_categoria}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {/* {row.tipo === 3 ? '' : row.code_unidad_moneda} */}
                        {row.name_unidad_medida}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.id !== 0 ? (
                          <NumericFormat
                            className="form-control w-100px"
                            thousandSeparator={false}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                e.stopPropagation();
                              }
                            }}
                            allowNegative={false}
                            decimalScale={2}
                            value={row.volumen}
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (!value) {
                                e.target.value = "1";
                              }
                            }}
                            isAllowed={(values: any) => {
                              const { floatValue } = values;
                              const integerPart = Math.floor(floatValue);
                              return integerPart.toString().length <= 7;
                            }}
                            suffix=""
                            allowLeadingZeros={false}
                            onValueChange={(values) => {
                              const { floatValue } = values;
                              if (floatValue) {
                                if (floatValue > 0) {
                                  volumeChange(floatValue, row.id, row.tipo);
                                }
                              } else {
                                volumeChange(1, row.id, row.tipo);
                              }
                            }}
                          />
                        ) : (
                          <>{"1"}</>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {`${formatPrice(
                          row.precio_sin_impuesto_conversion,
                          "es-MX",
                          "MXN",
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {`${formatPrice(
                          row.importe_sin_impuesto_conversion,
                          "es-MX",
                          "MXN",
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        <a
                          className="badge badge-light-danger  text-hover-danger fw-bold px-4 py-3 cursor-pointer"
                          title="Eliminar concepto"
                          onClick={async (event) => {
                            deleteRowById(row.id, row.tipo);
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen027.svg"
                            className="svg-icon-4"
                          />
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center text-muted">
                  Sin conceptos
                </td>
              </tr>
            )}

            <tr>
              <td colSpan={9}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={AddRow}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar Concepto
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { ConceptsList };
