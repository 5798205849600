import React, { useState, useEffect } from "react";
import Select from "react-select";
import { NumericFormat } from "react-number-format";
import {
  KTSVG,
  SimpleAlert,
  formatPrice,
  formatTotalSingle,
} from "../../../../../_metronic/helpers";
import { useSelectProducts } from "../../hooks/PackagesHook";
import { styleSelect } from "../../../../../_metronic/helpers/SelectStyles";
import { FormikProps } from "formik";

type Props = {
  data: any[];
  onChangePriceProducts: (price: number, tax: number, products: any) => void;
  codeCurrency: string;
  exchangeRate: number;
  updateCostProductos: number;
  packageId: number;
  formik: FormikProps<any>;
};

const ProductsList: React.FC<Props> = ({
  data,
  onChangePriceProducts,
  codeCurrency,
  exchangeRate,
  updateCostProductos,
  packageId,
  formik,
}) => {
  const [updateNewData, setUpdateNewData] = useState<boolean>(false);
  const { catalog: products, loadingRequest: loadingPROD } =
    useSelectProducts();
  const [dataMemo, setDataMemo] = useState<any[]>(data);
  const AddRow = () => {
    if (codeCurrency === "") {
      SimpleAlert("Favor seleccione un tipo de moneda!", 3000, "error");
      return false;
    }
    const cleanedData = dataMemo.filter((item) => item.id !== 0);
    const newRow: any = {
      id: 0,
      sku: "N/A",
      nombre: "Nuevo producto",
      id_unidad_medida: 0,
      name_unidad_medida: "N/A",
      code_unidad_moneda: "N/A",
      volumen: 1,
      precio_unitario: 0,
      precio_unitario_real: 0,
      importe: 0,
      monto_impuesto_precio_venta: 0,
      precio_impuesto_real: 0,
      monto_impuesto_precio_venta_total: 0,
      categoria: "N/A",
    };
    setDataMemo([...cleanedData, newRow]);
  };

  const addProduct = (selectedOption: any) => {
    if (selectedOption) {
      const {
        data: {
          id,
          sku,
          nombre,
          unidadMedidaVenta,
          unidadMonedaVenta,
          precio_venta,
          monto_impuesto_precio_venta,
          categoria,
        },
      } = selectedOption;
      const cleanedData = dataMemo.filter((item) => item.id !== 0);
      const existingIndex = dataMemo.findIndex((item) => item.id === id);
      if (existingIndex === -1) {
        const precio_unitario = calculateExchangeRate(
          unidadMonedaVenta ? unidadMonedaVenta.code : "",
          codeCurrency,
          precio_venta
        );

        const precio_unitario_tax = calculateExchangeRateTax(
          unidadMonedaVenta ? unidadMonedaVenta.code : "",
          codeCurrency,
          monto_impuesto_precio_venta
        );

        const updateRow = {
          id: id,
          sku: sku ? sku : "N/A",
          nombre: nombre,
          id_unidad_medida: unidadMedidaVenta ? unidadMedidaVenta.id : 0,
          name_unidad_medida: unidadMedidaVenta
            ? unidadMedidaVenta.name
            : "N/A",
          volumen: 1,
          code_unidad_moneda: unidadMonedaVenta
            ? unidadMonedaVenta.code
            : "N/A",
          precio_unitario: precio_unitario,
          precio_unitario_real: precio_venta,
          precio_impuesto_real: monto_impuesto_precio_venta,
          importe: Number(precio_unitario) * 1,
          monto_impuesto_precio_venta: precio_unitario_tax,
          monto_impuesto_precio_venta_total: Number(precio_unitario_tax) * 1,
          categoria: categoria ? categoria.nombre : "N/A",
        };
        setDataMemo((prevDataMemo) => {
          const updatedDataMemo = [...prevDataMemo, updateRow];
          sumAmount(updatedDataMemo);
          return updatedDataMemo;
        });
        setDataMemo([...cleanedData, updateRow]);
      } else {
        setDataMemo([...cleanedData]);
        SimpleAlert(
          "Ya existe el producto en el paquete actual!",
          3000,
          "error"
        );
      }
    }
  };

  const calculateExchangeRate = (
    unidadMoneda: string,
    codeCurrency: string,
    precio_venta: number
  ) => {
    let costCalculate = 0;
    switch (unidadMoneda) {
      case "MXN":
        if (codeCurrency === "USD") {
          costCalculate = Number(precio_venta) / exchangeRate;
        } else if (codeCurrency === "MXN") {
          costCalculate = Number(precio_venta);
        }
        break;
      case "USD":
        if (codeCurrency === "USD") {
          costCalculate = Number(precio_venta);
        } else if (codeCurrency === "MXN") {
          costCalculate = Number(precio_venta) * exchangeRate;
        }
        break;
    }
    return Number(formatTotalSingle(costCalculate, 2));
  };

  const calculateExchangeRateTax = (
    unidadMoneda: string,
    codeCurrency: string,
    monto_impuesto_precio_venta: number
  ) => {
    let taxCalculate = 0;
    switch (unidadMoneda) {
      case "MXN":
        if (codeCurrency === "USD") {
          taxCalculate = Number(monto_impuesto_precio_venta) / exchangeRate;
        } else if (codeCurrency === "MXN") {
          taxCalculate = Number(monto_impuesto_precio_venta);
        }
        break;
      case "USD":
        if (codeCurrency === "USD") {
          taxCalculate = Number(monto_impuesto_precio_venta);
        } else if (codeCurrency === "MXN") {
          taxCalculate = Number(monto_impuesto_precio_venta) * exchangeRate;
        }
        break;
    }
    return Number(taxCalculate.toFixed(2));
  };

  const sumAmount = (updatedDataMemo: any) => {
    const total = updatedDataMemo.reduce(
      (total: any, item: any) => Number(total) + Number(item.importe),
      0
    );
    console.log(total);
    const totalFormatt = formatTotalSingle(total, 2);

    const tax = updatedDataMemo.reduce(
      (total: any, item: any) =>
        formatTotalSingle(total, 2) +
        formatTotalSingle(item.monto_impuesto_precio_venta_total, 2),
      0
    );
    onChangePriceProducts(totalFormatt, tax, updatedDataMemo);
  };

  const deleteRowById = (productId: number) => {
    if (dataMemo.length && packageId > 0) {
      const storageData = JSON.parse(localStorage.getItem("memo") ?? "");
      const exist = storageData.filter((item: any) => item.id === productId);

      if (exist.length) {
        setUpdateNewData(true);
      }
    }

    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.filter(
        (item) => item.id !== productId
      );
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  const volumeChange = (newVolumen: number, productId: number) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === productId) {
          let p = formatTotalSingle(item.precio_unitario, 2);

          return {
            ...item,
            volumen: newVolumen,
            importe: p * newVolumen,
            monto_impuesto_precio_venta_total:
              item.monto_impuesto_precio_venta * newVolumen,
          };
        }
        return item;
      });
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setDataMemo((prevDataMemo) => {
        const updatedDataMemo = prevDataMemo.map((item: any) => {
          const precio_unitario = calculateExchangeRate(
            item.code_unidad_moneda,
            codeCurrency,
            formatTotalSingle(item.precio_unitario_real, 2)
          );
          const precio_unitario_tax = calculateExchangeRateTax(
            item.code_unidad_moneda,
            codeCurrency,
            formatTotalSingle(item.precio_impuesto_real, 2)
          );
          return {
            ...item,
            precio_unitario,
            monto_impuesto_precio_venta: precio_unitario_tax,
            importe: Number(precio_unitario) * item.volumen,
            monto_impuesto_precio_venta_total:
              Number(precio_unitario_tax) * item.volumen,
          };
        });
        sumAmount(updatedDataMemo);
        return updatedDataMemo;
      });
    }, 1000);
  }, [updateCostProductos]);

  useEffect(() => {
    const cleanedData = data.filter((item) => item.id !== 0);
    setDataMemo(cleanedData);
  }, [data]);

  useEffect(() => {
    if (data && packageId > 0) {
      localStorage.setItem("memo", JSON.stringify(data));
    }
  }, [data]);

  useEffect(() => {
    formik.setFieldValue("update_products", updateNewData);
  }, [updateNewData]);

  return (
    <>
      <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-800 fs-7">
              <th className="w-100px">SKU</th>
              <th className="w-250px">Concepto</th>
              <th className="w-250px">Categoría</th>
              <th className="w-100px">U/M</th>
              <th className="w-100px">Moneda</th>
              <th className="w-150px">Vol.</th>
              <th className="w-200px">Impuesto Unitario</th>
              <th className="w-200px">Precio Unitario</th>
              <th className="w-200px">Importe</th>
              <th className="w-100px"></th>
            </tr>
          </thead>
          <tbody>
            {dataMemo.length > 0 ? (
              dataMemo.map((row, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.sku}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                        {row.id === 0 ? (
                          <Select
                            key={row.id}
                            options={products}
                            styles={styleSelect}
                            placeholder={"Seleccione"}
                            isSearchable={true}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            noOptionsMessage={() => {
                              return (
                                <span>{"No se encontraron registros"}</span>
                              );
                            }}
                            onChange={addProduct}
                            isLoading={loadingPROD}
                            backspaceRemovesValue
                          />
                        ) : (
                          <>{row.nombre}</>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                        <>{row.categoria}</>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.name_unidad_medida}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.code_unidad_moneda}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.id !== 0 ? (
                          <NumericFormat
                            className="form-control w-100px"
                            thousandSeparator={false}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                e.stopPropagation();
                              }
                            }}
                            allowNegative={false}
                            decimalScale={2}
                            value={row.volumen}
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (!value) {
                                e.target.value = "1";
                              }
                            }}
                            isAllowed={(values: any) => {
                              const { floatValue } = values;
                              // Verificar que la parte entera no tenga más de 11 dígitos
                              const integerPart = Math.floor(floatValue);
                              return integerPart.toString().length <= 7;
                            }}
                            suffix=""
                            allowLeadingZeros={false}
                            onValueChange={(values) => {
                              const { floatValue } = values;
                              volumeChange(floatValue ? floatValue : 0, row.id);
                            }}
                          />
                        ) : (
                          <>{"1"}</>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {`${formatPrice(
                          formatTotalSingle(
                            row.monto_impuesto_precio_venta ?? 0,
                            2
                          ),
                          "es-MX",
                          "MXN",
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {`${formatPrice(
                          formatTotalSingle(row.precio_unitario ?? 0, 2),
                          "es-MX",
                          "MXN",
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {`${formatPrice(row.importe, "es-MX", "MXN", 2, 2)}`} /{" "}
                        {codeCurrency}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        <a
                          className="badge badge-light-danger  text-hover-danger fw-bold px-4 py-3 cursor-pointer"
                          title="Eliminar producto"
                          onClick={async (event) => {
                            deleteRowById(row.id);
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen027.svg"
                            className="svg-icon-4"
                          />
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center text-muted">
                  Sin productos
                </td>
              </tr>
            )}

            <tr>
              <td colSpan={9}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={AddRow}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar Producto
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { ProductsList };
