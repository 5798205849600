import { useState, useEffect } from "react";
import { SelectFormat } from "../../../../_metronic/helpers/models/GenericModel";
import { useHistory } from "react-router-dom";
import {
  PaqueteData,
  addCategoryPackages,
  addCreatePackageCopy,
  addSubcategoryPackages,
  addUpdatePackages,
  createHistoryMessagePackages,
  deletePackageCurrentService,
  getCatalogCategories,
  getCatalogPackages,
  getCatalogProducts,
  getCatalogServices,
  getCatalogSubcategories,
  getDocumentsByPackageId,
  getHistoryPackages,
  getNotesByPackageId,
  getPackageById,
  insertNotePackage,
  updateCosts,
} from "../../../service/packages/Packages";
import { SimpleAlert } from "../../../../_metronic/helpers";
import { HistoryModel } from "../../contacto/models/HistoryModel";
import { NotePackageModel } from "../../notes_opp/models/NotePackageModel";
import { PackageDocModel } from "../models/PackageDocModel";

export const useSelectCategories = (reloadCategoryList: number) => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalogCategories()
        .then()
        .catch((error: any) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadCategoryList]);

  return { catalog, loadingRequest };
};

export const useSelectSubcategories = (
  categoryId: number,
  reloadSubcategoryList: number
) => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalogSubcategories(categoryId)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    if (categoryId > 0) {
      fetchPost();
    } else {
      setLoadingRequest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, reloadSubcategoryList]);

  return { catalog, loadingRequest };
};

export const useSelectProducts = () => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalogProducts()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { catalog, loadingRequest };
};

export const useCreateEditPackage = () => {
  const [loadingPackages, setLoadingPackages] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const createEditPackages = async (packageQua: any) => {
    setLoadingPackages(false);
    setErrorRequest(false);
    try {
      const res = await addUpdatePackages(packageQua);
      setLoadingPackages(true);
      setErrorRequest(false);
      SimpleAlert(res.message, 3000, "success");
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, "error");
        setLoadingPackages(true);
      } else {
        setErrorRequest(true);
        history.push("/error/500");
      }
    }
  };
  return { createEditPackages, loadingPackages, errorRequest };
};

export const useCreateCategoryPackages = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const create = async (category: any) => {
    setLoading(false);
    setErrorRequest(false);
    try {
      await addCategoryPackages(category);
      setLoading(true);
      setErrorRequest(false);
      SimpleAlert("Se creó la categoría exitosamente!", 3000, "success");
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, "error");
        setLoading(true);
      } else {
        setErrorRequest(true);
        history.push("/error/500");
      }
    }
  };
  return { create, loading, errorRequest };
};

export const useCreateSubcategoryPackages = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const create = async (subcategory: any, categoryId: number) => {
    setLoading(false);
    setErrorRequest(false);
    try {
      await addSubcategoryPackages(subcategory, categoryId);
      setLoading(true);
      setErrorRequest(false);
      SimpleAlert("Se creó la subcategoría exitosamente!", 3000, "success");
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, "error");
        setLoading(true);
      } else {
        setErrorRequest(true);
        history.push("/error/500");
      }
    }
  };
  return { create, loading, errorRequest };
};

export const useSelectServices = () => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalogServices()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { catalog, loadingRequest };
};

export const useSelectPackages = () => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalogPackages()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { catalog, loadingRequest };
};

export const useGetOne = (id: number) => {
  const initModel: PaqueteData = {
    id: 0,
    nombre: "",
    sku: "",
    descripcion: "",
    package_photo: "",
    precio_unitario: 0,
    categoria_id: 0,
    sub_categoria_id: 0,
    tipo_paquete_id: 0,
    unidad_moneda_id: 0,
    clasif_paquete_id: 0,
    unidad_negocio_id: 0,
    products: [],
    services: [],
    existUpdatesProducts: false,
    existUpdatesServices: false,
  };

  const [initValues, setInitValues] = useState<any>(initModel);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      await getPackageById(id)
        .then((res) => {
          if (res) {
            setInitValues(res);
            setLoadingRequest(false);
          }
        })
        .catch((error) => {
          history.push("/error/500");
        });
    };
    if (id > 0) {
      fetchPost();
    } else {
      setInitValues(initModel);
      setLoadingRequest(false);
    }
  }, [id]);

  return { initValues, loadingRequest };
};

export const useGetHistory = (reaload: number, search: string, id: string) => {
  const [data, setData] = useState<HistoryModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getHistoryPackages(search, id)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setData(res);
        setLoadingRequest(1);
      }
    };
    if (reaload && Number(id) > 0) {
      fetchPost();
    }
  }, [reaload]);

  return { data, loadingRequest };
};

export const useInsertMessagePackage = () => {
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const history = useHistory();
  const insertData = async (message: string, id: number) => {
    setLoadingInsert(false);
    try {
      await createHistoryMessagePackages(message, id);
      setLoadingInsert(true);
    } catch (error) {
      history.push("/error/500");
      setLoadingInsert(false);
    }
  };
  return { insertData, loadingInsert };
};

export const useGetNoteByPackageId = (id: number, reaload: number) => {
  const [notes, setNotes] = useState<NotePackageModel[]>([]);
  const [loadingNote, setLoadingNote] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingNote(false);
    const fetchPost = async () => {
      try {
        const res = await getNotesByPackageId(id);
        if (res) {
          setNotes(res);
          setLoadingNote(true);
        }
      } catch (error) {
        history.push("/error/500");
        setLoadingNote(false);
      }
    };
    if (id) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reaload]);

  return { notes, loadingNote };
};
export const useCreateNotePackage = () => {
  const [loadingCreateNote, setLoadingCreateNote] = useState<boolean>(false);
  const history = useHistory();
  const createNote = async (note: NotePackageModel, id: number) => {
    setLoadingCreateNote(false);
    try {
      await insertNotePackage(note, id);
      setLoadingCreateNote(true);
    } catch (error) {
      history.push("/error/500");
      setLoadingCreateNote(false);
    }
  };
  return { createNote, loadingCreateNote };
};

export const useGetDocumentsByPackageId = (id: number, reload: number) => {
  const [dataDocuments, setDataDocuments] = useState<PackageDocModel[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingDocuments(false);
      const res = await getDocumentsByPackageId(id)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataDocuments(res);
        setLoadingDocuments(true);
      }
    };
    if (id > 0) {
      fetchPost();
    }
  }, [reload]);

  return { dataDocuments, loadingDocuments };
};

export const useCreatePackageCopy = () => {
  const [loadingPackages, setLoadingPackages] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const createPackageCopy = async (id: any) => {
    setLoadingPackages(false);
    setErrorRequest(false);
    try {
      const res = await addCreatePackageCopy(id);
      setLoadingPackages(true);
      setErrorRequest(false);
      SimpleAlert(res.message, 3000, "success");
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, "error");
        setLoadingPackages(true);
      } else {
        setErrorRequest(true);
        history.push("/error/500");
      }
    }
  };
  return { createPackageCopy, loadingPackages, errorRequest };
};

export const useDeletePackageCurrent = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const history = useHistory();
  const deletePackageCurrent = async (id: any) => {
    setLoading(false);
    setErrorRequest(false);
    setIsSuccess(false);
    try {
      const res = await deletePackageCurrentService(id);
      setLoading(true);
      setErrorRequest(false);
      SimpleAlert(res.message, 3000, "success");
      setIsSuccess(true);
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, "error");
        setLoading(true);
      } else {
        setErrorRequest(true);
        history.push("/error/500");
      }
    }
  };
  return { deletePackageCurrent, loading, errorRequest, isSuccess };
};

export const useUpdateCostConcepts = () => {
  const [loadingPackages, setLoadingPackages] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const updateCostConceptsH = async (id: any) => {
    setLoadingPackages(true);
    setErrorRequest(false);
    try {
      const res = await updateCosts(id);
      setLoadingPackages(false);
      setErrorRequest(false);
      SimpleAlert(res.message, 3000, "success");

      window.location.reload();
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, "error");
        setLoadingPackages(true);
      } else {
        setErrorRequest(true);
        history.push("/error/500");
      }
    }
  };
  return { updateCostConceptsH, loadingPackages, errorRequest };
};
