import React, { useState, useEffect } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { MenuComponent } from "../../../../_metronic/assets/ts/components/MenuComponent";
import { ProductList } from "./ProductList";
import { ProductModel } from "../models/QuotationModel";
import { randomClassList } from "../../../../_metronic/helpers/CustomElements";
import { string } from "yup";

type Props = {
  listData: any;
  onRefreshData: () => void;
  onRefreshDataSupplier: () => void;
  isLoading: boolean;
  catUnidadMoneda: any;
  catUnidadMedida: any;
  catTipoImpuesto: any;
  catProveedores: any;
};
interface RowDataList {
  code: string;
  color: string;
  categoriaId: number;
  nombre: string;
  items: any[];
}

const CardList: React.FC<Props> = ({
  listData,
  onRefreshData,
  isLoading,
  catUnidadMoneda,
  catUnidadMedida,
  catTipoImpuesto,
  catProveedores,
  onRefreshDataSupplier,
}) => {
  const [sendCatId, setSendcatId] = useState<number>(0);
  const [data, setData] = useState<RowDataList[]>(listData);
  const [isNewProduct, setIsNewProduct] = useState<boolean>(false);

  useEffect(() => {
    if (listData) {
      listData.map((item: RowDataList) => {
        const randomClass = randomClassList();
        item.color = randomClass;
        item.items.map((row: any) => {
          if (row.unidad_medida_venta && row.inventario) {
            const expectedConcat = `${row.inventario.split("/")[0]}/${
              row.unidad_medida_venta_code
            }`;
            if (row.inventario !== expectedConcat) {
              row.inventario = expectedConcat;
            }
          }
        });
      });

      setData(listData);
    }
  }, [listData]);

  useEffect(() => {
    if (!isNewProduct) {
      let removeDataSingle = data.map((categoria) => ({
        ...categoria,
        items: categoria.items.filter((item) => item.id !== 0),
      }));
      setData(removeDataSingle);
    } else {
      if (data.length) {
        data.map((item: any) => {
          if (item.categoriaId == sendCatId) {
            const newItemProduct: ProductModel = {
              id: 0,
              nombre: "Nuevo Producto",
              unidad_medida_venta: "",
              unidad_moneda_venta: "",
              impuesto: {},
              precio_venta: "0",
              precio_fijo_venta: "0",
              inventario: 0,
              impuesto_porcentaje: 0,
            };
            item.items.push(newItemProduct);
          }
        });
      }
    }
  }, [isNewProduct]);

  const columns: TableColumn<RowDataList>[] = [
    {
      name: "",
      selector: (row: RowDataList) => row.nombre,
      cell: (row: any) => (
        <>
          <span
            className={`badge fs-7 fw-bold mr-15 min-w-150px large-text`}
            style={{
              color: "#fff",
              backgroundColor: row.color ? row.color : "#9096EC",
            }}
          >
            {row.nombre}
          </span>
          <div
            className="badge badge-light-primary fs-7 fw-bold mr-15"
            title="Total de productos"
          >
            {row.items.length}
          </div>
          <a
            className="cursor-pointer badge badge-light-primary"
            title="Agregar producto"
            onClick={() => {
              setIsNewProduct(!isNewProduct);
              setSendcatId(row.categoriaId);
            }}
            role="button"
          >
            + Agregar producto
          </a>
        </>
      ),

      sortable: false,
      width: "250px",
    },
  ];

  const listGral = (d: any) => {
    return (
      <>
        <ProductList
          items={d.data.items}
          onRefreshData={onRefreshData}
          categoriId={sendCatId}
          isNewProduct={isNewProduct}
          catUnidadMoneda={catUnidadMoneda}
          catUnidadMedida={catUnidadMedida}
          catTipoImpuesto={catTipoImpuesto}
          catProveedores={catProveedores}
          onRefreshDataSupplier={onRefreshDataSupplier}
        />
      </>
    );
  };

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        responsive
        expandableRows
        expandableRowsComponent={listGral}
        noDataComponent={
          <>
            <div className="d-flex align-items-center">
              <div className="text-muted text-center fs-6 w-100 m-5">
                Sin registros
              </div>
            </div>
          </>
        }
        onRowExpandToggled={(event: any) => {
          if (!event) {
            setIsNewProduct(event);
          }
        }}
      />
    </>
  );
};

export default CardList;
