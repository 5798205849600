/* eslint-disable react-hooks/rules-of-hooks */
// export const formatPrice = ( price: number, locale: string, currency: string, minDigits: number, maxDigits: number) => {
//    return Number(price).toLocaleString(locale, {
//       style: 'currency',
//       currency: currency,
//       minimumFractionDigits: minDigits,
//       maximumFractionDigits: maxDigits,
//     })
// }

export const formatPrice = (
  price: number,
  locale: string,
  currency: string,
  minDecimals: number,
  maxDecimals: number
) => {
  if (isNaN(price)) {
    return "Invalid price";
  }

  const priceString = price.toString();
  const decimalIndex = priceString.indexOf(".");

  // Si no hay punto decimal o el número de decimales ya es menor o igual a maxDecimals, simplemente formatea el número.
  if (
    decimalIndex === -1 ||
    priceString.length - decimalIndex - 1 <= maxDecimals
  ) {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(price);
  }

  // Si hay más de maxDecimals, recorta el string.
  const truncatedPrice = priceString.substring(
    0,
    decimalIndex + maxDecimals + 1
  );
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(parseFloat(truncatedPrice));
};

export const formatTotalSingle = (value: number, decimals: number) => {
  const valueString = value.toString();
  const decimalIndex = valueString.indexOf(".");

  // Si no hay punto decimal o el número de decimales ya es menor o igual a 'decimals', simplemente devuelve el número.
  if (
    decimalIndex === -1 ||
    valueString.length - decimalIndex - 1 <= decimals
  ) {
    return parseFloat(valueString);
  }

  // Si hay más de 'decimals', recorta el string.
  const truncatedValue = valueString.substring(0, decimalIndex + decimals + 1);
  return parseFloat(truncatedValue);
};
