import React, { useState, useEffect } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { MenuComponent } from "../../../../_metronic/assets/ts/components/MenuComponent";
import { ServiceList } from "./ServiceList";
import { randomClassList } from "../../../../_metronic/helpers/CustomElements";
import { ServiceModel } from "../models/QuotationModel";

type Props = {
  listData: any;
  onRefreshData: () => void;
  onRefreshDataSupplier: () => void;
  catUnidadMoneda: any;
  catUnidadMedida: any;
  catTipoImpuesto: any;
  catProveedores: any;
};
interface RowDataList {
  code: string;
  color: string;
  categoriaId: number;
  nombre: string;
  items: any[];
}

const CardListServices: React.FC<Props> = ({
  listData,
  onRefreshData,
  catUnidadMoneda,
  catUnidadMedida,
  catTipoImpuesto,
  catProveedores,
  onRefreshDataSupplier,
}) => {
  const [sendCatId, setSendcatId] = useState<number>(0);
  const [data, setData] = useState<RowDataList[]>(listData);
  const [isNewService, setIsNewService] = useState<boolean>(false);

  useEffect(() => {
    if (listData) {
      listData.map((item: RowDataList) => {
        const randomClass = randomClassList();
        item.color = randomClass;
      });

      setData(listData);
    }
  }, [listData]);

  useEffect(() => {
    if (!isNewService) {
      let removeDataSingle = data.map((categoria) => ({
        ...categoria,
        items: categoria.items.filter((item) => item.id !== 0),
      }));
      setData(removeDataSingle);
    } else {
      if (data.length) {
        data.map((item: any) => {
          if (item.categoriaId == sendCatId) {
            const newItemService: ServiceModel = {
              id: 0,
              nombre: "Nuevo Servicio",
              unidad_medida_venta: "",
              unidad_moneda_venta: "",
              impuesto: {},
              precio_venta: "0",
              precio_fijo_venta: "0",
              inventario: 0,
              impuesto_porcentaje: 0,
            };
            item.items.push(newItemService);
          }
        });
      }
    }
  }, [isNewService]);

  const columns: TableColumn<RowDataList>[] = [
    {
      name: "",
      selector: (row: RowDataList) => row.nombre,
      cell: (row: any) => (
        <>
          <span
            className="badge fs-7 fw-bold mr-15 min-w-150px"
            style={{
              color: "#fff",
              backgroundColor: row.color ? row.color : "#9096EC",
            }}
          >
            {row.nombre}
          </span>
          <div
            className="badge badge-light-primary fs-7 fw-bold mr-15"
            title="Total de productos"
          >
            {row.items.length}
          </div>
          <a
            className="cursor-pointer badge badge-light-primary"
            title="Agregar servicio"
            onClick={() => {
              setIsNewService(!isNewService);
              setSendcatId(row.categoriaId);
            }}
            role="button"
          >
            + Agregar servicio
          </a>
        </>
      ),

      sortable: false,
      width: "250px",
    },
  ];

  const listGral = (d: any) => {
    return (
      <>
        <ServiceList
          items={d.data.items}
          onRefreshData={onRefreshData}
          categoriId={sendCatId}
          isNewService={isNewService}
          catUnidadMoneda={catUnidadMoneda}
          catUnidadMedida={catUnidadMedida}
          catTipoImpuesto={catTipoImpuesto}
          catProveedores={catProveedores}
          onRefreshDataSupplier={onRefreshDataSupplier}
        />
      </>
    );
  };

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        responsive
        expandableRows
        expandableRowsComponent={listGral}
        noDataComponent={
          <>
            <div className="d-flex align-items-center">
              <div className="text-muted text-center fs-6 w-100 m-5">
                Sin registros
              </div>
            </div>
          </>
        }
        onRowExpandToggled={(event) => {
          if (!event) {
            setIsNewService(event);
          }
        }}
      />
    </>
  );
};

export default CardListServices;
