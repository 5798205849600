import React, { useState, useEffect, useRef } from "react";
import { PackageModel } from "../models/QuotationModel";
import { Link } from "react-router-dom";
import { encryptId } from "../../../../_metronic/helpers";
import { formattedCurrency } from "../../../../_metronic/helpers/FormatCurrency";

type Props = {
  items: PackageModel[];
  onRefreshData: () => void;
  active: boolean;
  isEdit: boolean;
  categoriId: number;
};

const PackageList: React.FC<Props> = ({
  items,
  active,
  isEdit,
  categoriId,
  onRefreshData,
}) => {
  const [taskMemo, setTaskMemo] = useState<PackageModel[]>(items);
  const [reloadGrid, setReloadGrid] = useState<number>(Math.random() * 40);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showModalSupplier, setShowModalSupplier] = useState<boolean>(false);
  const [idSection, setIdSection] = useState<number>(0);

  useEffect(() => {
    setTaskMemo(items);
  }, [items]);

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-200px">Nombre</th>
              {/* <th className="w-200px">Foto</th> */}
              <th className="w-200px">Descripción</th>
              <th className="w-200px">Precio</th>
            </tr>
          </thead>
          <tbody className="">
            {taskMemo.map((row, index) => (
              <tr key={row.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-muted">
                      <Link
                        to={`/cotizaciones/paquetes/edit/${encryptId(
                          row.id.toString()
                        )}`}
                      >
                        {`${row.nombre}`}
                      </Link>
                    </div>
                  </div>
                </td>
                {/* {row.package_photo ? <>si hay imagen</> : <>no hay imagen</>} */}
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.descripcion}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.precio_unitario ? (
                        `${formattedCurrency(parseFloat(row.precio_unitario))}`
                      ) : (
                        <>Sin precio</>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export { PackageList };
