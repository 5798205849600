import React, { useState, useEffect, useRef } from "react";
import { ProductModel } from "../models/QuotationModel";
import { KTSVG, SimpleAlert, encryptId } from "../../../../_metronic/helpers";
import Swal from "sweetalert2";
import {
  useInsertProductShortcut,
  useUPdateProductShortcut,
  useUploadImageProd,
  useUploadDocProd,
  removeFile,
  useRemoveProduct,
} from "../hooks/ProductsHook";
import { downloadDoc } from "../../../service/products/Products";
import Select from "react-select";
import { styleSelectProductShortCut } from "../../../../_metronic/helpers/SelectStyles";
import { CreateSupplierModal } from "../_modals/CreateSupplierModal";
import { getExtensionFile } from "../../../../_metronic/helpers/GetExtensionFile";
import { formattedCurrency } from "../../../../_metronic/helpers/FormatCurrency";
import { NumericFormat } from "react-number-format";
import { useHistory } from "react-router-dom";

type Props = {
  items: ProductModel[];
  onRefreshData: () => void;
  onRefreshDataSupplier: () => void;
  isNewProduct: boolean;
  categoriId: number;
  catUnidadMoneda: any;
  catUnidadMedida: any;
  catTipoImpuesto: any;
  catProveedores: any;
};

const ProductList: React.FC<Props> = ({
  items,
  isNewProduct,
  categoriId,
  onRefreshData,
  onRefreshDataSupplier,
  catUnidadMoneda,
  catUnidadMedida,
  catTipoImpuesto,
  catProveedores,
}) => {
  const history = useHistory();
  const [taskMemo, setTaskMemo] = useState<ProductModel[]>([]);
  const [taskName, setTaskName] = useState<string>("");
  const itemsRefImg = useRef<any>([]);
  const itemsRefDoc = useRef<any>([]);
  const inputInvetaryRefs = useRef<any>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const { insertProductShort, isInsertedCorrect } = useInsertProductShortcut();
  const { updateProductShort, isCorrectUpdateP } = useUPdateProductShortcut();
  const { insertImageproduct, uploadImgCorrect } = useUploadImageProd();
  const { insertDocproduct } = useUploadDocProd();
  const [showModalSupplier, setShowModalSupplier] = useState<boolean>(false);
  const [idSection, setIdSection] = useState<number>(0);
  const { removeProducts, isDeletedCorrect } = useRemoveProduct();
  const [removePesoSign, setRemovePesoSign] = useState<boolean>(false);

  const onOpenFileImage = (event: any, currentItem: number) => {
    event.preventDefault();
    event.stopPropagation();
    itemsRefImg.current[currentItem].click();
  };

  const onOpenFileDoc = (event: any, currentIten: number) => {
    event.preventDefault();
    event.stopPropagation();
    itemsRefDoc.current[currentIten].click();
  };

  const sendTask = async () => {
    if (taskName.trim() !== "") {
      await insertProductShort(taskName, categoriId).then(() => {
        setTaskName("");
      });
    }
  };

  useEffect(() => {
    if (
      isDeletedCorrect ||
      isInsertedCorrect ||
      uploadImgCorrect ||
      isCorrectUpdateP
    ) {
      onRefreshData();
    }
  }, [isDeletedCorrect, isInsertedCorrect, uploadImgCorrect, isCorrectUpdateP]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [isNewProduct]);

  useEffect(() => {
    if (inputRef.current && document.activeElement === inputRef.current) {
      inputRef.current.classList.add("input-scroll-animation");
      inputRef.current.scrollIntoView({ behavior: "smooth", block: "center" });

      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.classList.remove("input-scroll-animation");
        }
      }, 500); // 0.5 segundos, igual a la duración de la animación CSS
    }
  }, [inputRef.current]);

  useEffect(() => {
    setTaskMemo(items);
  }, [items]);

  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendTask();
    }
  };
  const handleInputBlur = () => {
    setTaskMemo((prevTasks) => prevTasks.filter((task) => task.id !== 0));
    setTaskName("");
  };

  const handleOnKeyDownPriceFijoInput = async (e: any, id: any) => {
    let value = e.target.value.replace("$", "");
    if (e.key === "Enter") {
      try {
        await updateProductShort({
          id: id,
          precio_fijo_venta: value * 1 ?? 0,
        });
      } catch (error) {
        setTaskMemo((prevState) => [...prevState]);
      }
    }
  };

  const handleOnChangePriceSale = async (e: any, id: any) => {
    const value = e.target.value;
    setRemovePesoSign(true);
    const numbersOnly = value.replace(/[^\d.]/g, "");
    const match = numbersOnly.match(/\d*(\.\d{0,2})?/);

    const formattedValue = match ? match[0] : "";
    const precioFijoVenta = parseFloat(formattedValue);

    const updatedItems = taskMemo.map((item) => {
      if (item.id === id) {
        const incrementoPorImpuesto: number =
          item.impuesto_porcentaje * precioFijoVenta;

        const precioVentaFinal: any = precioFijoVenta + incrementoPorImpuesto;
        const formatedPriceToSale = formattedCurrency(
          precioVentaFinal ? parseFloat(precioVentaFinal) : 0
        );

        return {
          ...item,
          precio_fijo_venta: formattedValue ?? 0,
          precio_venta: formatedPriceToSale ?? 0,
        };
      }
      return item;
    });

    setTaskMemo(updatedItems);
  };

  // start input name
  const handleNameChange = (e: any, id: any) => {
    const updatedItems = taskMemo.map((item) => {
      if (item.id === id) {
        return { ...item, nombre: e.target.value };
      }
      return item;
    });
    setTaskMemo(updatedItems);
  };
  const handleEnterName = async (e: any, id: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputNombre = e.target.value;
      if (inputNombre) {
        try {
          await updateProductShort({
            id: id,
            nombre: inputNombre,
          });
          const updatedItems = taskMemo.map((item) => {
            if (item.id === id) {
              return { ...item, nombre: inputNombre };
            }
            return item;
          });
          setTaskMemo(updatedItems);
        } catch (error) {
          setTaskMemo((prevState) => [...prevState]);
        }
      }
    }
  };
  // end input name

  // start input inventario
  const handleEnterPress = async (e: any, id: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputInventario = e.target.value;

      if (inputInventario) {
        try {
          await updateProductShort({
            id: id,
            inventario: inputInventario,
          });
          const updatedItems = taskMemo.map((item) => {
            if (item.id === id) {
              return { ...item, inventario: inputInventario };
            }
            return item;
          });
          setTaskMemo(updatedItems);
        } catch (error) {
          setTaskMemo((prevState) => [...prevState]);
        }
      }
    }
  };
  const handleInventoryChange = (e: any, id: any) => {
    const value = e.target.value;

    if (value) {
      if (value === "" || /^\d*\.?\d{0,1}$/.test(value)) {
        const updatedItems = taskMemo.map((item) => {
          if (item.id === id) {
            return { ...item, inventario: value };
          }
          return item;
        });

        setTaskMemo(updatedItems);
      }
    }
  };
  // end input inventario

  // EVENTOS PARA SELECT DE CATALOGOS
  const handleUnitMedidaSelect = async (selectedOption: any, id: any) => {
    const { value } = selectedOption;

    if (value) {
      try {
        await updateProductShort({
          id: id,
          unidad_medida_venta_id: value,
        });
        const updatedItems = taskMemo.map((item) => {
          if (item.id === id) {
            return { ...item, unidad_medida_venta_id: value };
          }
          return item;
        });
        setTaskMemo(updatedItems);
      } catch (error) {
        setTaskMemo((prevState) => [...prevState]);
      }
    }
  };

  const handleUnitCurrencySelect = async (selectedOption: any, id: any) => {
    const { value } = selectedOption;

    if (value) {
      try {
        await updateProductShort({
          id: id,
          unidad_moneda_venta_id: value,
        });
        const updatedItems = taskMemo.map((item) => {
          if (item.id === id) {
            return { ...item, unidad_moneda_venta_id: value };
          }
          return item;
        });
        setTaskMemo(updatedItems);
      } catch (error) {
        setTaskMemo((prevState) => [...prevState]);
      }
    }
  };

  const handleTaxesSelect = async (selectedOption: any, id: any) => {
    const { value } = selectedOption;

    if (value) {
      try {
        await updateProductShort({
          id: id,
          impuesto_id: value,
        });
        const updatedItems = taskMemo.map((item) => {
          if (item.id === id) {
            return { ...item, impuesto_id: value };
          }
          return item;
        });
        setTaskMemo(updatedItems);
      } catch (error) {
        setTaskMemo((prevState) => [...prevState]);
      }
    }
  };

  const handleProveedorSelect = async (selectedOption: any, id: any) => {
    const { value } = selectedOption;

    if (value && value === "new") {
      setShowModalSupplier(true);
      setIdSection(id);
      return false;
    }

    if (value) {
      try {
        await updateProductShort({
          id: id,
          proveedor_id: value,
        });
        const updatedItems = taskMemo.map((item) => {
          if (item.id === id) {
            return { ...item, proveedor_id: value };
          }
          return item;
        });
        setTaskMemo(updatedItems);
      } catch (error) {
        setTaskMemo((prevState) => [...prevState]);
      }
    }
  };

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-230px">Nombre</th>
              <th className="w-70px">Foto</th>
              <th className="w-150px text-center">Unidad de medida/venta</th>
              <th className="w-150px text-center">Unidad de moneda</th>
              <th className="w-150px text-center">Precio de venta</th>
              <th className="w-100px">Tipo de impuesto</th>
              <th className="w-200px text-center">Precio/venta final</th>
              <th className="w-100px">Inventario</th>
              <th className="w-130px">Proveedor</th>
              <th className="w-100px">Archivo</th>
              <th className="w-100px">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {taskMemo.map((row: ProductModel, index: number) => (
              <tr key={index}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-muted">
                      {row.id === 0 ? (
                        <input
                          type="text"
                          value={taskName}
                          className="w-100 border-0"
                          ref={inputRef}
                          onChange={(e) => setTaskName(e.target.value)}
                          onKeyDown={onEnterPress}
                          style={{ outline: "none" }}
                          placeholder={row.nombre}
                          onBlur={handleInputBlur}
                          maxLength={40}
                        ></input>
                      ) : (
                        <>
                          {/* <input
                            type="text"
                            value={row.nombre ?? ''}
                            className="w-100 border-0 text-primary large-text"
                            style={{ outline: 'none' }}
                            title={row.nombre}
                            onChange={(e) => handleNameChange(e, row.id)}
                            onKeyDown={(e) => handleEnterName(e, row.id)}
                            maxLength={40}
                          /> */}

                          <a
                            className="text-muted  mb-2 text-hover-primary large-text cursor-pointer"
                            title={row.nombre}
                            onClick={() => {
                              history.push(
                                `/cotizaciones/productos/edit/${encryptId(
                                  row.id.toString()
                                )}`
                              );
                            }}
                          >
                            {row.nombre}
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                </td>
                {row?.id ? (
                  <>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-012">
                          <div className="symbol symbol-25px tooltip-soft">
                            <div>
                              <input
                                key={index}
                                style={{ display: "none" }}
                                ref={(el) => (itemsRefImg.current[index] = el)}
                                type="file"
                                name={`img_${row.id}`}
                                accept=".jpg, .png"
                                onChange={async (event: any) => {
                                  if (event.currentTarget.files) {
                                    let files = event.currentTarget.files;
                                    if (files.length) {
                                      const file = files[0];
                                      const { isExtensionAllowed } =
                                        getExtensionFile(file);

                                      if (isExtensionAllowed) {
                                        await insertImageproduct(files, row.id);
                                      } else {
                                        SimpleAlert(
                                          "Tipo de archivo no permitido",
                                          3000,
                                          "error"
                                        );
                                      }
                                    }
                                  }
                                }}
                              />
                              <a
                                className="btn btn-sm btn-icon btn-active-light-primary btn-light-primary"
                                onClick={(event) => {
                                  onOpenFileImage(event, index);
                                }}
                                title="Subir foto de producto"
                              >
                                {row.imagen ? (
                                  <img
                                    src={`${process.env.REACT_APP_PUBLIC}producto/${row?.imagen}`}
                                    title={row.nombre}
                                    crossOrigin="anonymous"
                                    className="symbol-label fs-5 "
                                    alt="user"
                                  />
                                ) : (
                                  <KTSVG
                                    path="/media/icons/duotune/general/gen006.svg"
                                    className="svg-icon-5 mr-05 "
                                  />
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                          <Select
                            options={catUnidadMedida}
                            styles={styleSelectProductShortCut}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            placeholder="Seleccione"
                            isSearchable={true}
                            noOptionsMessage={() =>
                              "No se encontraron registros"
                            }
                            onChange={(event: any) => {
                              handleUnitMedidaSelect(event, row.id);
                            }}
                            value={catUnidadMedida.find(
                              (option: any) =>
                                option.value === row.unidad_medida_venta_id
                            )}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex text-center flex-column fs-012 text-muted w-100">
                          <Select
                            options={catUnidadMoneda}
                            styles={styleSelectProductShortCut}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            placeholder="Seleccione"
                            isSearchable={true}
                            noOptionsMessage={() =>
                              "No se encontraron registros"
                            }
                            onChange={(event: any) => {
                              handleUnitCurrencySelect(event, row.id);
                            }}
                            value={catUnidadMoneda.find(
                              (option: any) =>
                                option.value === row.unidad_moneda_venta_id
                            )}
                          />
                        </div>
                      </div>
                    </td>
                    <td style={{ minWidth: "150px", overflowX: "auto" }}>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                          <div
                            className="d-flex align-items-center w-100"
                            style={{
                              padding: "0.375rem 0.75rem",
                              borderRadius: "0.25rem",
                            }}
                          >
                            <input
                              type="text"
                              defaultValue={`${
                                row.precio_fijo_venta
                                  ? formattedCurrency(
                                      parseFloat(row.precio_fijo_venta)
                                    )
                                  : 0
                              }`}
                              className="w-100 border-0 text-success fw-bold"
                              style={{ outline: "none" }}
                              onKeyDown={(e) =>
                                handleOnKeyDownPriceFijoInput(e, row.id)
                              }
                              onChange={(e) =>
                                handleOnChangePriceSale(e, row.id)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                          <Select
                            className="badge"
                            options={catTipoImpuesto}
                            styles={styleSelectProductShortCut}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            placeholder="Seleccione"
                            isSearchable={true}
                            noOptionsMessage={() =>
                              "No se encontraron registros"
                            }
                            onChange={(event: any) => {
                              handleTaxesSelect(event, row.id);
                            }}
                            value={catTipoImpuesto.find(
                              (option: any) => option.value === row.impuesto_id
                            )}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-012 text-primary  fw-bold ">
                          {row.precio_venta
                            ? `${removePesoSign ? "" : "$"}${row.precio_venta}`
                            : `${0}`}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                          <NumericFormat
                            placeholder={row.inventario?.toString() ?? ""}
                            getInputRef={(el: any) =>
                              (inputInvetaryRefs.current[index] = el)
                            }
                            thousandSeparator={false}
                            decimalSeparator={"."}
                            allowNegative={false}
                            decimalScale={1}
                            className="w-100 border-0 text-muted fs-012"
                            style={{ outline: "none" }}
                            onChange={(e) => handleInventoryChange(e, row.id)}
                            onKeyDown={(e) => handleEnterPress(e, row.id)}
                            // onFocus={() => {
                            //   inputInvetaryRefs.current[index].select();
                            // }}
                          />
                        </div>
                      </div>
                    </td>
                    <td
                      style={{ overflow: "none" }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                          <div>
                            <Select
                              options={catProveedores}
                              styles={styleSelectProductShortCut}
                              menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              placeholder="Seleccione"
                              isSearchable={true}
                              noOptionsMessage={() =>
                                "No se encontraron registros"
                              }
                              onChange={(selectedOption) =>
                                handleProveedorSelect(selectedOption, row.id)
                              }
                              value={catProveedores.find(
                                (option: any) =>
                                  option.value === row.proveedor_id
                              )}
                              backspaceRemovesValue
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                          <div className="space-items-between d-flex flex-shrink-0">
                            <div className="mr-05">
                              <input
                                key={index}
                                style={{ display: "none" }}
                                ref={(el) => (itemsRefDoc.current[index] = el)}
                                type="file"
                                name="documentos"
                                accept=".xlsx, .xlsm, .txt, .pdf, .jpg, .png"
                                onChange={async (event: any) => {
                                  if (event.currentTarget.files) {
                                    let files = event.currentTarget.files;
                                    if (files.length) {
                                      await insertDocproduct(
                                        files,
                                        row.id
                                      ).then(() => {
                                        onRefreshData();
                                      });
                                    }
                                  }
                                }}
                              />
                              <a
                                className="btn btn-sm btn-icon btn-active-light-primary pulse btn-light-primary"
                                onClick={(event) => {
                                  onOpenFileDoc(event, index);
                                }}
                                title="Subir archivo"
                              >
                                <KTSVG
                                  path="/media/icons/duotune/files/fil029.svg"
                                  className="svg-icon-6"
                                />
                                {row.documento_id ? (
                                  <span className="pulse-ring"></span>
                                ) : null}
                              </a>
                            </div>
                            {row.documento_id ? (
                              <div>
                                <div
                                  data-kt-menu-trigger="click"
                                  data-kt-menu-attach="parent"
                                  data-kt-menu-placement="bottom-end"
                                  data-kt-menu-flip="bottom"
                                  data-kt-menu="true"
                                  className="btn btn-sm btn-icon btn-active-light-primary btn-light-primary center-items"
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/general/gen053.svg"
                                    className="svg-icon-5"
                                  />
                                </div>
                                <div
                                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4"
                                  data-kt-menu="true"
                                >
                                  <div className="menu-item px-3 center-items">
                                    <a
                                      className="menu-link px-3 fs-8"
                                      onClick={(event) => {
                                        downloadDoc(
                                          row.documento_id
                                            ? row.documento_id
                                            : 0,
                                          row.documento_nombre_original
                                            ? row.documento_nombre_original
                                            : "",
                                          event
                                        );
                                      }}
                                    >
                                      <KTSVG
                                        path="/media/icons/duotune/files/fil021.svg"
                                        className="svg-icon-6 mr-05 "
                                      />
                                      Descargar
                                    </a>
                                  </div>
                                  <div className="menu-item px-3 center-items">
                                    <a
                                      className="menu-link px-3 fs-8"
                                      onClick={async (event) => {
                                        await removeFile(
                                          row.documento_id
                                            ? row.documento_id
                                            : 0,
                                          event
                                        ).then(() => {
                                          onRefreshData();
                                        });
                                      }}
                                    >
                                      <KTSVG
                                        path="/media/icons/duotune/general/gen027.svg"
                                        className="svg-icon-7 mr-05 "
                                      />
                                      Eliminar
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="center-items">
                        <div>
                          <a
                            className="btn btn-sm btn-active-light-primary btn-icon btn-light-primary"
                            onClick={(event) => {
                              Swal.fire({
                                title: "¿Estas seguro?",
                                html: `
                                <div className="text-gray-600">
                                  Se va a eliminar el producto con nombre <b>${row.nombre}</b>, una vez
                                  eliminado ya no se podrá modificar ningún dato de este
                                  producto.
                                </div>
                                `,
                                showCancelButton: true,
                                padding: "2em",
                                cancelButtonText: "Cancelar",
                                confirmButtonText: "Aceptar",
                                reverseButtons: true,
                                customClass: {
                                  confirmButton: "btn btn-primary",
                                  cancelButton: "btn btn-light me-3",
                                },
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  removeProducts(Number(row.id));
                                }
                              });
                            }}
                            title="Eliminar producto"
                          >
                            <KTSVG path="/media/icons/duotune/general/gen027.svg" />
                          </a>
                        </div>
                      </div>
                    </td>
                  </>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <CreateSupplierModal
        show={showModalSupplier}
        typeProveedor={1}
        handleClose={(refresh?: boolean) => {
          setShowModalSupplier(!showModalSupplier);
          if (refresh) {
            onRefreshData();
            onRefreshDataSupplier();
          }
        }}
        itemSelected={idSection}
      ></CreateSupplierModal>
    </>
  );
};

export { ProductList };
