import React from "react";
import { AsideMenuItem } from "./AsideMenuItem";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { shallowEqual, useSelector } from "react-redux";
import { UserModel } from "../../../../app/modules/auth/models/UserModel";
import { RootState } from "../../../../setup/index";
import { userHasRole } from "../../../helpers";

export function AsideMenuMain() {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;

  return (
    <>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className=" text-gray-600 fw-bolder text-uppercase fs-6 ls-1">
            CRM
          </span>
        </div>
      </div>
      {userHasRole(["ADMIN", "CMA", "TEC", "DIR", "RH", "SOP"], userRoles) && (
        <>
          <AsideMenuItem
            to="/index"
            icon="/media/icons/duotune/general/gen001.svg"
            title="Inicio"
          />
          {userHasRole(["ADMIN", "CMA", "DIR"], userRoles) && (
            <>
              <AsideMenuItemWithSub
                icon="/media/icons/duotune/graphs/gra008.svg"
                fontIcon="bi-layers"
                to="/dashboard"
                title="Dashboard"
                hasBullet={false}
              >
                <AsideMenuItem
                  to="/dashboard/proyecciones/"
                  title="Proyecciones de Ventas"
                />
                <AsideMenuItem
                  to="/dashboard/ventas/"
                  title="Ventas y Ventas Perdidas"
                />
              </AsideMenuItemWithSub>
            </>
          )}
        </>
      )}

      {userHasRole(["ADMIN", "CMA", "DIR"], userRoles) && (
        <AsideMenuItemWithSub
          icon="/media/icons/duotune/communication/com005.svg"
          fontIcon="bi-layers"
          to="/clientes"
          title="Clientes"
          hasBullet={false}
        >
          <AsideMenuItem to="/clientes/cuentas/" title="Cuentas" />
          <AsideMenuItem to="/clientes/contactos/" title="Contactos" />
        </AsideMenuItemWithSub>
      )}
      {userHasRole(["ADMIN", "CMA", "TEC", "DIR"], userRoles) && (
        <AsideMenuItemWithSub
          icon="/media/icons/duotune/general/gen022.svg"
          fontIcon="bi-app-indicator"
          to="/oportunidades"
          title="Oportunidades"
          hasBullet={false}
        >
          <AsideMenuItem to="/oportunidades" title="Mis Oportunidades" />
        </AsideMenuItemWithSub>
      )}
      {userHasRole(["ADMIN", "CMA", "TEC", "DIR"], userRoles) && (
        <AsideMenuItemWithSub
          icon="/media/icons/duotune/general/gen005.svg"
          fontIcon="bi-app-indicator"
          to="/cotizaciones"
          title="Cotizaciones"
          hasBullet={false}
          isBeta={true}
        >
          <AsideMenuItem
            to="/cotizaciones/cotizaciones"
            title="Mis Cotizaciones"
            isBeta
          />
          <AsideMenuItem
            to="/cotizaciones/productos"
            title="Productos"
            isBeta
          />
          <AsideMenuItem
            to="/cotizaciones/servicios"
            title="Servicios"
            isBeta
          />
          <AsideMenuItem to="/cotizaciones/paquetes" title="Paquetes" isBeta />
        </AsideMenuItemWithSub>
      )}
      {userHasRole(["ADMIN", "CMA", "DIR"], userRoles) && (
        <AsideMenuItem
          to="/envio_correo"
          icon="/media/icons/duotune/communication/com002.svg"
          title="Envío de correo"
          fontIcon="bi-app-indicator"
        />
      )}

      {userHasRole(["ADMIN", "DIR"], userRoles) && (
        <AsideMenuItemWithSub
          icon="/media/icons/duotune/communication/com006.svg"
          fontIcon="bi-app-indicator"
          to="/ventas"
          title="Ventas"
          hasBullet={false}
        >
          <AsideMenuItem
            to="/ventas/vendedor"
            title="Vendedor"
            fontIcon="bi-app-indicator"
          />
        </AsideMenuItemWithSub>
      )}
      <div className="separator border-gray-300"></div>

      {userHasRole(["ADMIN", "DIR", "CMA"], userRoles) && (
        <AsideMenuItemWithSub
          icon="/media/icons/duotune/coding/cod001.svg"
          fontIcon="bi-layers"
          to="/catalogos/"
          title="Ajustes"
          hasBullet={false}
        >
          {userHasRole(["ADMIN", "CMA", "DIR"], userRoles) && (
            <AsideMenuItem to="/plantilla_correo" title="Plantilla de correo" />
          )}

          {userHasRole(["ADMIN", "DIR", "CMA"], userRoles) && (
            <AsideMenuItemWithSub
              fontIcon="bi-layers"
              to="/catalogos/categorias"
              title="Categorías"
              hasBullet={false}
            >
              {userHasRole(["ADMIN", "CMA", "DIR"], userRoles) && (
                <>
                  <AsideMenuItem to="/categorias/productos" title="Productos" />
                  <AsideMenuItem to="/categorias/servicios" title="Servicios" />
                  <AsideMenuItem to="/categorias/paquetes" title="Paquetes" />
                </>
              )}
            </AsideMenuItemWithSub>
          )}
          {userHasRole(["ADMIN", "DIR", "CMA"], userRoles) && (
            <AsideMenuItemWithSub
              fontIcon="bi-layers"
              to="/catalogos/subcategorias"
              title="Subcategorías"
              hasBullet={false}
            >
              {userHasRole(["ADMIN", "CMA", "DIR"], userRoles) && (
                <>
                  <AsideMenuItem
                    to="/subcategorias/productos"
                    title="Productos"
                  />
                  <AsideMenuItem
                    to="/subcategorias/servicios"
                    title="Servicios"
                  />
                  <AsideMenuItem
                    to="/subcategorias/paquetes"
                    title="Paquetes"
                  />
                </>
              )}
            </AsideMenuItemWithSub>
          )}
          {userHasRole(["ADMIN", "DIR"], userRoles) && (
            <>
              <AsideMenuItem
                to="/catalogos/generico/DEP"
                title="Departamentos"
              />
              <AsideMenuItem to="/catalogos/generico/ETP" title="Etapas" />
              {/* <AsideMenuItem
                to="/catalogos/generico/STST"
                title="Estatus tareas"
              /> */}
              <AsideMenuItem to="/catalogos/generico/IND" title="Industrias" />

              <AsideMenuItem
                to="/catalogos/generico/TMOE"
                title="Tamaño de empresa"
              />
              <AsideMenuItem
                to="/catalogos/generico/TCA"
                title="Tipo de cuenta"
              />
              {/* <AsideMenuItem
                to="/catalogos/generico/TDR"
                title="Tipo de dirección"
              /> */}
              <AsideMenuItem
                to="/catalogos/generico/TOP"
                title="Tipo de oportunidad"
              />
              {/* <AsideMenuItem
                to="/catalogos/generico/TVE"
                title="Tipo de vendedor"
              /> */}
              <AsideMenuItem
                to="/catalogos/generico/UNEG"
                title="Unidad de negocio"
              />
              <AsideMenuItem
                to="/catalogos/generico/UMON"
                title="Unidad de moneda"
              />
              <AsideMenuItem
                to="/catalogos/generico/TPACK"
                title="Tipo de paquete"
              />
              <AsideMenuItem
                to="/catalogos/generico/TCOM"
                title="Tipo de compra producto"
              />
              <AsideMenuItem
                to="/catalogos/generico/UNMED"
                title="Unidad de medida producto"
              />
              <AsideMenuItem
                to="/catalogos/generico/TVEN"
                title="Tipo de venta producto"
              />
              <AsideMenuItem
                to="/catalogos/generico/TPVENS"
                title="Tipo de venta servicio"
              />

              <AsideMenuItem
                to="/catalogos/generico/TPCOMS"
                title="Tipo de compra servicio"
              />

              <AsideMenuItem
                to="/catalogos/generico/UNDMEDS"
                title="Unidad de medida servicio"
              />
              <AsideMenuItem to="/catalogos/descuento" title="Descuentos" />
              <AsideMenuItem to="/catalogos/recargo" title="Recargos" />
              <AsideMenuItem
                to="/catalogos/impuestos"
                title="Tipo de Impuesto"
              />
              <AsideMenuItem
                to="/catalogos/generico/TSERV"
                title="Tipo de servicio"
              />
              <AsideMenuItem
                to="/catalogos/generico/TPROD"
                title="Tipo de producto"
              />
              <AsideMenuItem
                to="/catalogos/generico/TCOT"
                title="Tipo de cotización"
              />
            </>
          )}
        </AsideMenuItemWithSub>
      )}
      {userHasRole(["ADMIN", "DIR"], userRoles) && (
        <AsideMenuItem
          to="/usuarios"
          title="Usuarios"
          icon="/media/icons/duotune/general/gen049.svg"
        />
      )}
    </>
  );
}
