import React, { FC, useState } from 'react';
import { formatDate } from '../../../../_metronic/helpers/FormatDate';
import { useHistory } from 'react-router-dom';
import { encryptId } from '../../../../_metronic/helpers';
import { formattedCurrency } from '../../../../_metronic/helpers/FormatCurrency';

type Props = {
  board: any;
  onRefreshData: () => void;
};

const CardOpportunities: FC<Props> = ({ board, onRefreshData }) => {
  const history = useHistory();
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(board.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, board.length);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          {board.slice(startIndex, endIndex).map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="col-md-6 col-lg-6 col-xxl-4 col-xl-6 mb-4 mt-4"
              >
                <div
                  className="card border borders-card-board h-100 cursor-pointer"
                  onClick={() =>
                    history.push(
                      `/cotizaciones/edit/${encryptId(item.id.toString())}`
                    )
                  }
                >
                  <div className="card-body d-flex flex-center flex-column p-9">
                    <div className="d-flex justify-content-between w-100 mb-5">
                      <div>
                        <a
                          href="#"
                          className="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0"
                        >
                          {item.nombre}
                        </a>
                        <div className="fw-bold text-gray-400 mb-6">
                          {item.client}
                        </div>
                        <div className="fs-5 fw-bolder text-gray-700">
                          {formattedCurrency(parseFloat(item.total))}
                        </div>
                      </div>
                      <div
                        className="symbol symbol-40px"
                        style={{ borderRadius: '8px' }}
                      >
                        <span
                          className={`symbol-label bg-light-primary text-primary" fs-5 fw-bolder`}
                        >
                          {item.vendedorAsignado
                            ? `${item.vendedorAsignado?.nombre.substring(
                                0,
                                1
                              )}${item.vendedorAsignado?.apellido_paterno.substring(
                                0,
                                1
                              )}`
                            : 'NA'}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end w-100">
                      <div className="rounded min-w-125px py-3 mx-3 px-4 mb-3">
                        <div className="fw-bold text-gray-400">
                          {formatDate(new Date(item.updatedAt))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="position-absolute opacity-15 bottom-0 start-0 border-4 border-primary border-bottom w-100"></span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="row justify-content-center">
          <nav aria-label="Navegación de página">
            <ul className="pagination">
              {Array.from({ length: totalPages }, (_, i) => (
                <li
                  key={i}
                  className={`page-item ${
                    currentPage === i + 1 ? 'active' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => onPageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export { CardOpportunities };
