import React, { FC, useRef, useState, useEffect } from "react";
import { encryptId } from "../../../../_metronic/helpers";
import Swal from "sweetalert2";
import Board from "react-trello";
import { useHistory } from "react-router-dom";
import { HomeBoardModel } from "../models/HomeModel";
import { KTSVG } from "../../../../_metronic/helpers";
import { updateOpportunityStage } from "../../../service/oportunidad/Oportunidad";
import { updValueCatalog } from "../../../service/catalog/Catalogs";
import { toast } from "react-toastify";

type Props = {
  board: HomeBoardModel[];
  boardFormatter: any;
  onRefreshData: () => void;
};

const CardOpportunities: FC<Props> = ({
  board,
  boardFormatter,
  onRefreshData,
}) => {
  const history = useHistory();
  const itemsRef = useRef<any>([]);
  const databoard = {
    lanes: boardFormatter,
  };
  const [refreshFlag, setRefreshFlag] = useState<number>(Math.random() * 40);

  const nameUpdateStage = async (
    new_nombre: string,
    items: any,
    currentInput: number
  ) => {
    const { etapa_id, codigo } = items;

    let data = {
      id: etapa_id,
      code: codigo,
      name: new_nombre,
    };
    updValueCatalog("", data)
      .then((res) => {
        itemsRef.current[currentInput].value = res.data.doc.data[1][0].name;
        onRefreshData();
      })
      .catch((err) => {
        let resMessageToast: string = "";
        const {
          data: { message, details = null },
        } = err.response;
        resMessageToast = `${message}`;

        toast.error(
          "Hubo un problema al intentar cambiar el nombre de la etapa, intentelo más tarde",
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      });
  };

  const currentItem = (input: any) => {
    if (itemsRef.current) {
      itemsRef.current.map((item: any, index: any) => {
        if (item === itemsRef.current[input]) {
          item.focus();
          item.className = "input-focus";
        } else {
          item.className = "";
        }
      });
    }
  };
  const Card = (props: any) => {
    const {
      onClick,
      estatus,
      cuenta,
      oportunidad,
      nombre_vendedor,
      apellido_paterno_vendedor,
      apellido_materno_endedor,
      photo,
      cantidad,
      porcentaje,
      draggable,
    } = props;

    return (
      <div
        className="card mb-2 borders-card-board cursor-pointer w-0310"
        onClick={onClick}
        title={oportunidad}
        style={{ cursor: !draggable ? "not-allowed" : "" }}
      >
        <div className="card-header d-flex border-0 mt-3 padding-card-board ">
          <div className="wh-0100">
            <div className="sp-bt-c wh-0100">
              <span className="fw-bolder text-gray-700 large-text">
                {oportunidad}
              </span>
              <div className="symbol symbol-40px">
                {photo ? (
                  <img
                    src={`${process.env.REACT_APP_PUBLIC}users/${photo}`}
                    title={`${nombre_vendedor} ${apellido_paterno_vendedor} ${apellido_materno_endedor}`}
                    crossOrigin="anonymous"
                    className="symbol-label fs-5 "
                    alt="user"
                  />
                ) : (
                  <span
                    className="symbol-label fs-5 fw-bold bg-light-primary text-primary fw-bold"
                    title={`${nombre_vendedor} ${apellido_paterno_vendedor} ${apellido_materno_endedor}`}
                  >{`${nombre_vendedor[0]}${apellido_paterno_vendedor[0]}`}</span>
                )}
              </div>
            </div>
          </div>
          <div>
            <div>
              <span className="text-gray-700 mt-1 fw-bold fs-7">{cuenta}</span>
            </div>
            <div className="text-gray-600 mt-1 fw-semibold fs-7 mb-2">
              {cantidad}
            </div>
          </div>
        </div>
        <div className="card-body padding-card-board">
          <div className="sp-bt-c mb-5">
            <div className=" bg-light rounded w-100 height-0010px">
              <div
                className="badge-primary rounded text-center height-0010px fs-010"
                role="progressbar"
                style={{ width: `${porcentaje}%` }}
              >{`${porcentaje}%`}</div>
            </div>
            <div className="margin-left-020">
              <span className="badge badge-light-success">{estatus}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const LaneHeader = (props: any) => {
    const { color_etapa, title, index, codigo, etapa_id } = props;

    return (
      <>
        <div
          className="card borders"
          style={{ borderTop: `solid 5px ${color_etapa}` }}
        >
          <div
            className="sp-bt-c padding-card-board "
            style={{ minHeight: "60px" }}
          >
            <div className="d-flex">
              <input
                className="large-text fw-bolder text-gray-700"
                ref={(el) => (itemsRef.current[index] = el)}
                onClick={() => {
                  currentItem(index);
                }}
                style={{
                  border: "none",
                  backgroundColor: "#fff",
                  outline: "none",
                }}
                defaultValue={title}
                onKeyDown={async (event: any) => {
                  if (
                    event.target.value.length >= 20 &&
                    event.key !== "Backspace" &&
                    event.key !== "Delete"
                  ) {
                    event.preventDefault();
                  }

                  if (event.key === "Enter") {
                    itemsRef.current[index].value = event.target.value;
                    await nameUpdateStage(event.target.value, props, index);
                  }
                }}
                title={""}
                onBlur={async (event: any) => {
                  await nameUpdateStage(event.target.value, props, index);
                  itemsRef.current[index].className = "";
                }}
              />
            </div>
            <div className="text-muted" title="Total de oportunidades">
              {props.cards.length}
            </div>
            {codigo !== "CGA" && codigo !== "CPE" ? (
              <a
                // href="#/"
                className="btn btn-sm btn-icon  btn-active-light-primary"
                title="Agregar nueva oportunidad"
                onClick={() => {
                  history.push(`/oportunidades_add/${etapa_id}`);
                }}
              >
                <KTSVG
                  path="/media/icons/duotune/abstract/abs011.svg"
                  className="svg-icon-6"
                />
              </a>
            ) : null}
          </div>
        </div>
      </>
    );
  };

  const opportunitiesUpdateByStage = async (
    opportunityId: number,
    codeStage: string
  ) => {
    const values = {
      oportunidadId: opportunityId,
      etapaCode: codeStage,
    };
    updateOpportunityStage(values)
      .then((res) => {
        const {
          data: { message },
        } = res;
        onRefreshData();
        toast.success(message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        let resMessageToast: string = "";
        const {
          data: { message, details = null },
        } = err.response;
        resMessageToast = `${message}`;
        onRefreshData();
        toast.error(
          "Hubo un problema al intentar cambiar de etapa la oportunidad, intentelo más tarde",
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      });
  };

  const components = {
    Card: Card,
    LaneHeader: LaneHeader,
  };

  return (
    <>
      {
        <Board
          key={refreshFlag}
          data={databoard}
          onCardClick={(event: any) => {
            history.push(
              `/oportunidades_edit/${encryptId(
                event.toString()
                // secretKey
              )}`
            );
          }}
          handleDragEnd={async (
            cardId: any,
            sourceLaneId: any,
            targetLaneId: any,
            position: any,
            cardDetails: any
          ) => {
            if (targetLaneId !== sourceLaneId) {
              if (targetLaneId === "CGA") {
                const { cantidad } = cardDetails;
                Swal.fire({
                  title: "¿Desea cerrar la venta?",
                  html: `
                  <div className="text-gray-600">
                    Se va a cerrar la venta con el siguiente monto <b>${cantidad}</b>, una vez
                    cerrada la venta ya no se podrá modificar ningún dato de esta
                    oportunidad.
                  </div>
                  `,
                  showCancelButton: true,
                  padding: "2em",
                  cancelButtonText: "Cancelar",
                  confirmButtonText: "Aceptar",
                  reverseButtons: true,
                  customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-light me-3",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    opportunitiesUpdateByStage(cardId, targetLaneId);
                  } else {
                    setRefreshFlag(Math.random() * 40);
                  }
                });
              } else if (targetLaneId === "COTI") {
                const { id } = cardDetails;
                Swal.fire({
                  title: "¿Deseas crear una cotización?",
                  showCancelButton: true,
                  padding: "2em",
                  cancelButtonText: "Cancelar",
                  confirmButtonText: "Aceptar",
                  reverseButtons: true,
                  customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-light me-3",
                  },
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    history.push(
                      `/cotizaciones/add/${encryptId(id.toString())}`
                    );
                  } else {
                    await opportunitiesUpdateByStage(cardId, targetLaneId);
                  }
                });
              } else {
                await opportunitiesUpdateByStage(cardId, targetLaneId);
              }
            }
          }}
          editable
          components={components}
        />
      }
    </>
  );
};

export { CardOpportunities };
