import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import { GenericPieChart, KTSVG, encryptId } from '../../../_metronic/helpers';
import { useGetAllVendedores, useGetSales } from './hooks/DashboardHook';
import { EmptyResult } from '../../../_metronic/helpers/components/EmptyResult';
import { Filter } from './components/Filter';
import StackedBarChart from '../../../_metronic/helpers/components/GenericStackedChart';
import BarChart from '../../../_metronic/helpers/components/GenericBarChart';
import {
  useGetTypeOfOpportunities,
  useGetStagesOfOpportunities,
} from './hooks/DashboardHook';
import { useGetAllCuentas } from '../contacto/hooks/ContactoHook';
import { TableList } from '../../../_metronic/helpers/components/TableList';
import { formattedCurrency } from '../../../_metronic/helpers/FormatCurrency';

const DashboardPage: FC = () => {
  const [seriesData, setSeriesData] = useState<any[]>([]);
  const [categoriesData, setCategoriesData] = useState<any[]>([]);
  const [percentageData, setPercentageData] = useState<any[]>([]);
  // Grafica distribución por agente
  const [agentesData, setAgentesData] = useState<any[]>([]);
  const [percentageAgenteData, setPercentageAgenteData] = useState<any[]>([]);
  // Grafica Líneas de negocio
  const [linesData, setLinesData] = useState<any[]>([]);
  const [percentageLinesData, setPercentageLinesData] = useState<any[]>([]);
  // Grafica de Ventas por agente
  const [agentesSalesData, setAgentesSalesData] = useState<any[]>([]);
  const [agentesMontoSalesData, setAgentesMontoSalesData] = useState<any[]>([]);
  // Grafica comparativa anio actual
  const [aniosComparative, setAniosComparative] = useState<any[]>([]);
  const [countComparative, setCountComparative] = useState<any[]>([]);
  const [totalComparative, setTotalComarative] = useState<any[]>([]);
  // Año
  const [yearSelected, setYearSelected] = useState<string>('');
  const [etapaName, setEtapaName] = useState<string>('');
  // GRID
  const [gridData, setGridData] = useState<any[]>([]);

  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues, setInitFilterValues] = useState({
    tipo_oportunidad: null,
    label_oportunidad: '',
    cuenta: null,
    label_cuenta: '',
    agente: null,
    label_agente: '',
    anio: null,
    estatus: null,
    label_estatus: '',
    filter: 0,
  });
  const { dataSellers } = useGetAllVendedores(reloadGrid);
  const { types } = useGetTypeOfOpportunities();
  const { stages } = useGetStagesOfOpportunities();
  const { dataChart, loadingRequest } = useGetSales(
    initFilterValues,
    reloadGrid
  );
  const [montoTotalVentas, setMontoTotalVentas] = useState(0);
  const { dataCuenta } = useGetAllCuentas(reloadGrid);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (loadingRequest > 0) {
      setIsLoading(false);
    }
  }, [loadingRequest]);

  useEffect(() => {
    if (dataChart) {
      const {
        venta: { series, categories, porcentajeCambio, total },
        distByAgent: { agentes, porcentajes },
        salesByAgent: { montoVentas, agentes: _agen },
        dataMonth: { anios, montos, total_ventas },
        filterYear,
        etapa,
        businessLine: { lineasNegocio, porcentajes: perc_line },
        dataGrid,
      } = dataChart;
      // Grafica Ventas por Año
      setSeriesData(series);
      setCategoriesData(categories);
      setPercentageData(porcentajeCambio);
      // Total de todas las ventas por año
      setMontoTotalVentas(total);
      // Grafica distribución por agente
      setAgentesData(agentes);
      setPercentageAgenteData(porcentajes);

      // Grafica comparativa anio actual
      setAniosComparative(anios);
      setCountComparative(montos);
      setTotalComarative(total_ventas);

      // Grafica de Ventas por agente
      setAgentesSalesData(_agen);
      let montosData = [];
      montosData.push(montoVentas);
      setAgentesMontoSalesData(montosData);
      //año selecionado
      setYearSelected(filterYear.toString());
      // Etapa seleccionada
      setEtapaName(etapa.toString());
      // Grafica Líneas de negocio
      setLinesData(lineasNegocio);
      setPercentageLinesData(perc_line);
      // GRID
      setGridData(dataGrid);
    }
  }, [dataChart]);

  const onSearchFilter = (values: any) => {
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({
        tipo_oportunidad: null,
        label_oportunidad: '',
        cuenta: null,
        label_cuenta: '',
        agente: null,
        label_agente: '',
        anio: null,
        estatus: null,
        label_estatus: '',
        filter: 0,
      });
    }
    setIsLoading(true);
    // setReloadGrid(Math.random() * 40);
  };

  const onCleanFilter = () => {
    setInitFilterValues({
      tipo_oportunidad: null,
      label_oportunidad: '',
      cuenta: null,
      label_cuenta: '',
      agente: null,
      label_agente: '',
      anio: null,
      estatus: null,
      label_estatus: '',
      filter: 0,
    });
    setIsLoading(true);
    // setReloadGrid(Math.random() * 40);
  };

  const columns: any = [
    {
      id: 'folio',
      name: <div className="fw-bolder text-muted me-5">Folio</div>,
      selector: (row: any) => row.id,
      cell: (row: any) => (
        <Link to={`/oportunidades_edit/${encryptId(row.id.toString())}`}>
          {row.id}
        </Link>
      ),
      sortable: true,
      width: '100px',
    },
    {
      id: 'oportunidad',
      name: <div className="fw-bolder text-muted me-5">Oportunidad</div>,
      selector: (row: any) => row.nombre,
      cell: (row: any) => (
        <Link to={`/oportunidades_edit/${encryptId(row.id.toString())}`}>
          {row.nombre}
        </Link>
      ),
      sortable: true,
      width: '250px',
    },
    {
      id: 'cuenta',
      name: <div className="fw-bolder text-muted me-5">Cuenta</div>,
      selector: (row: any) => row.cuenta.nombre,
      sortable: true,
      width: '250px',
    },
    {
      id: 'sub_cuenta',
      name: <div className="fw-bolder text-muted me-5">Subcuenta</div>,
      selector: (row: any) => (row.subCuenta ? row.subCuenta.nombre : 'N/A'),
      sortable: true,
      width: '250px',
    },
    {
      id: 'tipo',
      name: <div className="fw-bolder text-muted me-5">Tipo</div>,
      selector: (row: any) => row.tipoOportunidad?.name,
      sortable: true,
      width: '350px',
    },
    {
      id: 'etapa',
      name: <div className="fw-bolder text-muted me-5">Etapa</div>,
      selector: (row: any) => row.etapa.name,
      sortable: true,
      width: '250px',
    },
    {
      id: 'estatus',
      name: <div className="fw-bolder text-muted me-5">Estatus</div>,
      selector: (row: any) => row.status.name,
      sortable: true,
      width: '200px',
    },
    {
      id: 'vendedor',
      name: <div className="fw-bolder text-muted me-5">Vendedor</div>,
      selector: (row: any) =>
        `${row.vendedorComercial.nombre} ${row.vendedorComercial.apellido_paterno} ${row.vendedorComercial.apellido_materno}`,
      sortable: true,
      width: '250px',
    },
    {
      id: 'anio',
      name: <div className="fw-bolder text-muted me-5">Año</div>,
      selector: (row: any) => row.anio,
      sortable: true,
      width: '100px',
    },
  ];

  return (
    <>
      <h1 className="fw-semibold text-gray-800 text-center lh-lg">
        {
          isLoading ? (
            <span className="indicator-progress text-primary" style={{ display: 'block' }}>
              <div className="text-center mt-3">
                <span className="spinner-border spinner-border-lg align-middle ms-2"></span>
              </div>
            </span>
          ) : (
            <>
              {etapaName} del año
              <span className="text-primary"> {yearSelected}</span>
            </>
          )
        }



      </h1>
      <div className="row">
        <div className="col-10">
          <div className="d-flex align-items-center">
            {initFilterValues.filter === 1 && (
              <>
                {initFilterValues.tipo_oportunidad != null && (
                  <a
                    href="#/"
                    className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4"
                  >
                    <span
                      onClick={() => {
                        setIsLoading(true);
                        setInitFilterValues({
                          ...initFilterValues,
                          tipo_oportunidad: null,
                        });
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen040.svg"
                        className="svg-icon-3"
                      />
                    </span>

                    {initFilterValues.label_oportunidad}
                  </a>
                )}
                {initFilterValues.cuenta !== null && (
                  <a
                    href="#/"
                    className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4"
                  >
                    <span
                      onClick={() => {
                        setIsLoading(true);
                        setInitFilterValues({
                          ...initFilterValues,
                          cuenta: null,
                        });
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen040.svg"
                        className="svg-icon-3"
                      />
                    </span>

                    {initFilterValues.label_cuenta}
                  </a>
                )}
                {initFilterValues.agente !== null && (
                  <a
                    href="#/"
                    className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4"
                  >
                    <span
                      onClick={() => {
                        setIsLoading(true);
                        setInitFilterValues({
                          ...initFilterValues,
                          agente: null,
                        });
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen040.svg"
                        className="svg-icon-3"
                      />
                    </span>

                    {initFilterValues.label_agente}
                  </a>
                )}
                {initFilterValues.anio !== null && (
                  <a
                    href="#/"
                    className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4"
                  >
                    <span
                      onClick={() => {
                        setIsLoading(true);
                        setInitFilterValues({
                          ...initFilterValues,
                          anio: null,
                        });
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen040.svg"
                        className="svg-icon-3"
                      />
                    </span>

                    {initFilterValues.anio}
                  </a>
                )}
                {initFilterValues.estatus !== null && (
                  <a
                    href="#/"
                    className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4"
                  >
                    <span
                      onClick={() => {
                        setIsLoading(true);
                        setInitFilterValues({
                          ...initFilterValues,
                          estatus: null,
                        });
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen040.svg"
                        className="svg-icon-3"
                      />
                    </span>

                    {initFilterValues.label_estatus}
                  </a>
                )}
              </>
            )}
          </div>
        </div>
        <div className="col-2">
          <Filter
            initFilterValues={initFilterValues}
            onCleanFilter={onCleanFilter}
            onSearchFilter={onSearchFilter}
            data={dataSellers}
            filterTypes={types}
            filterStages={stages}
            flterCuenta={dataCuenta}
          />
        </div>
      </div>

      <div className={`row g-5 g-xl-10 mb-5 mb-xl-10`}>
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-9">
          {!isLoading ? (
            <StackedBarChart
              categories={categoriesData}
              series={seriesData}
              porcentajes={percentageData}
              title={`${formattedCurrency(montoTotalVentas)}`}
              description=""
            />
          ) : (
            <EmptyResult title="" loading={isLoading} />
          )}
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
          {!isLoading ? (
            <GenericPieChart
              type="donut"
              className="mb-0 "
              title={'Líneas de negocio'}
              description={''}
              series={percentageLinesData}
              labels={linesData}
              height={250}
              legend={false}
            />
          ) : (
            <EmptyResult title="" loading={isLoading} />
          )}
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
          {!isLoading ? (
            <GenericPieChart
              type="donut"
              className="mb-0 "
              title={'Distribución por agente'}
              description={''}
              series={percentageAgenteData}
              labels={agentesData}
              height={250}
              legend={true}
            />
          ) : (
            <EmptyResult title="" loading={isLoading} />
          )}
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
          {!isLoading ? (
            <BarChart
              categories={aniosComparative}
              series={countComparative}
              title="Comparativo del año actual con el año anterior"
              description=""
              position={true}
              montos={totalComparative}
              showSubtitle={true}
              isPrice={true}
            />
          ) : (
            <EmptyResult title="" loading={isLoading} />
          )}
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
          {!isLoading ? (
            <BarChart
              categories={agentesSalesData}
              series={agentesMontoSalesData}
              title="Ventas por agente"
              description=""
              position={true}
              montos={[]}
              showSubtitle={false}
              isPrice={true}
            />
          ) : (
            <EmptyResult title="" loading={isLoading} />
          )}
        </div>
      </div>

      <div className={`row gx-5 gx-xl-10`}>
        <div className="col-xl-12">
          <div className={`card mb-0 mb-xxl-8 h-100`}>
            {!isLoading ? (
              <TableList data={gridData} columns={columns}></TableList>
            ) : (
              <EmptyResult title="" loading={isLoading} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const DashboardSalesWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Ventas</PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardSalesWrapper };
