import { FC } from "react";
import { PageTitle, PageLink } from "../../../_metronic/layout/core";
import { View } from "./components/View";
import { useParams } from "react-router-dom";
import { decryptId } from "../../../_metronic/helpers";

const NoteServicePage: FC = () => {
  const { id_service, id } = useParams<{ id_service: string; id: string }>();

  const noteId = decryptId(id);
  const breadCrumbs: Array<PageLink> = [
    {
      title: "Servicios",
      path: "/cotizaciones/servicios/",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: "Editar",
      path: `/cotizaciones/servicios/edit/${id_service}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: "Notas",
      path: "#/",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {noteId ? "Editar nota" : "Nueva nota"}
      </PageTitle>

      <View id={noteId ? noteId : "0"} />
    </>
  );
};

export { NoteServicePage };
