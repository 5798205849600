import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  addCategoryProducts,
  addProductType,
  addSupplierToProduct,
  addUpdateProduct,
  copyProduct,
  createHistoryMessageProduct,
  getCategoriesGrid,
  getDocumentsByProductId,
  getHistoryProducts,
  getNotesByProductId,
  getProductById,
  getSubCategoriesGrid,
  getSupplierByProduct,
  getSuppliersList,
  getTaxes,
  insertNoteProduct,
  updateSupplierToProduct,
} from "../../../service/products/Products";
import {
  GenricCatalogsProductModel,
  ProductModel,
} from "../models/ProductModel";
import { SimpleAlert } from "../../../../_metronic/helpers";
import { HistoryModel } from "../../../../_metronic/helpers/models/HistoryModel";
import { NoteProductModel } from "../models/NoteProductModel";
import { ProductDocModel } from "../models/ProductDocModel";
import { SelectFormat } from "../../../../_metronic/helpers/models/GenericModel";
import { SupplierProductModel } from "../models/SupplierProductModel";
import { getChangeType } from "../../../service/catalog/Catalogs";

type Option = {
  id: number | 0;
  value: number | 0;
  label: string | null | "";
};

export const useGetCatalogOfProductCategories = (
  reloadCategoryList: number
) => {
  const [loadingCatProducts, setLoadingCatProducts] = useState<boolean>(true);
  const [selectData, setSelectData] = useState<Option[]>([]);
  const history = useHistory();

  useEffect(() => {
    const fetchGet = async () => {
      const response = await getCategoriesGrid()
        .then()
        .catch((error: any) => {
          history.push("/error/500");
        });
      if (response) {
        setLoadingCatProducts(false);
        const selectOptions = response.rows.map(
          (option: GenricCatalogsProductModel) => ({
            id: Number(option.id),
            value: option.id,
            label: `${option.nombre} `,
          })
        );

        selectOptions.unshift({
          id: 0,
          value: "0",
          label: `Agregar categoría`,
          data: {},
        });
        setSelectData(selectOptions);
      }
    };

    fetchGet();
  }, [reloadCategoryList]);

  return {
    loadingCatProducts,
    selectData,
  };
};

export const useGetCatalogOfSubCategories = (
  categoryId: number,
  reloadSubcategoryList: number
) => {
  const [loadingSubCatProducts, setLoadingSubCatProducts] =
    useState<boolean>(true);
  const [selectData, setSelectData] = useState<Option[]>([]);
  const history = useHistory();

  useEffect(() => {
    const fetchGet = async () => {
      const response = await getSubCategoriesGrid(categoryId)
        .then()
        .catch((error: any) => {
          history.push("/error/500");
        });
      if (response) {
        setLoadingSubCatProducts(false);
        const selectOptions = response.rows.map(
          (option: GenricCatalogsProductModel) => ({
            value: option.id,
            label: `${option.nombre} `,
          })
        );

        selectOptions.unshift({
          id: 0,
          value: "0",
          label: `Agregar subcategoría`,
          data: {},
        });
        setSelectData(selectOptions);
      }
    };

    fetchGet();
  }, [categoryId, reloadSubcategoryList]);

  return {
    loadingSubCatProducts,
    selectData,
  };
};

export const useGetCatTaxes = () => {
  const [loadingGetTaxes, setLoadingGetTaxes] = useState<boolean>(true);
  const [optionsSelectTaxes, setOptionsSelectTaxes] = useState<any[]>([]);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getTaxes()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        let formatLabel: string;
        const selectOptions = res.map(
          (option: any) => (
            (formatLabel = `${option.siglas} ${option.porcentaje * 100}%`),
            {
              value: option.id,
              label: formatLabel,
              data: option,
            }
          )
        );
        setOptionsSelectTaxes(selectOptions);
        setLoadingGetTaxes(false);
      }
    };
    fetchPost();
  }, []);

  return { loadingGetTaxes, optionsSelectTaxes };
};

export const useCreateEditProduct = (isEdit: boolean) => {
  const [loadingProduct, setLoadingProduct] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);
  const [productId, setProductId] = useState<number>(0);

  const history = useHistory();
  const createEditProduct = async (product: any) => {
    setLoadingProduct(false);
    setErrorRequest(false);
    try {
      const res = await addUpdateProduct(product);

      if (res) {
        if (!isEdit) {
          const {
            doc: {
              data: { id },
            },
          } = res;

          setProductId(Number(id));
        }

        setLoadingProduct(true);
        setErrorRequest(false);
        SimpleAlert(res.message, 3000, "success");
      }
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, "error");
        setLoadingProduct(true);
      } else {
        setErrorRequest(true);
        history.push("/error/500");
      }
    }
  };
  return { createEditProduct, loadingProduct, errorRequest, productId };
};

export const useGetOne = (id: number) => {
  const initModel: ProductModel = {
    id: 0,
    nombre: "",
    descripcion: "",
    sku: "",
    imagen: "",
    precio_venta: "",
    inventario: 0,
    precio_fijo_venta: 0,
    categoria_id: 0,
    aplica_impuesto_precio_venta: false,
    monto_impuesto_precio_venta: null,
    porcentaje_beneficio_precio_venta: null,
    monto_beneficio_precio_venta: null,
    precio_objetivo_calculado_venta: null,
    precio_compra: null,
    porcentaje_conversion_compra: null,
    tipo_cambio_compra: null,
    precio_fijo_compra: null,
    precio_proveedor_compra: null,
    porcentaje_beneficio_precio_compra: null,
    monto_beneficio_precio_compra: null,
    tipo_precio_compra_id: null,
    unidadNegocio: null,
    categoria: {
      id: 0,
      nombre: "",
    },
    subcategoria: null,
    sub_categoria_id: 0,
    tipo: null,
    impuesto: null,
    tipoVenta: null,
    unidadMedidaVenta: {
      id: 0,
      code: "",
      name: "",
    },
    unidadMonedaVenta: {
      id: 0,
      code: "",
      name: "",
    },
    tipoPrecioVenta: {
      id: 0,
      code: "",
      name: "",
    },
    tipoCompra: null,
    unidadMedidaCompra: null,
    unidadMonedaCompra: null,
    tipoPrecioCompra: null,
    tipo_compra_id: null,
    tipo_id: 0,
    impuesto_id: 0,
    documentos: [],
    proveedor_id: null,
  };

  const [initValues, setInitValues] = useState<any>(initModel);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      await getProductById(id)
        .then((res) => {
          if (res) {
            setInitValues(res);
            setLoadingRequest(false);
          }
        })
        .catch((error) => {
          history.push("/error/500");
        });
    };
    if (id > 0) {
      fetchPost();
    } else {
      setInitValues(initModel);
      setLoadingRequest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { initValues, loadingRequest };
};

export const useGetHistory = (reaload: number, id: string) => {
  const [data, setData] = useState<HistoryModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getHistoryProducts(id)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setData(res);
        setLoadingRequest(1);
      }
    };
    if (reaload && Number(id) > 0) {
      fetchPost();
    }
  }, [reaload]);

  return { data, loadingRequest };
};

export const useInsertMessageProduct = () => {
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const history = useHistory();
  const insertData = async (message: string, id: number) => {
    setLoadingInsert(false);
    try {
      await createHistoryMessageProduct(message, id);
      setLoadingInsert(true);
    } catch (error) {
      history.push("/error/500");
      setLoadingInsert(false);
    }
  };
  return { insertData, loadingInsert };
};

export const useGetNoteByProductId = (id: number, reaload: number) => {
  const [notes, setNotes] = useState<NoteProductModel[]>([]);
  const [loadingNote, setLoadingNote] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingNote(false);
    const fetchPost = async () => {
      try {
        const res = await getNotesByProductId(id);
        if (res) {
          setNotes(res);
          setLoadingNote(true);
        }
      } catch (error) {
        history.push("/error/500");
        setLoadingNote(false);
      }
    };
    if (id) {
      fetchPost();
    }
  }, [id, reaload]);

  return { notes, loadingNote };
};

export const useGetDocumentsByProductId = (id: number, reload: number) => {
  const [dataDocuments, setDataDocuments] = useState<ProductDocModel[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingDocuments(false);
      const res = await getDocumentsByProductId(id)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataDocuments(res);
        setLoadingDocuments(true);
      }
    };
    if (id > 0) {
      fetchPost();
    }
  }, [reload]);

  return { dataDocuments, loadingDocuments };
};

export const useCreateNoteProduct = () => {
  const [loadingCreateNote, setLoadingCreateNote] = useState<boolean>(false);
  const history = useHistory();
  const createNote = async (note: NoteProductModel, id: number) => {
    setLoadingCreateNote(false);
    try {
      await insertNoteProduct(note, id);
      setLoadingCreateNote(true);
    } catch (error) {
      history.push("/error/500");
      setLoadingCreateNote(false);
    }
  };
  return { createNote, loadingCreateNote };
};

export const useCreateProductCopy = () => {
  const [loadingProduct, setLaodingProduct] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const createProdcutCopy = async (id: any) => {
    setLaodingProduct(false);
    setErrorRequest(false);
    try {
      const res = await copyProduct(id);
      setLaodingProduct(true);
      setErrorRequest(false);
      SimpleAlert(res.message, 3000, "success");
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, "error");
        setLaodingProduct(true);
      } else {
        setErrorRequest(true);
        history.push("/error/500");
      }
    }
  };
  return { createProdcutCopy, loadingProduct, errorRequest };
};

export const useCreateCategoryProducts = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const create = async (category: any) => {
    setLoading(false);
    setErrorRequest(false);
    try {
      const response = await addCategoryProducts(category);
      setLoading(true);
      setErrorRequest(false);
      SimpleAlert(response.message, 3000, response.status);
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, "error");
        setLoading(true);
      } else {
        setErrorRequest(true);
        history.push("/error/500");
      }
    }
  };
  return { create, loading, errorRequest };
};

export const useCreateProductType = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const create = async (name: any) => {
    setLoading(false);
    setErrorRequest(false);
    try {
      const response = await addProductType(name);
      setLoading(true);
      setErrorRequest(false);
      SimpleAlert(response.message, 3000, response.status);
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, "error");
        setLoading(true);
      } else {
        setErrorRequest(true);
        history.push("/error/500");
      }
    }
  };
  return { create, loading, errorRequest };
};

export const useSelectSupplier = (reload?: number) => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getSuppliersList()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };

    fetchPost();
  }, [reload]);

  return { catalog, loadingRequest };
};

export const useGetSuppliersByProduct = (
  id: number,
  reaload: number,
  execute: boolean
) => {
  const [suppliers, setSuppliers] = useState<SupplierProductModel[]>([]);
  const [loadingSupplier, setLoadingSupplier] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingSupplier(false);
    const fetchPost = async () => {
      try {
        const res = await getSupplierByProduct(id);
        if (res) {
          setSuppliers(res);
          setLoadingSupplier(true);
        }
      } catch (error) {
        history.push("/error/500");
        setLoadingSupplier(false);
      }
    };
    if (id && !execute) {
      fetchPost();
    }
  }, [id, reaload]);

  return { suppliers, loadingSupplier };
};

export const useCreateEditSupplier = () => {
  const [loadingSupp, setLoadingSupp] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const createSupplier = async (supp: any) => {
    setLoadingSupp(true);
    setErrorRequest(false);
    try {
      const res = await addSupplierToProduct(supp);

      if (res) {
        const {
          data: { message },
        } = res;
        setLoadingSupp(false);
        SimpleAlert(message, 3000, "success");
      }
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, "error");
        setLoadingSupp(true);
      } else {
        setErrorRequest(true);
        history.push("/error/500");
      }
    }
  };
  return { createSupplier, loadingSupp, errorRequest };
};

export const useChangeTypeCurrency = (body: any) => {
  const [change, setChange] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoading(false);
      const res = await getChangeType(body)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setChange(res);
        setLoading(true);
      }
    };
    if (body) {
      fetchPost();
    }
  }, [body]);

  return { change, loading };
};

export const useUpdateSupplier = () => {
  const [loadingSupp, setLoadingSupp] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);
  const [reloadInfo, setReloadInfo] = useState<number>(0);

  const history = useHistory();
  const updateSupplier = async (body: any) => {
    setLoadingSupp(true);
    try {
      const res = await updateSupplierToProduct(body);
      if (res) {
        // const { data: { message } } = res;
        setLoadingSupp(false);
        setReloadInfo(Math.random() * 50);
        // SimpleAlert(message, 3000, "success");
      }
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, "error");
        setLoadingSupp(true);
      } else {
        setErrorRequest(true);
        history.push("/error/500");
      }
    }
  };
  return { updateSupplier, loadingSupp, errorRequest, reloadInfo };
};
