import axios from "axios";
import { SelectFormat } from "../../../_metronic/helpers/models/GenericModel";
const API_URL = process.env.REACT_APP_API;

export async function getCatalog(code: string) {
  let orderEtp: string = "";
  if (code == "ETP") orderEtp = "?sort=order";

  const response = await axios.get(`${API_URL}catalogs/${code}${orderEtp}`);
  const data = response.data.doc.data;
  return data;
}
export async function getCatalogCode(code: string) {
  let orderEtp: string = "";
  if (code) orderEtp = "?sort=order";

  const response = await axios.get(`${API_URL}catalogs/${code}${orderEtp}`);
  const data = response.data.doc.data.rows;
  return data;
}
export async function getCatalogGrid(
  code: string,
  startIndex: number,
  endIndex: number,
  filters: any
) {
  let search: string = "";
  let orderEtp: string = "";

  if (code == "ETP") orderEtp = "&sort=order";

  if (filters) {
    search = `&nameOpLike=%${filters}%`;
  }
  const response = await axios.get(
    `${API_URL}catalogs/${code}?limit=${startIndex}&page=${endIndex}${search}${orderEtp}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function updValueCatalog(code_catalogo: string, body: any) {
  const { id, code, name, order } = body;
  let data = {};
  let response;

  if (code_catalogo === "ETP") {
    data = {
      code: code,
      name: name,
      order: order,
      color: body.color,
    };
    response = await axios.put(`${API_URL}catalogs/upd/etapa/${id}`, data);
  } else {
    data = {
      code: code,
      name: name,
      order: order,
    };
    response = await axios.put(`${API_URL}catalogs/${id}`, data);
  }
  return response;
}
export async function addValueCatalog(code_catalogo: string, body: any) {
  const { code, name, order } = body;
  let data = {};
  let response;
  if (code_catalogo === "ETP") {
    data = {
      code: code,
      name: name,
      order: order,
      color: body.color,
    };
    response = await axios.post(`${API_URL}catalogs/add/etapa`, data);
  } else {
    data = {
      code: code,
      name: name,
      order: order,
    };
    response = await axios.post(`${API_URL}catalogs/${code_catalogo}`, data);
  }
  return response;
}
export async function removeRegisterCatalog(id: number, code_catalogo: string) {
  let response;
  if (code_catalogo == "ETP") {
    response = await axios.delete(`${API_URL}catalogs/remove/etapa/${id}`);
  } else {
    response = await axios.delete(`${API_URL}catalogs/${id}`);
  }

  return response;
}
export async function getCatalogDisabledGrid(
  code: string,
  startIndex: number,
  endIndex: number,
  filters: any
) {
  let search: string = "";
  if (filters) {
    search = `&nameOpLike=%${filters}%`;
  }
  const response = await axios.get(
    `${API_URL}catalogs/reactivate/${code}?limit=${startIndex}&page=${endIndex}${search}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function activeCatalog(id: number) {
  const response = await axios.post(`${API_URL}catalogs/reactivate/${id}`);
  return response;
}

export async function getDataCatalogById(
  id_row: number,
  code_catalogo: string
) {
  const response = await axios.get(
    `${API_URL}catalogs/${code_catalogo}?id=${id_row}`
  );
  const { id, name, code, order, etapaColor } = response.data.doc.data?.rows[0];
  let color = etapaColor ? etapaColor.color : "";

  return {
    id: id,
    name: name,
    code: code,
    order: order,
    color: color,
  };
}
export async function getCatalogSelect(code: string) {
  const response = await axios.get(`${API_URL}catalogs/${code}`);
  const data = response.data.doc.data.rows;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.name}`,
      data: item,
    };
    select.push(selectCustom);
  });

  if (code === "TPROD") {
    select.unshift({
      id: 0,
      value: "0",
      label: `Agregar tipo de producto`,
      data: {},
    });
  }
  if (code === "TSERV") {
    select.unshift({
      id: 0,
      value: "0",
      label: `Agregar tipo de servicio`,
      data: {},
    });
  }
  if (code === "UNEG") {
    select.unshift({
      id: 0,
      value: "0",
      label: `Agregar unidad de negocio`,
      data: {},
    });
  }

  return select;
}
export async function getCatalogCurrencyPackages(code: string) {
  const response = await axios.get(`${API_URL}catalogs/${code}`);
  const data = response.data.doc.data.rows;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.code} - ${item.name}`,
      data: item,
    };
    select.push(selectCustom);
  });
  return select;
}

export async function getCatFields() {
  const response = await axios.get(`${API_URL}catalogs/campos/TBL_OPO`);
  const data = response.data.doc.data;
  return data;
}

export async function getCatFieldsProd() {
  const response = await axios.get(`${API_URL}catalogs/campos/TBL_PROD`);

  const data = response.data.doc.data;
  const codesToFilter: string[] = [
    "PROD_NOM",
    "PROD_UNEG",
    "PROD_TIPO",
    "PROD_PV",
  ];
  const dataFilter = data.filter((obj: any) =>
    codesToFilter.includes(obj.code)
  );
  return dataFilter;
}

export async function getChangeType(body: any) {
  const { USD, MXN } = body;
  const response = await axios.get(
    `${API_URL}catalogs/currency/converter/${"USD"}/${"MXN"}`
  );
  const data = response.data.doc.data;
  return data;
}
