import axios from "axios";
import { SelectFormat } from "../../../_metronic/helpers/models/GenericModel";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { NotePackageModel } from "../../pages/notes_opp/models/NotePackageModel";

const API_URL = process.env.REACT_APP_API;
const API_URL_IMAGE_PUBLIC = `${process.env.REACT_APP_PUBLIC}paquete/`;

interface Producto {
  code_unidad_moneda: string;
  id: number;
  id_unidad_medida: number;
  precio_impuesto_real: number;
  name_unidad_medida: string;
  nombre: string;
  precio_unitario_real: number;
  sku: string;
  volumen: number;
  categoria: string;
}

interface Servicio {
  code_unidad_moneda: string;
  id: number;
  id_unidad_medida: number;
  precio_impuesto_real: number;
  name_unidad_medida: string;
  nombre: string;
  precio_unitario_real: number;
  sku: string;
  volumen: number;
  ren: number;
  cap: number;
  categoria: string;
}

export interface PaqueteData {
  id: number;
  nombre: string;
  sku: string;
  descripcion: string;
  package_photo: string;
  precio_unitario: number;
  categoria_id: number;
  sub_categoria_id: number;
  tipo_paquete_id: number;
  unidad_moneda_id: number;
  clasif_paquete_id: number;
  unidad_negocio_id: number;
  products: Producto[];
  services: Servicio[];
  existUpdatesProducts: boolean;
  existUpdatesServices: boolean;
}

export async function getPackageById(
  packageId: number
): Promise<PaqueteData | undefined> {
  try {
    const random = Math.random() * 40;
    const response = await axios.get(
      `${API_URL}cotizacion/paquete/${packageId}`
    );
    const data = response.data.doc.data;

    const {
      id,
      nombre,
      sku,
      descripcion,
      package_photo,
      precio_unitario,
      categoria,
      subCategoria,
      paquete,
      moneda,
      unidad_moneda_id,
      clasificacion,
      unidadNegocio,
      productos,
      servicios,
      existUpdatesProducts,
      existUpdatesServices,
    } = data;

    const mapProducto = (item: any): Producto => ({
      code_unidad_moneda: item.unidadMonedaVenta.code,
      id: item.producto_id,
      id_unidad_medida: item.unidadMedidaVenta?.id || 0,
      precio_impuesto_real: item.monto_impuesto_precio_venta || 0,
      name_unidad_medida: item.unidadMedidaVenta?.name || "N/A",
      nombre: item.producto_name,
      precio_unitario_real: item.precio_venta || 0,
      sku: item.sku || "N/A",
      volumen: item.volumen,
      categoria: item.categoriaProducto ? item.categoriaProducto.nombre : "N/A",
    });

    const mapServicio = (item: any): Servicio => ({
      code_unidad_moneda: item.unidadMonedaVenta.code,
      id: item.servicio_id,
      id_unidad_medida: item.unidadMedidaVenta?.id || 0,
      precio_impuesto_real: item.monto_impuesto_precio_venta || 0,
      name_unidad_medida: item.unidadMedidaVenta?.name || "N/A",
      nombre: item.servicio_name,
      precio_unitario_real: item.precio_venta || 0,
      sku: item.sku || "N/A",
      volumen: item.volumen,
      ren: item.ren,
      cap: item.cap,
      categoria: item.categoriaServicio ? item.categoriaServicio.nombre : "N/A",
    });

    const productosMapeados = productos.map(mapProducto);
    const serviciosMapeados = servicios.map(mapServicio);
    return {
      id,
      nombre,
      sku,
      descripcion,
      package_photo: package_photo
        ? `${API_URL_IMAGE_PUBLIC}${package_photo}?${random}`
        : toAbsoluteUrl("/media/avatars/photo.png"),
      precio_unitario,
      categoria_id: categoria?.id || 0,
      sub_categoria_id: subCategoria?.id || 0,
      tipo_paquete_id: paquete?.id || 0,
      unidad_moneda_id: unidad_moneda_id,
      clasif_paquete_id: clasificacion?.id || 0,
      unidad_negocio_id: unidadNegocio?.id || 0,
      products: productosMapeados,
      services: serviciosMapeados,
      existUpdatesProducts,
      existUpdatesServices,
    };
  } catch (error) {
    console.error("ERROR:", error);
    return undefined;
  }
}

// export async function getPackageById(packageId: number) {
//   try {
//     const random = Math.random() * 40;
//     const response = await axios.get(
//       `${API_URL}cotizacion/paquete/${packageId}`
//     );
//     let data = response.data.doc.data;
//     const {
//       id,
//       nombre,
//       sku,
//       descripcion,
//       package_photo,
//       precio_unitario,
//       categoria,
//       subCategoria,
//       paquete,
//       moneda,
//       clasificacion,
//       unidadNegocio,
//       productos,
//       servicios,
//       existUpdatesProducts,
//       existUpdatesServices
//     } = data;
//     let products = productos.map((item: any) => {
//       let code_unidad_moneda = item.unidadMonedaVenta.code;
//       let id = item.producto_id;
//       let id_unidad_medida = item.unidadMedidaVenta
//         ? item.unidadMedidaVenta.id
//         : 0;
//       let precio_impuesto_real = item.monto_impuesto_precio_venta;
//       let name_unidad_medida = item.unidadMedidaVenta
//         ? item.unidadMedidaVenta.name
//         : 'N/A';
//       let nombre = item.producto_name;
//       let precio_unitario_real = item.precio_venta;
//       let sku = item.sku ? item.sku : 'N/A';
//       let volumen = item.volumen;

//       return {
//         code_unidad_moneda,
//         id,
//         id_unidad_medida,
//         precio_impuesto_real,
//         name_unidad_medida,
//         nombre,
//         precio_unitario_real,
//         sku,
//         volumen,
//       };
//     });
//     let services = servicios.map((item: any) => {
//       let code_unidad_moneda = item.unidadMonedaVenta.code;
//       let id = item.servicio_id;
//       let id_unidad_medida = item.unidadMedidaVenta
//         ? item.unidadMedidaVenta.id
//         : 0;
//       let precio_impuesto_real = item.monto_impuesto_precio_venta;
//       let name_unidad_medida = item.unidadMedidaVenta
//         ? item.unidadMedidaVenta.name
//         : 'N/A';
//       let nombre = item.servicio_name;
//       let precio_unitario_real = item.precio_venta;
//       let sku = item.sku ? item.sku : 'N/A';
//       let volumen = item.volumen;
//       let ren = item.ren;
//       let cap = item.cap;
//       return {
//         code_unidad_moneda,
//         id,
//         id_unidad_medida,
//         precio_impuesto_real,
//         name_unidad_medida,
//         nombre,
//         precio_unitario_real,
//         sku,
//         volumen,
//         ren,
//         cap,
//       };
//     });
//     let packageData = {
//       id: id,
//       nombre: nombre,
//       sku: sku,
//       descripcion: descripcion,
//       package_photo: package_photo
//         ? `${API_URL_IMAGE_PUBLIC}${package_photo}?${random}`
//         : toAbsoluteUrl('/media/avatars/photo.png'),
//       precio_unitario: precio_unitario,
//       categoria_id: categoria ? categoria.id : 0,
//       sub_categoria_id: subCategoria ? subCategoria.id : 0,
//       tipo_paquete_id: paquete ? paquete.id : 0,
//       unidad_moneda_id: moneda ? moneda.id : 0,
//       clasif_paquete_id: clasificacion ? clasificacion.id : 0,
//       unidad_negocio_id: unidadNegocio ? unidadNegocio.id : 0,
//       products: products,
//       services: services,
//       existUpdatesProducts: existUpdatesProducts,
//       existUpdatesServices: existUpdatesServices
//     };
//     return packageData;
//   } catch (error) {
//     console.log('ERROR: ', error);
//   }
// }

export async function getCatalogCategories() {
  const response = await axios.get(
    `${API_URL}cotizacion/catalogo/categoria/paquete`
  );
  const data = response.data.doc.data.rows;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  select.unshift({
    id: 0,
    value: "0",
    label: `Agregar categoría`,
    data: {},
  });

  return select;
}
export async function getCatalogSubcategories(categoryId: number) {
  const response = await axios.get(
    `${API_URL}cotizacion/catalogo/subcategoria/paquete/categoria/${categoryId}`
  );
  const data = response.data.doc.data.rows;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  select.unshift({
    id: 0,
    value: "0",
    label: `Agregar subcategoría`,
    data: {},
  });
  return select;
}

export async function getCatalogProducts() {
  const response = await axios.post(`${API_URL}productos`);
  const data = response.data.doc.data;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  return select;
}

export const addUpdatePackages = async (packageQua: any) => {
  try {
    const {
      id,
      nombre,
      descripcion,
      categoria_id,
      sub_categoria_id,
      tipo_paquete_id,
      unidad_moneda_id,
      clasif_paquete_id,
      unidad_negocio_id,
      products,
      services,
      image,
      update_products,
      update_services,
    } = packageQua;
    const formData = new FormData();
    formData.append("nombre", nombre);
    formData.append("descripcion", descripcion);
    formData.append("update_products", update_products);
    formData.append("update_services", update_services);
    if (categoria_id > 0) {
      formData.append("categoria_id", categoria_id);
    }
    if (sub_categoria_id > 0) {
      formData.append("sub_categoria_id", sub_categoria_id);
    }
    if (tipo_paquete_id > 0) {
      formData.append("tipo_paquete_id", tipo_paquete_id);
    }
    if (unidad_moneda_id > 0) {
      formData.append("unidad_moneda_id", unidad_moneda_id);
    }
    if (clasif_paquete_id > 0) {
      formData.append("clasif_paquete_id", clasif_paquete_id);
    }
    if (unidad_negocio_id > 0) {
      formData.append("unidad_negocio_id", unidad_negocio_id);
    }
    if (products.length) {
      formData.append("productos", JSON.stringify(products));
    }
    if (services.length) {
      formData.append("servicios", JSON.stringify(services));
    }
    if (image) {
      formData.append("package_photo", image);
    }
    let response;
    if (id) {
      response = await axios.patch(
        `${API_URL}cotizacion/paquete/${id}`,
        formData
      );
    } else {
      response = await axios.post(`${API_URL}cotizacion/paquete/add`, formData);
    }
    return response.data;

    // const response = await axios.post(
    //   `${API_URL}cotizacion/paquete/add`,
    //   formData
    // );
    // return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar paquete:", error);
    throw error;
  }
};

export const addCategoryPackages = async (category: any) => {
  try {
    const { nombre } = category;

    const response = await axios.post(
      `${API_URL}cotizacion/catalogo/categoria/paquete`,
      {
        nombre,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar paquete:", error);
    throw error;
  }
};

export const addSubcategoryPackages = async (
  subcategory: any,
  categoryId: number
) => {
  try {
    const { nombre } = subcategory;

    const response = await axios.post(
      `${API_URL}cotizacion/catalogo/subcategoria/paquete`,
      {
        nombre,
        categoria_id: categoryId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar paquete:", error);
    throw error;
  }
};

export async function getCatalogServices() {
  const response = await axios.post(`${API_URL}servicios`);
  const data = response.data.doc.data;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  return select;
}

export async function getCatalogPackages() {
  const response = await axios.post(`${API_URL}cotizacion/paquete`);
  const data = response.data.doc.data.rows;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  return select;
}
// CATALOGO CATEGORIAS
export async function getCategoriesGrid() {
  const response = await axios.get(
    `${API_URL}cotizacion/catalogo/categoria/paquete`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getDataCategoryById(id_row: number) {
  const response = await axios.get(
    `${API_URL}cotizacion/catalogo/categoria/paquete/${id_row}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function addValueCategory(body: any) {
  const response = await axios.post(
    `${API_URL}cotizacion/catalogo/categoria/paquete`,
    {
      nombre: body.nombre,
      orden: body.orden ? body.orden : null,
    }
  );
  return response;
}

export async function updValueCategory(body: any) {
  const data = {
    nombre: body.nombre,
    orden: body.orden,
  };

  const response = await axios.patch(
    `${API_URL}cotizacion/catalogo/categoria/paquete/${body.id}`,
    data
  );
  return response;
}

export async function removeRegisterCategory(id: number) {
  const response = await axios.delete(
    `${API_URL}cotizacion/catalogo/categoria/paquete/${id}`
  );

  return response;
}

// CATALOGO SUBCATEGORIAS

export async function getSubCategoriesGrid() {
  const response = await axios.get(
    `${API_URL}cotizacion/catalogo/subcategoria/paquete`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getDataSubCategoryById(id_row: number) {
  const response = await axios.get(
    `${API_URL}cotizacion/catalogo/subcategoria/paquete/${id_row}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function addValueSubCategory(body: any) {
  const response = await axios.post(
    `${API_URL}cotizacion/catalogo/subcategoria/paquete`,
    {
      nombre: body.nombre,
      orden: body.orden ? body.orden : null,
      categoria_id: body.categoria_id,
    }
  );
  return response;
}

export async function updValueSubCategory(body: any) {
  const data = {
    nombre: body.nombre,
    orden: body.orden,
    categoria_id: body.categoria_id,
  };

  const response = await axios.patch(
    `${API_URL}cotizacion/catalogo/subcategoria/paquete/${body.id}`,
    data
  );
  return response;
}

export async function removeRegisterSubCategory(id: number) {
  const response = await axios.delete(
    `${API_URL}cotizacion/catalogo/subcategoria/paquete/${id}`
  );

  return response;
}

export async function getHistoryPackages(search: string, id: string) {
  const response = await axios.get(
    `${API_URL}cotizacion/paquete/historial/${id}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function createHistoryMessagePackages(
  message: string,
  id: number
) {
  const response = await axios.post(
    `${API_URL}cotizacion/paquete/historial/comentario`,
    {
      packageId: id,
      comentario: message,
    }
  );
  return response;
}

export async function getNotesByPackageId(id: number) {
  const response = await axios.get(
    `${API_URL}cotizacion/paquete/notas/byPaquete/${id}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function insertNotePackage(note: NotePackageModel, id: number) {
  const response = await axios.post(`${API_URL}cotizacion/paquete/notas`, {
    nombre: note.nombre,
    descripcion: note.descripcion,
    paquete_id: Number(id),
  });
  return response;
}

export const uploadFileToPackage = async (file: any, id: string) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("packageId", id);

  const response = await axios.post(
    `${API_URL}cotizacion/paquete/documentos`,
    formData
  );
  return response;
};
export const removeFileOfPackage = async (id: number) => {
  const response = await axios.delete(
    `${API_URL}cotizacion/paquete/documentos/${id}`
  );
  return response;
};
export async function getDocumentsByPackageId(id: number) {
  const response = await axios.get(
    `${API_URL}cotizacion/paquete/documentos/${id}`
  );
  const data = response.data.doc.data;
  return data;
}

export const addCreatePackageCopy = async (id: number) => {
  try {
    const response = await axios.post(
      `${API_URL}cotizacion/paquete/copy/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al copiar paquete:", error);
    throw error;
  }
};

export const deletePackageCurrentService = async (id: number) => {
  try {
    const response = await axios.delete(`${API_URL}cotizacion/paquete/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al eliminar el paquete:", error);
    throw error;
  }
};

export const updateCosts = async (id: number) => {
  try {
    const response = await axios.patch(
      `${API_URL}cotizacion/paquete/updates/prod/serv`,
      {
        id,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al actualizar los conceptos:", error);
    throw error;
  }
};
