

import { useState, useEffect } from 'react';
import { SelectFormat } from '../../../../_metronic/helpers/models/GenericModel';
import { useHistory } from 'react-router-dom';
import { actualizarPrecios, addSubaccountQuo, addUpdateQuotation, createHistoryMessageQuo, eliminarCotizacion, finishQuotation, getCatalogAccount, getCatalogDiscount, getCatalogSubaccount, getCatalogSurcharge, getDocumentsByQuoId, getHistoryQuo, getNotesByQuoId, getQuotationById, getQuotationPDFById, getVersionsByQuoId, insertNoteQuo } from '../../../service/quotation/quotation';
import { SimpleAlert } from '../../../../_metronic/helpers';
import { NoteQuoModel } from '../../notes_quo/models/NoteModel';
import { QuoDocModel, VersionModel } from '../models/QuotationModel';
import { HistoryModel } from '../../../../_metronic/helpers/models/HistoryModel';

export const useSelectAccount = (reload: number) => {
    const [catalog, setCatalog] = useState<SelectFormat[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(true);
  
    const history = useHistory();
    useEffect(() => {
      const fetchPost = async () => {
        const data = await getCatalogAccount()
          .then()
          .catch((error: any) => {
            history.push('/error/500');
          });
        if (data) {
          setCatalog(data);
          setLoadingRequest(false);
        }
      };
      fetchPost();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);
  
    return { catalog, loadingRequest };
  };

  export const useCreateSubaccountQuo = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [errorRequest, setErrorRequest] = useState<boolean>(false);
  
    const history = useHistory();
    const create = async (subaccount: any, accountId: number) => {
      setLoading(false);
      setErrorRequest(false);
      try {
        await addSubaccountQuo(subaccount, accountId);
        setLoading(true);
        setErrorRequest(false);
        SimpleAlert('Se creó la subcuenta exitosamente!', 3000, 'success');
      } catch (error: any) {
        setErrorRequest(true);
        if (error && error.response) {
          const { message } = error.response.data;
          SimpleAlert(message, 3000, 'error');
          setLoading(true);
        } else {
          setErrorRequest(true);
          history.push('/error/500');
        }
      }
    };
    return { create, loading, errorRequest };
  };

  export const useSelectSubaccount = (
    accountId: number,
    reload: number
  ) => {
    const [catalog, setCatalog] = useState<SelectFormat[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(true);
  
    const history = useHistory();
    useEffect(() => {
      const fetchPost = async () => {
        const data = await getCatalogSubaccount(accountId)
          .then()
          .catch((error) => {
            history.push('/error/500');
          });
        if (data) {
          setCatalog(data);
          setLoadingRequest(false);
        }
      };
      if (accountId > 0) {
        fetchPost();
      } else {
        setLoadingRequest(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId, reload]);
  
    return { catalog, loadingRequest };
  };

  export const useSelectSurcharge = (reload: number) => {
    const [catalog, setCatalog] = useState<SelectFormat[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(true);
  
    const history = useHistory();
    useEffect(() => {
      const fetchPost = async () => {
        const data = await getCatalogSurcharge()
          .then()
          .catch((error: any) => {
            history.push('/error/500');
          });
        if (data) {
          setCatalog(data);
          setLoadingRequest(false);
        }
      };
      fetchPost();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);
  
    return { catalog, loadingRequest };
  };

  export const useSelectDiscount = (reload: number) => {
    const [catalog, setCatalog] = useState<SelectFormat[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(true);
  
    const history = useHistory();
    useEffect(() => {
      const fetchPost = async () => {
        const data = await getCatalogDiscount()
          .then()
          .catch((error: any) => {
            history.push('/error/500');
          });
        if (data) {
          setCatalog(data);
          setLoadingRequest(false);
        }
      };
      fetchPost();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);
  
    return { catalog, loadingRequest };
  };

  export const useCreateEditQuotation = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [errorRequest, setErrorRequest] = useState<boolean>(false);
  
    const history = useHistory();
    const createEdit = async (packageQua: any) => {
      setLoading(false);
      setErrorRequest(false);
      try {
        const res = await addUpdateQuotation(packageQua);
        setLoading(true);
        setErrorRequest(false);
        SimpleAlert(res.message, 3000, 'success');
      } catch (error: any) {
        setErrorRequest(true);
        if (error && error.response) {
          const { message } = error.response.data;
          SimpleAlert(message, 3000, 'error');
          setLoading(true);
        } else {
          setErrorRequest(true);
          history.push('/error/500');
        }
      }
    };
    return { createEdit, loading, errorRequest };
  };


export const useGetOne = (id: number) => {
  const initModel: any = {
    id: 0,
    nombre: '',
    unidad_negocio_id: 0,
    cuenta_id: 0,
    subcuenta_id: 0,
    contacto_id: 0,
    tipo_id: 0,
    unidad_moneda_id: 0,
    vendedor_asignado_id: 0,
    tipo_ajuste_id: 0,
    tipo_ajuste_code: '',
    recargo_id: 0,
    recargo_value: 0,
    recargo_tipo_code: '',
    descuento_id: 0,
    descuento_value: 0,
    descuento_tipo_code: '',
    version: "",
    codigo_estatus: "",
    cotizacion_grupo: null,
    cotizacion_origen: null,
    cargo_version: false,
    costo_indirecto_id: 0,
    costo_indirecto_code: '',
    costo_indirecto_value: 0,
    utilidad_id: 0,
    utilidad_code: '',
    utilidad_value: 0,
    secciones: [],
  };

  const [initValues, setInitValues] = useState<any>(initModel);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      const res = await getQuotationById(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });

      if (res) {
        setInitValues(res);
        setLoadingRequest(false);
      }
    };
    if (id > 0) {
      fetchPost();
    } else {
      setInitValues(initModel);
      setLoadingRequest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { initValues, loadingRequest };
};



export const useGetOnePDF = (id: number) => {
  const initModel: any = {
    id: 0,
    nombre: '',
    unidad_negocio_id: 0,
    cuenta_id: 0,
    subcuenta_id: 0,
    contacto_id: 0,
    tipo_id: 0,
    unidad_moneda_id: 0,
    vendedor_asignado_id: 0,
    tipo_ajuste_id: 0,
    recargo_id: 0,
    recargo_value: 0,
    recargo_tipo_code: '',
    descuento_id: 0,
    descuento_value: 0,
    descuento_tipo_code: '',
    empresa_nombre: '',
    // costo_indirecto_id: 0,
    // costo_indirecto_code: '',
    // costo_indirecto_value: 0,
    // utilidad_id: 0,
    // utilidad_code: '',
    // utilidad_value: 0,
    concepts: [],
  };

  const [initValues, setInitValues] = useState<any>(initModel);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      const res = await getQuotationPDFById(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });

      if (res) {
        setInitValues(res);
        setLoadingRequest(false);
      }
    };
    if (id > 0) {
      fetchPost();
    } else {
      setInitValues(initModel);
      setLoadingRequest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { initValues, loadingRequest };
};

export const useGetNoteByQuoId = (id: number, reaload: number) => {
  const [notes, setNotes] = useState<NoteQuoModel[]>([]);
  const [loadingNote, setLoadingNote] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingNote(false);
    const fetchPost = async () => {
      try {
        const res = await getNotesByQuoId(id);
        if (res) {
          setNotes(res);
          setLoadingNote(true);
        }
      } catch (error) {
        history.push('/error/500');
        setLoadingNote(false);
      }
    };
    if (id) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reaload]);

  return { notes, loadingNote };
};

export const useCreateNoteQuo = () => {
  const [loadingCreateNote, setLoadingCreateNote] = useState<boolean>(false);
  const history = useHistory();
  const createNote = async (note: NoteQuoModel, cotizacion_id: number) => {
    setLoadingCreateNote(false);
    try {
      await insertNoteQuo(note, cotizacion_id);
      setLoadingCreateNote(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingCreateNote(false);
    }
  };
  return { createNote, loadingCreateNote };
};

export const useGetDocumentsByQuoId = (
  cotizacionId: number,
  reload: number
) => {
  const [dataDocuments, setDataDocuments] = useState<QuoDocModel[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingDocuments(false);
      const res = await getDocumentsByQuoId(cotizacionId)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataDocuments(res);
        setLoadingDocuments(true);
      }
    };
    if (cotizacionId > 0) {
      fetchPost();
    }
  }, [reload]);

  return { dataDocuments, loadingDocuments };
};

export const useGetVersionsByQuoId = (id: number, reaload: number) => {
  const [versions, setVersions] = useState<VersionModel[]>([]);
  const [loadingVersions, setLoadingVersions] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingVersions(false);
    const fetchPost = async () => {
      try {
        const res = await getVersionsByQuoId(id);
        if (res) {
          setVersions(res);
          setLoadingVersions(true);
        }
      } catch (error) {
        history.push('/error/500');
        setLoadingVersions(false);
      }
    };
    if (id) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reaload]);

  return { versions, loadingVersions };
};


export const useFinishQuotation = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const history = useHistory();
  const finish = async (cotizacion_id: any) => {
    setLoading(true);
    setErrorRequest(false);
    try {
      const res = await finishQuotation(cotizacion_id);
      setErrorRequest(false);
      SimpleAlert(res.message, 3000, 'success');
      setIsSuccess(true);
    } catch (error: any) {
      setErrorRequest(true);
      setLoading(false);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
      } else {
        setErrorRequest(true);
        history.push('/error/500');
      }
    }
  };
  return { finish, loading, errorRequest, isSuccess };
};

export const useEliminarCotizacion = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const history = useHistory();
  const eliminar = async (id: any) => {
    setLoading(false);
    setIsSuccess(false);
    try {
      const res = await eliminarCotizacion(id);
      setLoading(true);
      setIsSuccess(true);
      SimpleAlert(res.message, 3000, 'success');
    } catch (error: any) {
      setIsSuccess(false);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoading(true);
      } else {
        history.push('/error/500');
      }
    }
  };
  return { eliminar, loading, isSuccess };
};

export const useActualizarPrecios = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const history = useHistory();
  const actualizar = async (id: any) => {
    setLoading(false);
    setIsSuccess(false);
    try {
      const res = await actualizarPrecios(id);
      setLoading(true);
      setIsSuccess(true);
      SimpleAlert(res.message, 3000, 'success');
      history.push(`/cotizaciones/cotizaciones`);
    } catch (error: any) {
      setIsSuccess(false);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoading(true);
      } else {
        history.push('/error/500');
      }
    }
  };
  return { actualizar, loading, isSuccess };
};

export const useGetHistory = (reaload: number, search: string, id: string) => {
  const [data, setData] = useState<HistoryModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getHistoryQuo(search, id)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setData(res);
        setLoadingRequest(1);
      }
    };
    if (reaload && Number(id) > 0) {
      fetchPost();
    }
  }, [reaload]);

  return { data, loadingRequest };
};
export const useInsertMessageQuo = () => {
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const history = useHistory();
  const insertData = async (message: string, id: number) => {
    setLoadingInsert(false);
    try {
      await createHistoryMessageQuo(message, id);
      setLoadingInsert(true);
    } catch (error) {
      history.push("/error/500");
      setLoadingInsert(false);
    }
  };
  return { insertData, loadingInsert };
};