import React, { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { styleSelect } from "../../../../../_metronic/helpers/SelectStyles";
import Select from "react-select";
import CountUp from "react-countup";
import { FormikProps } from "formik";
import {
  useSelectComponentFormat,
  useSelectGeneric,
  useSelectGenericPlus,
} from "../../../catalogos/generico/hooks/genericoHook";
import { ServiceModel } from "../../models/ServiceModel";
import { useChangeTypeCurrency } from "../../hooks/ServiceHook";
import { formatTotalSingle } from "../../../../../_metronic/helpers";

type Props = {
  currentUnitMeasurePurchaseProduct: (measure: any) => void;
  currentUnitCurrencyPurchaseProduct: (currency: any) => void;
  codeMeasureSaleProduct: string;
  codeCurrencySaleProduct: string;
  updatePricePurchaseProduct: (
    total: number,
    priceWithoutTaxes: number
  ) => void;
  getCalculatePrice: (price: any) => void;
  taxValue: number;
  profitPorcentaje: number;
  isSupplierType: (isSupp: boolean) => void;
  disableOption: boolean;
  dataSupplier: any;
  priceSale: number;
  isCheck: boolean;
  IsCheckedSupplierSecction: (isChecked: boolean) => void;
  initVal: any;
  formik: FormikProps<ServiceModel>;
  reloadGridSupplier: number;
};

const PurchaseDataSection: React.FC<Props> = ({
  currentUnitMeasurePurchaseProduct,
  currentUnitCurrencyPurchaseProduct,
  codeMeasureSaleProduct,
  codeCurrencySaleProduct,
  taxValue,
  profitPorcentaje,
  getCalculatePrice,
  updatePricePurchaseProduct,
  isSupplierType,
  disableOption,
  dataSupplier,
  priceSale,
  isCheck,
  IsCheckedSupplierSecction,
  initVal,
  formik,
  reloadGridSupplier,
}) => {
  const { catalog: PurchaseType, loadingRequest: loadingPT } =
    useSelectGenericPlus("TPCOMS");
  const [currencySelected, setCurrencySelected] = useState({});
  const { change } = useChangeTypeCurrency(currencySelected);
  const { catalog: PricePurchase } = useSelectGeneric("TPCOM");

  const { optionsSelect: UnitMeasure } = useSelectComponentFormat(
    "UNDMEDS",
    true
  );
  const { optionsSelect: UnitCurrency } = useSelectComponentFormat(
    "UMON",
    true
  );
  const [selectedOptionUnitMeasure, setSelectedOptionUnitMeasure] = useState<
    any | null
  >(null);
  const [selectedOptionPurchaseType, setSelectedOptionPurchaseType] = useState<
    any | null
  >(null);
  const [selectedOptionCurrency, setSelectedOptionCurrency] = useState<
    any | null
  >(null);
  const priceTypeOption = {
    FIJO: 1,
    PROVEEDOR: 2,
  };
  const [priceType, setPriceType] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const [fixedSalesPrice, setFixedSalesPrice] = useState<number>(0);
  const [supplierPrice, setSupplierPrice] = useState<number>(0);
  const [profit, setProfit] = useState<number>(0);
  const [codeMeasure, setCodeMeasure] = useState<string>("");
  const [codeCurrency, setCodeCurrency] = useState<string>("");
  const [convertion, setConvertion] = useState<number>(0);
  const [isDisabledConvertion, setIsDisabledConvertion] =
    useState<boolean>(false);
  const [changeType, setChangeType] = useState<number>(0);
  const [priceObj, setPriceObj] = useState<number>(0);
  const [typePriceValue, setTypePriceValue] = useState<number>(0);
  const [totalPricePurchase, setTotalPricePurchase] = useState<number>(0);

  const changePurchaseType = (selectOption: any) => {
    if (selectOption) {
      const { value } = selectOption;
      setSelectedOptionPurchaseType(selectOption);
      formik.setFieldValue("tipo_compra_id", value);
    } else {
      setSelectedOptionPurchaseType(selectOption);
      formik.setFieldValue("tipo_compra_id", null);
    }
  };

  const changeUnitMeasure = (selectOption: any) => {
    if (selectOption) {
      const {
        data: { code },
      } = selectOption;
      setCodeMeasure(code);

      setSelectedOptionUnitMeasure(selectOption);
    } else {
      setCodeMeasure("");
      setSelectedOptionUnitMeasure(selectOption);
    }
  };

  const changeCurrency = (selectOption: any) => {
    if (selectOption) {
      const {
        data: { code },
      } = selectOption;

      setCodeCurrency(code);
      setSelectedOptionCurrency(selectOption);
    } else {
      setCodeCurrency("");
      setSelectedOptionCurrency(selectOption);
      setChangeType(0);
    }
  };

  useEffect(() => {
    if (initVal && initVal.id > 0) {
      const {
        tipoCompra,
        unidadMedidaCompra,
        unidadMonedaCompra,
        porcentaje_conversion_compra,
        tipo_cambio_compra,
        precio_fijo_compra,
        tipoPrecioCompra,
        precio_proveedor_compra,
        monto_beneficio_precio_compra,
        porcentaje_beneficio_precio_compra,
      } = initVal;
      setPriceObj(monto_beneficio_precio_compra || 0);
      setProfit(porcentaje_beneficio_precio_compra || 0);

      if (tipoCompra) {
        const typePurchase = PurchaseType.filter(
          (item) => item.value === tipoCompra?.id
        );
        setSelectedOptionPurchaseType(typePurchase[0]);
      } else {
        const defaultaValuePurchaseType = PurchaseType.filter(
          (option: any) => option.data.code == "TPCOMSEDIR"
        );
        if (defaultaValuePurchaseType.length) {
          setSelectedOptionPurchaseType(defaultaValuePurchaseType[0]);

          const { id } = defaultaValuePurchaseType[0];

          formik.setFieldValue("tipo_compra_id", id);
        }
      }

      if (unidadMedidaCompra) {
        const measure = UnitMeasure.filter(
          (item) => item.value === unidadMedidaCompra?.id
        );
        if (measure) {
          setSelectedOptionUnitMeasure(measure[0]);
        }
      }

      if (unidadMonedaCompra) {
        const currency = UnitCurrency.filter(
          (item) => item.value === unidadMonedaCompra?.id
        );

        if (currency) {
          setSelectedOptionCurrency(currency[0]);
        }
      }

      setConvertion(Number(porcentaje_conversion_compra));
      setChangeType(tipo_cambio_compra);

      if (tipoPrecioCompra) {
        const { code, id } = tipoPrecioCompra;
        setTypePriceValue(id);

        if (code === "TPCF") {
          setPriceType(priceTypeOption.FIJO);
          setFixedSalesPrice(Number(precio_fijo_compra));
        } else if (code === "TPCP") {
          setPriceType(priceTypeOption.PROVEEDOR);
          setSupplierPrice(precio_proveedor_compra);
        }
      } else {
        const typePrice = PricePurchase.filter((item) => item.code === "TPCF");
        if (typePrice.length) {
          const { id } = typePrice[0];

          setPriceType(priceTypeOption.FIJO);
          setTypePriceValue(id);
        }
      }
    }
  }, [initVal, UnitMeasure, UnitCurrency, PurchaseType, PricePurchase]);

  useEffect(() => {
    if (PricePurchase && priceType === priceTypeOption.FIJO) {
      const typePrice = PricePurchase.filter((item) => item.code === "TPCF");
      if (typePrice.length) {
        const { id } = typePrice[0];
        setTypePriceValue(id);
      }
    } else if (PricePurchase && priceType === priceTypeOption.PROVEEDOR) {
      const typePrice = PricePurchase.filter((item) => item.code === "TPCP");
      if (typePrice.length) {
        const { id } = typePrice[0];
        setTypePriceValue(id);
      }
    } else {
      setTypePriceValue(0);
    }
  }, [PricePurchase, priceType]);

  useEffect(() => {
    if (codeMeasureSaleProduct && codeMeasureSaleProduct === codeMeasure) {
      setConvertion(100);
      setIsDisabledConvertion(true);
    } else {
      setIsDisabledConvertion(false);
      setConvertion(0);
    }
  }, [codeMeasureSaleProduct, codeMeasure]);

  useEffect(() => {
    if (codeCurrencySaleProduct && codeCurrencySaleProduct === codeCurrency) {
      setChangeType(1);
    } else if (codeCurrency && change) {
      const {
        rates: {
          MXN: { rate },
        },
      } = change;

      setChangeType(rate);
    } else {
      setChangeType(0);
    }
  }, [codeCurrencySaleProduct, codeCurrency, change]);

  useEffect(() => {
    if (taxValue) {
      setTax(Number(taxValue));
    } else {
      setTax(0);
    }
  }, [taxValue]);

  useEffect(() => {
    if (priceType === priceTypeOption.FIJO) {
      const impuesto = fixedSalesPrice * tax;
      const total = fixedSalesPrice + impuesto;
      setTotalPricePurchase(total);
    }
  }, [fixedSalesPrice, tax, priceType]);

  useEffect(() => {
    if (priceType === priceTypeOption.PROVEEDOR) {
      isSupplierType(true);
      setFixedSalesPrice(0);
      let suppTax = supplierPrice * tax;
      let total = supplierPrice + suppTax;
      setTotalPricePurchase(total);
    } else {
      isSupplierType(false);
      IsCheckedSupplierSecction(false);
      setSupplierPrice(0);
    }
  }, [supplierPrice, tax, priceType]);

  useEffect(() => {
    if (priceType === priceTypeOption.FIJO) {
      updatePricePurchaseProduct(totalPricePurchase, fixedSalesPrice);
    } else if (priceType === priceTypeOption.PROVEEDOR) {
      updatePricePurchaseProduct(totalPricePurchase, supplierPrice);
    }
  }, [fixedSalesPrice, supplierPrice, totalPricePurchase, priceType]);

  useEffect(() => {
    if (totalPricePurchase) {
      let ben = profitPorcentaje / 100;
      let benefitFactor = ben + 1;
      let total = totalPricePurchase * benefitFactor;
      getCalculatePrice(total);
    } else {
      getCalculatePrice(0);
    }
  }, [profitPorcentaje, totalPricePurchase]);

  useEffect(() => {
    if (dataSupplier && priceType === priceTypeOption.PROVEEDOR) {
      const { precio_unitario, unidadMedida, unidadMoneda } = dataSupplier;
      setSupplierPrice(precio_unitario ? Number(precio_unitario) : 0);

      if (unidadMedida) {
        const measure = UnitMeasure.filter(
          (option: any) => option.value === unidadMedida?.id
        );
        const { code } = unidadMedida;
        setCodeMeasure(code);

        if (measure.length) {
          setSelectedOptionUnitMeasure(measure[0]);
        }
      } else {
        setCodeMeasure("");
        setSelectedOptionUnitMeasure(null);
      }

      if (unidadMoneda) {
        const currency = UnitCurrency.filter(
          (option: any) => option.value === unidadMoneda?.id
        );
        const { code } = unidadMoneda;
        setCodeCurrency(code);

        if (currency.length) {
          setSelectedOptionCurrency(currency[0]);
        }
      } else {
        setCodeCurrency("");
        setSelectedOptionCurrency(null);
      }
    } else {
      setSupplierPrice(0);
    }
  }, [dataSupplier, priceType, reloadGridSupplier]);

  useEffect(() => {
    if (priceSale && profit) {
      let ben = profit / 100;
      let benefitFactor = 1 - ben;
      formik.setFieldValue("porcentaje_beneficio_precio_compra", profit);
      let total = priceSale * benefitFactor;
      setPriceObj(total);
    } else {
      setPriceObj(0);
      setProfit(0);
      formik.setFieldValue("porcentaje_beneficio_precio_compra", 0);
    }
  }, [profit, priceSale]);

  useEffect(() => {
    if (isCheck) {
      setPriceType(priceTypeOption.PROVEEDOR);
    }
  }, [isCheck]);

  useEffect(() => {
    formik.setFieldValue("tipo_cambio_compra", changeType);
  }, [changeType]);

  useEffect(() => {
    formik.setFieldValue("porcentaje_conversion_compra", convertion);
  }, [convertion]);

  useEffect(() => {
    formik.setFieldValue("tipo_precio_compra_id", typePriceValue);
  }, [typePriceValue]);

  useEffect(() => {
    if (selectedOptionCurrency) {
      const {
        data: { code },
      } = selectedOptionCurrency;
      setCodeCurrency(code);
      currentUnitCurrencyPurchaseProduct(selectedOptionCurrency);
    }
  }, [selectedOptionCurrency]);

  useEffect(() => {
    if (selectedOptionUnitMeasure) {
      const {
        data: { code },
      } = selectedOptionUnitMeasure;
      setCodeMeasure(code);
      currentUnitMeasurePurchaseProduct(selectedOptionUnitMeasure);
    }
  }, [selectedOptionUnitMeasure]);

  return (
    <>
      <div className="row row-scroll">
        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto">Tipo de compra</label>
            </div>
            <Select
              options={PurchaseType}
              styles={styleSelect}
              placeholder={"Seleccione"}
              isSearchable={true}
              noOptionsMessage={() => {
                return <span>{"No se encontraron registros"}</span>;
              }}
              isLoading={loadingPT}
              onChange={changePurchaseType}
              value={selectedOptionPurchaseType}
              backspaceRemovesValue
              isClearable
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto">Conversión</label>
            </div>

            <NumericFormat
              disabled={isDisabledConvertion}
              className="form-control "
              thousandSeparator={false}
              allowNegative={false}
              suffix="%"
              allowLeadingZeros={false}
              isAllowed={({ value }) => {
                // Verificar que el valor esté entre 0 y 100
                if (parseInt(value) < 0 || parseInt(value) > 100) {
                  return false;
                }
                // Verificar que el valor tenga entre 1 y 3 dígitos enteros
                const integerValue = parseInt(value);
                if (integerValue < 10 && value.length > 1) {
                  return false;
                }
                if (integerValue < 100 && value.length > 2) {
                  return false;
                }
                return true;
              }}
              value={convertion}
              onValueChange={(values) => {
                const { floatValue } = values;
                setConvertion(floatValue ? floatValue : 0);
              }}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto large-text">
                Unidad de medida
              </label>
            </div>
            <Select
              options={UnitMeasure}
              styles={styleSelect}
              placeholder={"Seleccione"}
              isSearchable={true}
              noOptionsMessage={() => {
                return <span>{"No se encontraron registros"}</span>;
              }}
              onChange={changeUnitMeasure}
              value={selectedOptionUnitMeasure}
              backspaceRemovesValue
              isClearable
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 mb-5">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto large-text">
                Unidad moneda
              </label>
            </div>
            <Select
              options={UnitCurrency}
              styles={styleSelect}
              placeholder={"Seleccione"}
              isSearchable={true}
              noOptionsMessage={() => {
                return <span>{"No se encontraron registros"}</span>;
              }}
              onChange={changeCurrency}
              value={selectedOptionCurrency}
              backspaceRemovesValue
              isClearable
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-2 col-xl-2 col-xxl-2 mb-5">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto large-text">
                Tipo de cambio
              </label>
            </div>

            <NumericFormat
              className="form-control "
              thousandSeparator={false}
              allowNegative={false}
              allowLeadingZeros={false}
              value={changeType}
              onValueChange={(values) => {
                const { floatValue } = values;
                setChangeType(floatValue ? floatValue : 0);
              }}
            />
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto fw-bolder">
                Precio de compra
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-5 row-scroll">
        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          <div className="w-100 pe-5 mb-5">
            <div className="w-100 d-flex">
              <label className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input  mr-10"
                  type="radio"
                  checked={priceType === priceTypeOption.FIJO}
                  onClick={() => {
                    setPriceType(priceTypeOption.FIJO);
                  }}
                />
                Precio fijo
              </label>
            </div>
          </div>
          <div className="w-100 pe-5">
            <label className="form-label w-auto">Precio compra fijo</label>
            <div className="w-100 d-flex">
              <NumericFormat
                disabled={priceType != priceTypeOption.FIJO}
                className="form-control fs-4"
                thousandSeparator={true}
                prefix="$"
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                allowLeadingZeros={false}
                value={fixedSalesPrice}
                isAllowed={(values: any) => {
                  const { floatValue } = values;
                  const integerPart = Math.floor(floatValue);
                  return integerPart.toString().length <= 9;
                }}
                onValueChange={(values: any) => {
                  const { floatValue } = values;
                  setFixedSalesPrice(floatValue);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-4 col-xl-3 col-xxl-3">
          <div className="w-100 pe-5 mb-5">
            <div className="w-100 d-flex">
              <label className="form-check form-check-custom form-check-solid large-text">
                <input
                  disabled={disableOption}
                  title={` ${
                    disableOption
                      ? "Es necesario tener al menos un proveedor para poder seleccionar esta opción"
                      : ""
                  }`}
                  className={`mr-10  " ${
                    disableOption
                      ? "cursor-not-allowed  "
                      : "cursor-pointer form-check-input"
                  } `}
                  type="radio"
                  checked={priceType === priceTypeOption.PROVEEDOR}
                  onClick={() => {
                    setPriceType(priceTypeOption.PROVEEDOR);
                  }}
                />
                Precio proveedor
              </label>
            </div>
          </div>
          <div className="w-100 pe-5">
            <label className="form-label w-auto">Precio proveedor</label>
            <div className="w-100 d-flex">
              <NumericFormat
                disabled
                className="form-control fs-4"
                thousandSeparator={true}
                prefix="$"
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                allowLeadingZeros={false}
                value={supplierPrice}
                isAllowed={(values: any) => {
                  const { floatValue } = values;
                  const integerPart = Math.floor(floatValue);
                  return integerPart.toString().length <= 9;
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
          <div className="w-100 pe-5 mb-5">
            <div className="w-100 d-flex center-items">
              <label
                className="form-check form-check-custom form-check-solid center-items"
                style={{ width: "100%" }}
              >
                Precio objetivo - referencia
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
              <div className="w-100 pe-5">
                <label className="form-label w-auto">Beneficio</label>
                <div className="w-100 d-flex">
                  <NumericFormat
                    className="form-control fs-4"
                    thousandSeparator={false}
                    allowNegative={false}
                    suffix="%"
                    allowLeadingZeros={false}
                    isAllowed={({ value }) => {
                      // Verificar que el valor esté entre 0 y 100
                      if (value === "") return true;
                      const intValue = parseInt(value);
                      return intValue >= 0 && intValue <= 100;
                    }}
                    value={profit}
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      setProfit(floatValue ? floatValue : 0);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="w-100 pe-5">
                <label
                  className="form-label w-auto large-text"
                  style={{ marginBottom: "1px" }}
                >
                  Precio objetivo
                </label>
                <div className="w-100 d-flex">
                  <NumericFormat
                    disabled
                    className="form-control fs-4"
                    thousandSeparator={true}
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    value={priceObj}
                    isAllowed={(values: any) => {
                      const { floatValue } = values;
                      const integerPart = Math.floor(floatValue);
                      return integerPart.toString().length <= 9;
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2 mt-md-5 price-total"
          style={{ alignSelf: "flex-end" }}
        >
          <div className=" me-5 fw-bold w-100">Precio unitario</div>
          <div>
            <span
              className="fs-1 fw-bold"
              style={{ color: "#1B84FF", fontSize: "2px" }}
            >
              <CountUp
                end={formatTotalSingle(totalPricePurchase, 2)}
                duration={1}
                prefix="$"
                decimals={2}
              />{" "}
            </span>
          </div>
          <div>
            <span className="fs-7 fw-semibold opacity-50">
              <span data-kt-element="period">
                {codeCurrency ? `${codeCurrency}/` : ""}
              </span>

              <span data-kt-element="period">
                {codeMeasure ? codeMeasure : ""}
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export { PurchaseDataSection };
