export const colorStatusOpportunity = (code: string) => {
  let _className = "";

  switch (code) {
    case "OPENC":
      _className = "badge badge-light-primary";
      break;
    case "OPRSG":
      _className = "badge badge-light-warning";
      break;
    case "OPCER":
      _className = "badge badge-light";
      break;
    case "OPRR":
      _className = "badge badge-light-danger";
      break;
    default:
      _className = "";
      break;
  }

  return _className;
};

export const colorStatusTask = (code: string) => {
  let _className = "";

  switch (code) {
    case "TENCUR":
      _className = "badge badge-light-primary";
      break;
    case "TRET":
      _className = "badge badge-light";
      break;
    case "TCOMP":
      _className = "badge badge-light-success";
      break;
    case "TPAUSE":
      _className = "badge badge-light-info";
      break;
    case "TCANC":
      _className = "badge badge-light-danger";
      break;
    default:
      _className = "";
      break;
  }

  return _className;
};

export const colorStatusRisk = (code: string) => {
  let _className = "";

  switch (code) {
    case "RENC":
      _className = "badge badge-light-primary";
      break;
    case "RENRE":
      _className = "badge badge-light";
      break;
    case "RENMIT":
      _className = "badge badge-light-success";
      break;
    case "RECANC":
      _className = "badge badge-light-danger";
      break;
    default:
      _className = "";
      break;
  }

  return _className;
};

export const colorStatusMit = (code: string) => {
  let _className = "";

  switch (code) {
    case "STSECM":
      _className = "badge badge-light-primary";
      break;
    case "STSREM":
      _className = "badge badge-light";
      break;
    case "STSCOMM":
      _className = "badge badge-light-success";
      break;
    case "STSCANM":
      _className = "badge badge-light-danger";
      break;
    default:
      _className = "";
      break;
  }

  return _className;
};

export const randomClass = () => {
  let classNames = [
    "text-task-01",
    "text-task-02",
    "text-task-03",
    "text-task-04",
    "text-task-05",
    "text-task-06",
    "text-task-07",
    "text-task-08",
    "text-task-09",
    "text-task-10",
  ];

  const randomIndex = Math.floor(Math.random() * classNames.length);
  return classNames[randomIndex];
};

export const randomClassList = () => {
  //   let classNames = [
  //     "bg-list-01",
  //     "bg-list-02",
  //     "bg-list-03",
  //     "bg-list-04",
  //     "bg-list-05",
  //     "bg-list-06",
  //     "bg-list-07",
  //     "bg-list-08",
  //     "bg-list-09",
  //     "bg-list-10",
  //   ];
  const colorsHex = [
    "#EDCC59",
    "#C87DC7",
    "#F48F38",
    "#9CCF59",
    "#024FAF",
    "#F15A5A",
    "#FFBD59",
    "#02A858",
    "#4876B9",
    "#08B0CD",
  ];

  const randomIndex = Math.floor(Math.random() * colorsHex.length);
  return colorsHex[randomIndex];
};

export const randomClassCat = () => {
  let classNames = [
    "bg-list-01",
    "bg-list-02",
    "bg-list-03",
    "bg-list-04",
    "bg-list-05",
    "bg-list-06",
    "bg-list-07",
    "bg-list-08",
    "bg-list-09",
    "bg-list-10",
  ];

  const randomIndex = Math.floor(Math.random() * classNames.length);
  return classNames[randomIndex];
};
