import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CategoryModel } from "../models/QuotationModel";
import { toast } from "react-toastify";
import {
  getCategoriesPackage,
  insertCategoryPackage,
  getAllPackages,
} from "../../../service/products/Products";

export const useGetCategoryPack = (realoadGrid: number) => {
  const [dataCatPack, setDataCatPack] = useState<CategoryModel[]>([]);
  const [loadingRequestPack, setLoadingRequestPack] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequestPack(0);
      const res = await getCategoriesPackage()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataCatPack(res.data);
        setLoadingRequestPack(1);
      }
    };
    fetchPost();
    // if (realoadGrid) {
    //   fetchPost();
    // }
  }, [realoadGrid]);

  return { dataCatPack, loadingRequestPack };
};

export const useCreateCategoryPackage = () => {
  const [loadingCreateCategoryPack, setLoadingCreateCategoryPack] =
    useState<boolean>(false);
  const [isCorrectInsertCPA, setIsCorrectInsertCPA] = useState<boolean>(false);

  const createCategoryPackage = async (category: CategoryModel) => {
    setLoadingCreateCategoryPack(true);
    try {
      await insertCategoryPackage(category).then(() => {
        setLoadingCreateCategoryPack(false);
        setIsCorrectInsertCPA(true);
      });
    } catch (err: any) {
      setIsCorrectInsertCPA(false);
      let resMessageToast: string = "";
      const {
        data: { message },
      } = err.response;
      resMessageToast = `${message}`;
      toast.error(resMessageToast, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setLoadingCreateCategoryPack(false);
      throw resMessageToast;
    }
  };
  return {
    createCategoryPackage,
    loadingCreateCategoryPack,
    isCorrectInsertCPA,
  };
};

export const useGetAllPack = (
  realoadGrid: number
  // startIndex: number,
  // endIndex: number,
  // filters: any
) => {
  const [dataPackages, setDataPackages] = useState<CategoryModel[]>([]);
  const [loadingRequestPackages, setLoadingRequestPackages] =
    useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequestPackages(true);
      const res = await getAllPackages()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataPackages(res.data);
        setLoadingRequestPackages(false);
      }
    };
    fetchPost();
    // if (realoadGrid) {
    //   fetchPost();
    // }
  }, [realoadGrid]);

  return { dataPackages, loadingRequestPackages };
};
