import React, { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Pagination, encryptId } from "../../../../_metronic/helpers";
import { MenuComponent } from "../../../../_metronic/assets/ts/components/MenuComponent";
import { OportunidadModel } from "../models/QuotationModel";
import { shallowEqual, useSelector } from "react-redux";
import { UserModel } from "../../../modules/auth/models/UserModel";
import { RootState } from "../../../../setup/index";
import { useHistory } from "react-router-dom";
import { formattedCurrency } from "../../../../_metronic/helpers/FormatCurrency";
import { formatDate } from "../../../../_metronic/helpers/FormatDate";

type Props = {
  data: any;
  count: number;
  currentPage: number;
  totalPages: number;
  onRemoveRegister: (row: any) => void;
  onPageChange: (pageNumber: number) => void;
  onEditRegister: (row: any) => void;
  onDetail: (row: any) => void;
  loadingGrid: boolean;
};

const ListGrid: React.FC<Props> = ({
  data,
  count,
  currentPage,
  totalPages,
  onRemoveRegister,

  onPageChange,
  onEditRegister,
  onDetail,
  loadingGrid,
}) => {
  const Oportunidad: Array<OportunidadModel> = data;
  const [columns, setColumns] = useState<Array<any>>([]);
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;

    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };
  const history = useHistory();

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  const columnsCot = [
    { id: "name", label: "Cotización", width: "300px" },
    { id: "client", label: "Cuenta", width: "200px" },
    { id: "client", label: "Unidad de moneda", width: "200px" },
    { id: "mont", label: "Monto", width: "200px" },
    { id: "dateUpdate", label: "Vendedor", width: "200px" },
    { id: "vendor", label: "Actualización", width: "200px" },
  ];

  return (
    <>
      <div className="card-body py-3">
        <div className="table-responsive min-h-600px">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bolder text-muted">
                {columnsCot.map((column) => (
                  <th key={column.id} className={`w-${column.width}`}>
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                data &&
                  data.map((row: any) => (
                    <tr key={row.id}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start flex-column fs-6">
                            <Link
                              to={`/cotizaciones/edit/${encryptId(
                                row.id.toString()
                              )}`}
                            >
                              {row.nombre}
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start flex-column fs-6">
                            {row?.cuenta?.nombre}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start flex-column fs-6">
                            {row?.unidadMoneda.code}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start flex-column fs-6">
                            {formattedCurrency(parseFloat(row.total))}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start flex-column fs-6">
                            {row.vendedorAsignado
                              ? `${row?.vendedorAsignado?.nombre} ${row?.vendedorAsignado?.apellido_paterno}`
                              : "Sin vendedor"}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start flex-column fs-6">
                            {formatDate(new Date(row.updatedAt))}
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex text-center">
                          <div className="d-flex justify-content-start flex-column fs-6">
                            {row.status?.code === "PLN" ? (
                              <span className="badge badge-light-success">
                                {row.status?.name}
                              </span>
                            ) : row.status?.code === "RSG" ? (
                              <span className="badge badge-light-warning text-warning">
                                {row.status?.name}
                              </span>
                            ) : (
                              <span className="badge badge-light-danger text-danger">
                                {row.status?.name}
                              </span>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                // )
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
