import { FC } from 'react';
import { PageTitle, PageLink } from '../../../_metronic/layout/core';
import { View } from './components/View';
// import { ViewAddEdit } from './components/ViewAddEdit';
import { useParams } from 'react-router-dom';
import { decryptId } from '../../../_metronic/helpers';

const NoteQuoPage: FC = () => {
  const { id_cotizacion, id } =
    useParams<{ id_cotizacion: string; id: string }>();

  const noteId = decryptId(id);
  const quoId = decryptId(id_cotizacion);
  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Cotización',
      path: '/cotizaciones/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Editar',
      path: `/cotizaciones/edit/${id_cotizacion}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Notas',
      path: '#/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {noteId ? 'Editar nota' : 'Nueva nota'}
      </PageTitle>

      <View id={noteId ? noteId : '0'} quoId={quoId ? quoId : '0'} />
    </>
  );
};

export { NoteQuoPage };
