import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";
import Swal from "sweetalert2";
import { KTSVG, encryptId } from "../../../../_metronic/helpers";
import { styleSelect } from "../../../../_metronic/helpers/SelectStyles";
import {
  useGetAllCuentas,
  useGetAllSubCuentas,
} from "../../contacto/hooks/ContactoHook";
import {
  useGetOppById,
  useGetAllVendedoresComerciales,
  useGetNoteByOpoId,
  useGetQuotationsByIdOportunity,
} from "../hooks/OportunidadHook";
import { useGetOne } from "../../account/hooks/AccountHook";
import { NumericFormat } from "react-number-format";
import {
  addOportunidad,
  updateOportunidad,
} from "../../../service/oportunidad/Oportunidad";
import { useGetContactosFilter } from "../../contacto/hooks/ContactoHook";
import { dateFormattYYYYMMDD } from "../../../../_metronic/helpers/FormatDate";
import { OpportunityDoc } from "./OpportunityDoc";
import { CreateCuentaModal } from "../_modals/quickAccess/CreateCuentaModal";
import { CreateSubCuentaModal } from "../_modals/quickAccess/CreateSubCuentaModal";
import { CreateContactModal } from "../_modals/quickAccess/CreateContactModal";
import { CreateDptoModal } from "../_modals/quickAccess/CreateDptoModal";
import { CreateUnitModal } from "../_modals/quickAccess/CreateUnitModal";
import {
  useDataFormatForSelectComponent,
  useInsertTaskOportunity,
  useGetTasksByIdOportunity,
  useGetRisksByIdOportunity,
  useInsertRiskOportunity,
  useGetDptos,
  useGetDocumentsByOportunityId,
  useGetBusinessUnit,
} from "../hooks/OportunidadHook";
import { TaskList } from "./TaskList";
import { RiskList } from "./RiskList";
import { colorStatusOpportunity } from "../../../../_metronic/helpers/CustomElements";
import { NoteList } from "./sections/NoteList";
import { CreateNoteOppModal } from "../_modals/CreateNoteModal";
import { CreateNoteOppDetailModal } from "../_modals/CreateNoteDetailModal";
import { QuotationList } from "./sections/QuotationList";

type Props = {
  id_row: number;
  onRefreshData: () => void;
  section: string;
  onDisabledHistory: (disabled: boolean) => void;
  etapaSelected: string;
};

const OporunidadSchema = Yup.object().shape({
  nombre: Yup.string().required("Este campo es requerido."),
  fecha_aprobacion_estimada: Yup.date().required("Este campo es requerido"),
  tipo_oportunidad_id: Yup.number()
    .min(1, "Este campo es requerido.")
    .required("Este campo es requerido."),
  vendedor_comercial_id: Yup.number()
    .min(1, "Este campo es requerido.")
    .required("Este campo es requerido."),
  cuenta_id: Yup.number()
    .min(1, "Este campo es requerido.")
    .required("Este campo es requerido."),
  etapa_id: Yup.number()
    .min(1, "Este campo es requerido.")
    .required("Este campo es requerido."),
  cantidad_estimada: Yup.number()
    .required("Este campo es requerido.")
    .min(1, "Requerido ser mayor a 0."),
  probabilidad: Yup.number()
    .min(1, "Este campo es requerido.")
    .required("Este campo es requerido."),
});

const OpportunityForm: React.FC<Props> = ({
  id_row,
  onRefreshData,
  section,
  onDisabledHistory,
  etapaSelected,
}) => {
  const [formIsEdit, setFormIsEdit] = useState<boolean>(false);

  // SECTION TASKS
  const [reloadGridTasks, setReloadGridTasks] = useState<number>(
    Math.random() * 40
  );
  const [reloadGridRisks, setReloadGridRisks] = useState<number>(
    Math.random() * 40
  );
  const { insertTask, loadingInsertTask } = useInsertTaskOportunity();
  const { insertRisk, loadingInsertRisk } = useInsertRiskOportunity();

  const { tasks, loadingTask } = useGetTasksByIdOportunity(
    id_row.toString(),
    reloadGridTasks
  );
  const { quotations, loadingQuotations } = useGetQuotationsByIdOportunity(
    id_row.toString()
  );
  const { risks, loadingRisk } = useGetRisksByIdOportunity(
    id_row.toString(),
    reloadGridRisks
  );
  const [reloadDocuments, setReloadDocuments] = useState(Math.random() * 40);
  const { dataDocuments, loadingDocuments } = useGetDocumentsByOportunityId(
    id_row,
    reloadDocuments
  );
  // NOTAS
  const [reloadGridNotes, setReloadGridNotes] = useState<number>(
    Math.random() * 40
  );
  const { notes, loadingNote } = useGetNoteByOpoId(
    id_row.toString(),
    reloadGridNotes
  );

  const history = useHistory();
  const [tabs, setTabs] = useState<
    string | "TASK" | "RISKS" | "ACC" | "NOTE" | "COTI"
  >("NOTE");
  const sectionTabs = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [showModalCuenta, setShowModalCuenta] = useState<boolean>(false);
  const [activeNewTask, setActiveNewTask] = useState<boolean>(false);
  const [activeNewRisk, setActiveNewRisk] = useState<boolean>(false);
  const [showModalSubCuenta, setShowModalSubCuenta] = useState<boolean>(false);
  const [showModalContact, setShowModalContact] = useState<boolean>(false);
  const [showModalDpto, setShowModalDpto] = useState<boolean>(false);
  const [showModalUnit, setShowModalUnit] = useState<boolean>(false);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [showNoteDetailModal, setShowNoteDetailModal] =
    useState<boolean>(false);
  const [activeNewNote, setActiveNewNote] = useState<boolean>(false);

  const [reloadInfoSubCuenta, setReloadInfoSubCuenta] = useState<number>(0);
  const [reloadContact, setReloadContact] = useState<number>(
    Math.random() * 40
  );
  const [reloadDpto, setReloadDpto] = useState<number>(Math.random() * 40);
  const [reloadUnit, setReloadUnit] = useState<number>(Math.random() * 40);
  const [cuentaId, setCuentaId] = useState<number>(0);
  const [subcuentaId, setSubcuentaId] = useState<number>(0);
  const { dataSelect: etapa } = useDataFormatForSelectComponent("ETP", id_row);
  const { dataSelect: oportunidad } = useDataFormatForSelectComponent(
    "TOP",
    id_row
  );
  const { dataSelectDpto } = useGetDptos("DEP", reloadDpto);
  const { dataSelectUnit: unidadNegocio } = useGetBusinessUnit(
    "UNEG",
    reloadUnit
  );
  const { dataSelect: moneda } = useDataFormatForSelectComponent(
    "UMON",
    id_row
  );
  const [reloadInfoCuenta, setReloadInfoCuenta] = useState(Math.random() * 40);
  const { cuentaDataSelect } = useGetAllCuentas(reloadInfoCuenta);
  const [reloadInfoCurrentOpportunity, setReloadInfoCurrentOpportunity] =
    useState(Math.random() * 40);
  const { initValues, loadingRequest } = useGetOppById(
    id_row,
    reloadInfoCurrentOpportunity
  );
  let { subCuentaDataSelect } = useGetAllSubCuentas(
    cuentaId,
    reloadInfoSubCuenta
  );
  const { initValues: cuenta } = useGetOne(cuentaId);
  const [stageCodeInitial, setStageCodeInitial] = useState<string>("");
  const { vComerciales, sellerData } = useGetAllVendedoresComerciales();
  const [approvalDate, setApprovalDate] = useState<string>("");
  const [closingDate, setClosingDate] = useState<string>("");
  const [probabilidad, setProbabilidad] = useState<number>(0);
  const [cantidad, setCantidad] = useState<number>(0);
  const [noti, setNoti] = useState(0);
  const [subAccList, setSubAccList] = useState<any[]>([
    {
      value: "new",
      label: "Agregar Subcuenta",
    },
  ]);
  const location_get = 1;
  const { contDataSelect } = useGetContactosFilter(
    reloadContact,
    cuentaId,
    subcuentaId,
    location_get
  );

  //Form
  const [staegId, setStaegId] = useState<any | null>(null);
  const [typeOppId, setTypeOPPId] = useState<any | null>(null);
  const [cueId, setCueId] = useState<any | null>(null);
  const [sbId, setSbId] = useState<any | null>(null);
  const [contId, setContId] = useState<any | null>(null);
  const [vcId, setVCId] = useState<any | null>(null);
  const [dptoId, setDptoId] = useState<any | null>(null);
  const [monedaId, setMonedaId] = useState<any | null>(null);
  const [unidadNegId, setUnidadNegId] = useState<any | null>(null);
  const [showConfirmRedirect, setShowConfirmRedirect] =
    useState<boolean>(false);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const [minApprovaldDate, setMinApprovaldDate] = useState<string>(
    currentDate.toISOString().split("T")[0]
  );
  const [minClosingDate, setMinClosingDate] = useState<string>(
    currentDate.toISOString().split("T")[0]
  );
  const [accName, setAccName] = useState<string | null>("");
  const [subAccName, setSubAccName] = useState<string | null>("");
  const scrollToFinalSection = () => {
    if (sectionTabs.current)
      sectionTabs.current.scrollIntoView({ behavior: "smooth" });
  };

  // SECTION TASKS
  const saveTask = (taskName: string) => {
    insertTask(taskName, id_row.toString());
  };

  // SECTION RISKS
  const saveRisk = (riskName: string) => {
    insertRisk(riskName, id_row.toString());
  };

  // SECTIONS NOTES
  const openModal = (isOpen: boolean) => {
    setShowNoteModal(isOpen);
  };
  const openDetailModal = (isOpen: boolean) => {
    setShowNoteDetailModal(isOpen);
  };

  useEffect(() => {
    if (loadingInsertTask) {
      setReloadGridTasks(Math.random() * 40);
      onRefreshData();
    }
  }, [loadingInsertTask]);

  useEffect(() => {
    setSubAccList(subCuentaDataSelect);
  }, [subCuentaDataSelect]);

  useEffect(() => {
    if (loadingInsertRisk) {
      // window.location.reload();
      setReloadGridRisks(Math.random() * 40);
      setReloadInfoCurrentOpportunity(Math.random() * 40);
      onRefreshData();
    }
  }, [loadingInsertRisk]);

  useEffect(() => {
    if (section) {
      setTabs(section);
      scrollToFinalSection();
      switch (section) {
        case "TASK":
          setActiveNewTask(true);
          break;
        case "RISKS":
          setActiveNewRisk(true);
          break;
        default:
          break;
      }
    }
  }, [section]);

  useEffect(() => {
    if (etapaSelected && etapa) {
      const etp = etapa.find((item: any) => item.value == etapaSelected);
      setStaegId(etp);
      formOportunidad.setFieldValue("etapa_id", etapaSelected);
    }
  }, [etapaSelected, etapa]);

  useEffect(() => {
    if (cuenta.id > 0) {
      const { vendedorComercial } = cuenta;

      if (vComerciales) {
        const findSeller = vComerciales.filter(
          (item: any) => item.id === vendedorComercial?.id
        );

        if (findSeller.length) {
          formOportunidad.setFieldValue(
            "vendedor_comercial_id",
            id_row > 0 ? initValues.vendedor_comercial_id : findSeller[0]?.id
          );

          setVCId({
            value:
              id_row > 0 ? initValues.vendedor_comercial_id : findSeller[0].id,
            label:
              id_row > 0
                ? initValues.vendedorComercial?.nombre +
                  " " +
                  initValues.vendedorComercial?.apellido_paterno +
                  " (" +
                  initValues.vendedorComercial?.email +
                  ")"
                : findSeller[0].alias,
            nombre:
              id_row > 0
                ? initValues.vendedorComercial?.nombre
                : findSeller[0].nombre,
            apellidoP:
              id_row > 0
                ? initValues.vendedorComercial?.apellido_paterno
                : findSeller[0].apellido_paterno,
          });
        } else {
          const objNewSeller = {
            id: vendedorComercial?.id ?? 0,
            alias: `${vendedorComercial?.nombre} ${vendedorComercial?.apellido_paterno} (${vendedorComercial?.email}) Responsable de cuenta`,
            apellido_materno: vendedorComercial?.apellido_paterno ?? "",
            apellido_paterno: "",
            email: "",
            nombre: vendedorComercial?.nombre ?? "",
            telefono: "",
            user_id: 0,
            created_at: new Date(),
            tipoVendedor: {
              id: 0,
              name: "",
              code: "",
            },
            user: {
              id: 0,
            },
          };

          vComerciales.push(objNewSeller);

          const newData = vComerciales.filter(
            (vendedor: any) => vendedor.id === objNewSeller.id
          );
          formOportunidad.setFieldValue(
            "vendedor_comercial_id",
            id_row > 0 ? initValues.vendedor_comercial_id : newData[0]?.id
          );
          setVCId({
            value:
              id_row > 0 ? initValues.vendedor_comercial_id : newData[0].id,
            label:
              id_row > 0
                ? initValues.vendedorComercial?.nombre +
                  " " +
                  initValues.vendedorComercial?.apellido_paterno +
                  " (" +
                  initValues.vendedorComercial?.email +
                  ")"
                : newData[0].alias,
            nombre:
              id_row > 0
                ? initValues.vendedorComercial?.nombre
                : newData[0].nombre,
            apellidoP:
              id_row > 0
                ? initValues.vendedorComercial?.apellido_paterno
                : newData[0].apellido_paterno,
          });
        }
      }
    }
  }, [cuenta]);

  useEffect(() => {
    if (!cuentaId) {
      formOportunidad.setFieldValue("vendedor_comercial_id", 0);
      formOportunidad.setFieldValue("sub_cuenta_id", 0);
      setSubcuentaId(0);
    }
  }, [cuentaId]);

  useEffect(() => {
    if (id_row > 0 && initValues?.id > 0) {
      setApprovalDate(
        initValues.fecha_aprobacion_estimada
          ? dateFormattYYYYMMDD(initValues.fecha_aprobacion_estimada.toString())
          : ""
      );
      setClosingDate(
        initValues.fecha_cierre_real
          ? dateFormattYYYYMMDD(initValues.fecha_cierre_real.toString())
          : ""
      );
      setCantidad(initValues.cantidad_estimada);

      setProbabilidad(initValues.probabilidad ? initValues.probabilidad : 0);

      if (etapa.length && initValues.etapa_id) {
        setStageCodeInitial(initValues.etapa?.code || "");

        formOportunidad.setFieldValue("etapa_id", initValues.etapa_id);
        const filterEtp = etapa.filter(
          (item) => item.value === initValues.etapa_id
        );

        setStaegId(filterEtp[0]);
      }
      // ETAPA
      // setStaegId({
      //   value: initValues.etapa_id,
      //   label: initValues.etapa?.name,
      // });
      // formOportunidad.setFieldValue("etapa_id", initValues.etapa_id);

      // CUENTA
      setCuentaId(initValues.cuenta_id ? initValues.cuenta_id : 0);
      setCueId({
        value: initValues.cuenta_id,
        label: initValues.cuenta?.nombre,
      });
      setAccName(initValues.cuenta?.id ? initValues.cuenta?.nombre : "");
      formOportunidad.setFieldValue("cuenta_id", initValues.cuenta_id);

      // SUBCUENTA
      setSubcuentaId(initValues.sub_cuenta_id ? initValues.sub_cuenta_id : 0);
      formOportunidad.setFieldValue("sub_cuenta_id", initValues.sub_cuenta_id);
      if (initValues.sub_cuenta_id) {
        setSbId({
          value: initValues.sub_cuenta_id,
          label: initValues.subCuenta?.nombre,
        });
        setSubAccName(initValues.subCuenta ? initValues.subCuenta?.nombre : "");
      }

      // DEPARTAMENTO
      formOportunidad.setFieldValue(
        "departamento_id",
        initValues.departamento_id
      );
      if (initValues.departamento_id) {
        setDptoId({
          value: initValues.departamento_id,
          label: initValues.departamento?.name,
        });
      }

      // CONTACTO
      if (initValues.contacto?.id) {
        const nameContact =
          `${initValues.contacto.nombre} ${initValues.contacto.apellido_paterno} ${initValues.contacto.apellido_materno}` ||
          "";
        setContId({
          value: initValues.contacto?.id,
          label: nameContact,
        });
        formOportunidad.setFieldValue(
          "nombre_contacto",
          initValues.contacto?.id
        );
      }

      //   VENDEDOR COMERCIAL
      formOportunidad.setFieldValue(
        "vendedor_comercial_id",
        initValues.vendedor_comercial_id
      );

      // UNIDAD DE NEGOCIO
      if (initValues.unidad_negocio_id) {
        setUnidadNegId({
          value: initValues.unidad_negocio_id,
          label: initValues.unidad?.name,
        });
        formOportunidad.setFieldValue(
          "unidad_negocio_id",
          initValues.unidad_negocio_id
        );
      }

      // TIPO OPORTUNIDAD
      if (initValues.tipoOportunidad?.id) {
        setTypeOPPId({
          value: initValues.tipoOportunidad?.id,
          label: initValues.tipoOportunidad?.name,
        });
        formOportunidad.setFieldValue(
          "tipo_oportunidad_id",
          initValues.tipoOportunidad?.id
        );
      }

      // MONEDA
      if (initValues.moneda?.id) {
        setMonedaId({
          value: initValues.moneda?.id,
          label: initValues.moneda?.code,
        });
        formOportunidad.setFieldValue("moneda_id", initValues.moneda_id);
      }

      // ESTATUS
      formOportunidad.setFieldValue("status_id", initValues.status_id);
    } else {
      setApprovalDate("");
      setClosingDate("");
      setCantidad(0);
    }
  }, [initValues, id_row, etapa]);

  useEffect(() => {
    localStorage.setItem("notification_new", noti.toString());
    window.dispatchEvent(new Event("storage"));
  }, [noti]);

  const formOportunidad = useFormik({
    initialValues: initValues,
    validationSchema: OporunidadSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          if (values.etapa?.code == "CGA") {
            Swal.fire({
              title: "¿Desea cerrar la venta?",
              html: `
              <div className="text-gray-600">
                Se va a cerrar la venta con el siguiente monto <b>$${values.cantidad_estimada}</b>, una vez
                cerrada la venta ya no se podrá modificar ningún dato de esta
                oportunidad.
              </div>
              `,
              showCancelButton: true,
              padding: "2em",
              cancelButtonText: "Cancelar",
              confirmButtonText: "Aceptar",
              reverseButtons: true,
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-light me-3",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                updateOportunidad(values)
                  .then((res) => {
                    const {
                      data: { message },
                    } = res;
                    toast.success(message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    setReloadInfoCurrentOpportunity(Math.random() * 40);
                    onRefreshData();
                    setLoading(false);
                  })
                  .catch((err) => {
                    let resMessageToast: string = "";
                    const {
                      data: { message, details = null },
                    } = err.response;
                    resMessageToast = `${message}`;
                    setLoading(false);
                    setSubmitting(false);
                    toast.error(resMessageToast, {
                      position: "top-right",
                      autoClose: 4000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  });
              } else {
                setLoading(false);
              }
            });
          } else {
            updateOportunidad(values)
              .then((res) => {
                const {
                  data: { message },
                } = res;
                toast.success(message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                setReloadInfoCurrentOpportunity(Math.random() * 40);
                onRefreshData();
                setLoading(false);

                if (showConfirmRedirect) {
                  Swal.fire({
                    title: "¿Deseas crear una cotización?",
                    showCancelButton: true,
                    padding: "2em",
                    cancelButtonText: "Cancelar",
                    confirmButtonText: "Aceptar",
                    reverseButtons: true,
                    customClass: {
                      confirmButton: "btn btn-primary",
                      cancelButton: "btn btn-light me-3",
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      history.push(
                        `/cotizaciones/add/${encryptId(values.id.toString())}`
                      );
                    }
                  });
                }
              })
              .catch((err) => {
                let resMessageToast: string = "";
                const {
                  data: { message, details = null },
                } = err.response;
                resMessageToast = `${message}`;
                setLoading(false);
                setSubmitting(false);
                toast.error(resMessageToast, {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              });
          }
        } else {
          addOportunidad(values)
            .then((res) => {
              const {
                data: {
                  message,
                  doc: {
                    data: { id },
                  },
                },
              } = res;
              toast.success(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              formOportunidad.resetForm();
              setLoading(false);
              // handleClose(true);
              setNoti(noti + 1);
              setCantidad(0);

              if (showConfirmRedirect) {
                Swal.fire({
                  title: "¿Deseas crear una cotización?",
                  showCancelButton: true,
                  padding: "2em",
                  cancelButtonText: "Cancelar",
                  confirmButtonText: "Aceptar",
                  reverseButtons: true,
                  customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-light me-3",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    history.push(
                      `/cotizaciones/add/${encryptId(id.toString())}`
                    );
                  } else {
                    history.push(`/oportunidades`);
                  }
                });
              } else {
                history.push(`/oportunidades`);
              }
            })
            .catch((err) => {
              let resMessageToast: string = "";
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            });
        }
      }, 1000);
    },
  });

  useEffect(() => {
    if (initValues.etapa?.code) {
      if (initValues.etapa?.code === "CGA") {
        setFormIsEdit(true);
        onDisabledHistory(true);
      } else {
        setFormIsEdit(false);
        onDisabledHistory(false);
      }
    }
  }, [initValues.etapa]);

  useEffect(() => {
    formOportunidad.setFieldValue("cantidad_estimada", cantidad);
    formOportunidad.setFieldValue("probabilidad", probabilidad);
  }, [cantidad, probabilidad]);

  const handleSelect = (selectedOption: any, selectId: string) => {
    if (selectedOption) {
      const { value, label } = selectedOption;
      switch (selectId) {
        case "ETP":
          setStaegId(selectedOption);
          formOportunidad.setFieldValue("etapa_id", value);
          break;
        case "TOPP":
          setTypeOPPId(selectedOption);
          formOportunidad.setFieldValue("tipo_oportunidad_id", value);
          break;
        case "DEP":
          setDptoId(selectedOption);
          formOportunidad.setFieldValue("departamento_id", value);
          if (value === "new") {
            setShowModalDpto(true);
            setDptoId(null);
            formOportunidad.setFieldValue("departamento_id", null);
          }
          break;
        case "UNEGOCIO":
          setUnidadNegId(selectedOption);
          formOportunidad.setFieldValue("unidad_negocio_id", value);
          if (value === "new") {
            setShowModalUnit(true);
            setUnidadNegId(null);
            formOportunidad.setFieldValue("unidad_negocio_id", null);
          }
          break;
        case "MON":
          setMonedaId(selectedOption);
          formOportunidad.setFieldValue("moneda_id", value);
          break;
        case "CUENTA":
          setCueId(selectedOption);
          formOportunidad.setFieldValue("cuenta_id", value);
          setCuentaId(value);
          setAccName(label);
          // subcuenta
          setSbId(null);
          formOportunidad.setFieldValue("sub_cuenta_id", 0);
          setSubcuentaId(0);
          setSubAccName("");
          setSubAccList([
            {
              value: "new",
              label: "Agregar Subcuenta",
            },
          ]);
          // contacto
          setContId(null);
          formOportunidad.setFieldValue("nombre_contacto", 0);
          if (value === "new") {
            setShowModalCuenta(true);
            setCueId(null);
            setVCId(null);
            setAccName("");
            setCuentaId(0);
            formOportunidad.setFieldValue("cuenta_id", 0);
          }
          break;
        case "SUB":
          setSbId(selectedOption);
          formOportunidad.setFieldValue("sub_cuenta_id", value);
          setSubcuentaId(value);
          setSubAccName(label);

          if (value === "new") {
            setShowModalSubCuenta(true);
            setSbId(null);
            setSubAccName("");
            setSubcuentaId(0);
            formOportunidad.setFieldValue("sub_cuenta_id", 0);
          }
          break;
        case "CONT":
          setContId(selectedOption);
          formOportunidad.setFieldValue("nombre_contacto", value);
          if (value === "new") {
            setShowModalContact(true);
            setContId(null);
            formOportunidad.setFieldValue("nombre_contacto", 0);
          }
          break;
        case "VC":
          setVCId(selectedOption);
          formOportunidad.setFieldValue("vendedor_comercial_id", value);
          break;
        default:
          break;
      }
    } else {
      switch (selectId) {
        case "ETP":
          setStaegId(selectedOption);
          formOportunidad.setFieldValue("etapa_id", 0);
          break;
        case "TOPP":
          setTypeOPPId(selectedOption);
          formOportunidad.setFieldValue("tipo_oportunidad_id", 0);
          break;
        case "DEP":
          setDptoId(selectedOption);
          formOportunidad.setFieldValue("departamento_id", 0);
          break;
        case "UNEGOCIO":
          setUnidadNegId(selectedOption);
          formOportunidad.setFieldValue("unidad_negocio_id", 0);
          break;
        case "MON":
          setMonedaId(selectedOption);
          formOportunidad.setFieldValue("moneda_id", 0);
          break;
        case "CUENTA":
          setCueId(selectedOption);
          formOportunidad.setFieldValue("cuenta_id", 0);
          setCuentaId(0);
          setAccName("");
          setSubAccList([]);

          // subcuenta
          setSbId(null);
          formOportunidad.setFieldValue("sub_cuenta_id", 0);
          setSubcuentaId(0);
          setSubAccName("");
          setVCId(null);
          // contacto
          setContId(null);
          formOportunidad.setFieldValue("nombre_contacto", 0);
          break;
        case "SUB":
          setSbId(selectedOption);
          formOportunidad.setFieldValue("sub_cuenta_id", 0);
          setSubcuentaId(0);
          setSubAccName("");
          break;
        case "CONT":
          setContId(selectedOption);
          formOportunidad.setFieldValue("nombre_contacto", 0);
          break;
        case "VC":
          setVCId(selectedOption);
          formOportunidad.setFieldValue("vendedor_comercial_id", 0);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (staegId) {
      const {
        data: { code },
      } = staegId;
      if (
        (!id_row && code === "COTI") ||
        (id_row > 0 && stageCodeInitial !== "COTI" && code === "COTI")
      ) {
        setShowConfirmRedirect(true);
      } else {
        setShowConfirmRedirect(false);
      }
    }
  }, [staegId, id_row, stageCodeInitial]);

  return (
    <>
      <form
        id="kt_modal_generic_form"
        className="form mb-10"
        action="#"
        onSubmit={formOportunidad.handleSubmit}
      >
        <div className="row wh-0100 mb-3">
          <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
            <input
              className="form-control mb-2 w-100 border-0"
              data-kt-element="input"
              style={{
                width: "100%",
                padding: "15px",
                fontSize: "24px",
                boxSizing: "border-box",
                marginBottom: "20px",
              }}
              disabled={formIsEdit}
              placeholder="Nombre *"
              {...formOportunidad.getFieldProps("nombre")}
            ></input>
            {formOportunidad.touched.nombre && formOportunidad.errors.nombre && (
              <div className="fv-plugins-message-container text-danger w-100">
                <span role="alert">{formOportunidad.errors.nombre}</span>
              </div>
            )}
          </div>
        </div>
        <div className="space-items-between mb-3">
          <div className="row wh-0100">
            <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
              <div className="mb-3">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-7 mb-1 w-auto">
                    Etapa
                  </label>
                </div>
                <Select
                  options={etapa}
                  isDisabled={formIsEdit}
                  styles={styleSelect}
                  placeholder={"Seleccione"}
                  isSearchable={true}
                  noOptionsMessage={() => {
                    return <span>{"No se encontraron registros"}</span>;
                  }}
                  onChange={(event) => handleSelect(event, "ETP")}
                  value={staegId}
                  isLoading={loadingRequest}
                  isClearable
                  backspaceRemovesValue
                />
                {formOportunidad.touched.etapa_id &&
                  formOportunidad.errors.etapa_id && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">
                        {formOportunidad.errors.etapa_id}
                      </span>
                    </div>
                  )}
              </div>
            </div>

            <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
              <div className="mb-3">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-7 mb-1 w-auto">
                    Cuenta
                  </label>
                </div>
                <Select
                  isDisabled={formIsEdit}
                  options={cuentaDataSelect}
                  styles={styleSelect}
                  placeholder={"Seleccione"}
                  isSearchable={true}
                  noOptionsMessage={() => {
                    return <span>{"No se encontraron registros"}</span>;
                  }}
                  onChange={(event) => handleSelect(event, "CUENTA")}
                  value={cueId}
                  isLoading={loadingRequest}
                  isClearable
                  backspaceRemovesValue
                />
                {formOportunidad.touched.cuenta_id &&
                  formOportunidad.errors.cuenta_id && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">
                        {formOportunidad.errors.cuenta_id}
                      </span>
                    </div>
                  )}
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
              <div className="mb-3">
                <div className="w-100 d-flex">
                  <label className=" fw-bold fs-7 mb-1 w-auto">Subcuenta</label>
                </div>
                <Select
                  isDisabled={formIsEdit}
                  options={subAccList}
                  // options={subCuentaDataSelect}
                  styles={styleSelect}
                  placeholder={"Seleccione"}
                  isSearchable={true}
                  noOptionsMessage={() => {
                    return <span>{"No se encontraron registros"}</span>;
                  }}
                  onChange={(event) => handleSelect(event, "SUB")}
                  value={sbId}
                  isLoading={loadingRequest}
                  isClearable
                  backspaceRemovesValue
                />
              </div>
            </div>
          </div>
        </div>
        <div className="space-items-between mb-3">
          <div className="row wh-0100">
            <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
              <div className="mb-3">
                <div className="w-100 d-flex">
                  <label className="fw-bold fs-7 mb-1 w-auto w-100">
                    Departamento
                  </label>
                </div>
                <Select
                  isDisabled={formIsEdit}
                  options={dataSelectDpto}
                  styles={styleSelect}
                  placeholder={"Seleccione"}
                  isSearchable={true}
                  noOptionsMessage={() => {
                    return <span>{"No se encontraron registros"}</span>;
                  }}
                  onChange={(event) => handleSelect(event, "DEP")}
                  value={dptoId}
                  isLoading={loadingRequest}
                  isClearable
                  backspaceRemovesValue
                />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
              <div className="mb-3">
                <div className="w-100 d-flex">
                  <label className="fw-bold fs-7 mb-1 w-auto">Contacto</label>
                </div>
                <Select
                  isDisabled={formIsEdit}
                  options={contDataSelect}
                  styles={styleSelect}
                  placeholder={"Seleccione"}
                  isSearchable={true}
                  noOptionsMessage={() => {
                    return <span>{"No se encontraron registros"}</span>;
                  }}
                  onChange={(event) => handleSelect(event, "CONT")}
                  value={contId}
                  isLoading={loadingRequest}
                  isClearable
                  backspaceRemovesValue
                />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
              <div className="mb-3 ">
                <div className="w-100 d-flex mb-1">
                  <label className="required fw-bold fs-7 mb-1 w-auto w-100">
                    Vendedor comercial
                  </label>
                </div>
                <Select
                  isDisabled={formIsEdit}
                  options={sellerData}
                  styles={{
                    placeholder: (provided: any, state: any) => ({
                      ...provided,
                      color: "#181c32",
                      textAlign: "center",
                    }),
                    indicatorSeparator: (provided: any, state: any) => ({
                      ...provided,
                      display: "none",
                    }),
                    dropdownIndicator: (provided: any, state: any) => ({
                      ...provided,
                      color: "#9096EC",
                      display: "none",
                    }),
                    control: (provided: any, state: any) => ({
                      ...provided,
                      border: "none",
                      borderRadius: "6px",
                      padding: "3px",
                      fontSize: "1rem",
                      boxShadow: "none",
                      backgroundColor: state.isDisabled ? "#EFF2F5" : "white",
                    }),
                    singleValue: (provided: any, state: any) => ({
                      ...provided,
                      color: state.isDisabled ? "#181c32" : "inherit",
                      fontWeight: 500,
                    }),
                    option: (provided: any, state: any) => ({
                      ...provided,
                      fontSize: "1.0rem",
                      textAlign: "center",
                    }),
                  }}
                  formatOptionLabel={(option) => (
                    <div
                      className="d-flex"
                      style={{ alignItems: "center", width: "100%" }}
                    >
                      <div className="symbol symbol-30px">
                        <span
                          className="symbol-label fs-7 fw-bold bg-light-primary text-primary fw-bold"
                          title={option.label}
                        >
                          {option?.nombre[0]}
                          {option?.apellidoP[0]}
                        </span>
                      </div>
                      <span className="ms-3 ">
                        {option.nombre} {option.apellidoP}
                      </span>
                    </div>
                  )}
                  placeholder={
                    <span className="symbol symbol-30px cursor-pointer">
                      <div className="symbol-label">
                        <KTSVG
                          path="/media/icons/duotune/communication/com006.svg"
                          className="svg-icon-1"
                        />
                      </div>
                    </span>
                  }
                  isSearchable={false}
                  noOptionsMessage={() => {
                    return <span>{"No se encontraron registros"}</span>;
                  }}
                  onChange={(event) => handleSelect(event, "VC")}
                  value={vcId}
                  isLoading={loadingRequest}
                  isClearable
                  backspaceRemovesValue
                />

                {formOportunidad.touched.vendedor_comercial_id &&
                  formOportunidad.errors.vendedor_comercial_id && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">
                        {formOportunidad.errors.vendedor_comercial_id}
                      </span>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="space-items-between mb-3">
          <div className="row wh-0100">
            <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
              <div className="mb-3">
                <div className="w-100 d-flex">
                  <label className="fw-bold fs-7 mb-1 w-auto">
                    Unidad de negocio
                  </label>
                </div>
                <Select
                  isDisabled={formIsEdit}
                  options={unidadNegocio}
                  styles={styleSelect}
                  placeholder={"Seleccione"}
                  isSearchable={true}
                  noOptionsMessage={() => {
                    return <span>{"No se encontraron registros"}</span>;
                  }}
                  onChange={(event) => handleSelect(event, "UNEGOCIO")}
                  value={unidadNegId}
                  isLoading={loadingRequest}
                  isClearable
                  backspaceRemovesValue
                />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
              <div className="mb-3">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-7 mb-1 w-auto">
                    Tipo de oportunidad
                  </label>
                </div>
                <Select
                  isDisabled={formIsEdit}
                  options={oportunidad}
                  styles={styleSelect}
                  placeholder={"Seleccione"}
                  isSearchable={true}
                  noOptionsMessage={() => {
                    return <span>{"No se encontraron registros"}</span>;
                  }}
                  onChange={(event) => handleSelect(event, "TOPP")}
                  value={typeOppId}
                  isLoading={loadingRequest}
                  isClearable
                  backspaceRemovesValue
                />
                {formOportunidad.touched.tipo_oportunidad_id &&
                  formOportunidad.errors.tipo_oportunidad_id && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">
                        {formOportunidad.errors.tipo_oportunidad_id}
                      </span>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="space-items-between mb-3">
          <div className="row wh-0100">
            <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
              <div className="mb-3">
                <div className="w-100 d-flex">
                  <label className="fw-bold required fs-7 mb-1 w-auto">
                    Probabilidad de éxito
                  </label>
                </div>
                <NumericFormat
                  disabled={formIsEdit}
                  className="form-control fs-7"
                  {...formOportunidad.getFieldProps("probabilidad")}
                  thousandSeparator={false}
                  allowNegative={false}
                  suffix="%"
                  allowLeadingZeros={false}
                  isAllowed={({ value }) => {
                    // Verificar que el valor esté entre 0 y 100
                    if (parseInt(value) < 0 || parseInt(value) > 100) {
                      return false;
                    }
                    // Verificar que el valor tenga entre 1 y 3 dígitos enteros
                    const integerValue = parseInt(value);
                    if (integerValue < 10 && value.length > 1) {
                      return false;
                    }
                    if (integerValue < 100 && value.length > 2) {
                      return false;
                    }
                    return true;
                  }}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    setProbabilidad(floatValue ? floatValue : 0);
                  }}
                />
                {formOportunidad.touched.probabilidad &&
                  formOportunidad.errors.probabilidad && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">
                        {formOportunidad.errors.probabilidad}
                      </span>
                    </div>
                  )}
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
              <div className="mb-3">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-7 mb-1 w-auto">
                    Fecha estimada de cierre
                  </label>
                </div>
                <input
                  disabled={formIsEdit}
                  className="form-control fs-7"
                  data-kt-element="input"
                  type="date"
                  min={minApprovaldDate}
                  {...formOportunidad.getFieldProps(
                    "fecha_aprobacion_estimada"
                  )}
                  onKeyDown={(e) => e.preventDefault()}
                  value={approvalDate}
                  onChange={(event) => {
                    setApprovalDate(event.target.value);
                    formOportunidad.setFieldValue(
                      "fecha_aprobacion_estimada",
                      event.target.value
                    );
                  }}
                />
                {formOportunidad.touched.fecha_aprobacion_estimada &&
                  formOportunidad.errors.fecha_aprobacion_estimada && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">
                        {formOportunidad.errors.fecha_aprobacion_estimada}
                      </span>
                    </div>
                  )}
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
              <div className="mb-3">
                <div className="w-100 d-flex">
                  <label className="fw-bold fs-7 mb-1 w-auto">
                    Fecha de cierre real
                  </label>
                </div>
                <input
                  disabled={formIsEdit}
                  className="form-control fs-7"
                  data-kt-element="input"
                  type="date"
                  min={minClosingDate}
                  {...formOportunidad.getFieldProps("fecha_cierre_real")}
                  onKeyDown={(e) => e.preventDefault()}
                  value={closingDate}
                  onChange={(event) => {
                    setClosingDate(event.target.value);
                    formOportunidad.setFieldValue(
                      "fecha_cierre_real",
                      event.target.value
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="space-items-between mb-3">
          <div className="row wh-0100">
            {initValues.status_id != 0 && (
              <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
                <div className="mb-3">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-7 mb-1 w-auto ">Estatus</label>
                  </div>
                  <div
                    className={`mt-4 ${
                      initValues.status
                        ? colorStatusOpportunity(initValues.status.code || "")
                        : ""
                    }`}
                  >
                    {initValues?.status ? initValues.status.name : "N/A"}{" "}
                  </div>
                </div>
              </div>
            )}

            <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
              <div className="mb-3">
                <div className="w-100 d-flex">
                  <label className="fw-bold fs-7 mb-1 w-auto w-100">
                    Unidad de moneda
                  </label>
                </div>
                <Select
                  isDisabled={formIsEdit}
                  options={moneda}
                  styles={styleSelect}
                  placeholder={"Seleccione"}
                  isSearchable={true}
                  noOptionsMessage={() => {
                    return <span>{"No se encontraron registros"}</span>;
                  }}
                  onChange={(event) => handleSelect(event, "MON")}
                  value={monedaId}
                  isLoading={loadingRequest}
                  isClearable
                  backspaceRemovesValue
                />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
              <div className="mb-3">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-7 mb-1 w-auto w-100">
                    Cantidad estimada
                  </label>
                </div>
                {/* <NumericFormat
                  disabled={formIsEdit}
                  className="form-control fs-4"
                  thousandSeparator={false}
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  value={cantidad}
                  onValueChange={(values: any) => {
                    const { floatValue } = values;
                    setCantidad(floatValue);
                  }}
                /> */}
                <NumericFormat
                  disabled={formIsEdit}
                  className="form-control fs-4"
                  thousandSeparator={true}
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  value={cantidad}
                  onValueChange={(values: any) => {
                    const { floatValue } = values;
                    setCantidad(floatValue);
                  }}
                />

                {formOportunidad.touched.cantidad_estimada &&
                  formOportunidad.errors.cantidad_estimada && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">
                        {formOportunidad.errors.cantidad_estimada}
                      </span>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="row wh-0100 mb-3">
          <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
            <div className="w-100 d-flex">
              <label className="fw-bold fs-6 mb-2 w-auto">Descripción</label>
            </div>
            <textarea
              disabled={formIsEdit}
              className="form-control"
              data-kt-element="input"
              rows={5}
              style={{ resize: "none" }}
              placeholder=""
              {...formOportunidad.getFieldProps("detalles")}
            ></textarea>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-10">
          <button
            type="reset"
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            onClick={() => {
              history.push(`/oportunidades`);
            }}
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            data-kt-users-modal-action="submit"
            disabled={loading || formIsEdit}
          >
            {!loading && (
              <span className="indicator-label">
                {id_row > 0 ? "Actualizar" : "Guardar"}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Espere por favor...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>

      {id_row && !loadingRequest ? (
        <div className="row g-6 g-xl-9" ref={sectionTabs}>
          <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
            <div className="card card-flush p-5">
              <div className="row mb-5">
                <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                  <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                    <li className="nav-item cursor-pointer ">
                      <a
                        className={`nav-link me-6 ${
                          tabs === "NOTE" ? "text-active-primary active" : ""
                        } `}
                        onClick={() => {
                          setTabs("NOTE");
                        }}
                      >
                        {/* <KTSVG
                                                path="/media/icons/duotune/text/txt001.svg"
                                                className="svg-icon-1 fs-7"
                                            /> */}
                        <span className="margin-left-05">Notas</span>
                      </a>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <a
                        className={`nav-link me-6 ${
                          tabs === "TASK" ? "text-active-primary active" : ""
                        } `}
                        onClick={() => {
                          setTabs("TASK");
                        }}
                      >
                        {/* <KTSVG
                                                path="/media/icons/duotune/text/txt001.svg"
                                                className="svg-icon-1 fs-7"
                                            /> */}
                        <span className="margin-left-05">Tareas</span>
                      </a>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <a
                        className={`nav-link me-6 ${
                          tabs === "RISKS" ? "text-active-primary active" : ""
                        } `}
                        onClick={() => {
                          setTabs("RISKS");
                        }}
                      >
                        {/* <KTSVG
                                                path="/media/icons/duotune/text/txt001.svg"
                                                className="svg-icon-1 fs-7"
                                            /> */}
                        <span className="margin-left-05">Riesgos</span>
                      </a>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <a
                        className={`nav-link me-6 ${
                          tabs === "ACC" ? "text-active-primary active" : ""
                        } `}
                        onClick={() => {
                          setTabs("ACC");
                        }}
                      >
                        {/* <KTSVG
                                                path="/media/icons/duotune/text/txt001.svg"
                                                className="svg-icon-1 fs-7"
                                            /> */}
                        <span className="margin-left-05">Documentos</span>
                      </a>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <a
                        className={`nav-link me-6 ${
                          tabs === "COTI" ? "text-active-primary active" : ""
                        } `}
                        onClick={() => {
                          setTabs("COTI");
                        }}
                      >
                        <span className="margin-left-05">Cotizaciones</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                {tabs === "NOTE" ? (
                  <NoteList
                    data={notes}
                    onOpenModal={openModal}
                    active={activeNewNote}
                    onOpenDetailModal={openDetailModal}
                    isEdit={formIsEdit}
                  ></NoteList>
                ) : null}
                {tabs === "TASK" ? (
                  <>
                    <TaskList
                      tasks={tasks}
                      onSaveTask={saveTask}
                      active={activeNewTask}
                      isEdit={formIsEdit}
                    ></TaskList>
                  </>
                ) : null}
                {tabs === "RISKS" ? (
                  <>
                    <RiskList
                      risks={risks}
                      onSaveRisk={saveRisk}
                      active={activeNewRisk}
                      isEdit={formIsEdit}
                    ></RiskList>
                  </>
                ) : null}
                {tabs === "ACC" ? (
                  <OpportunityDoc
                    documents={dataDocuments || []}
                    onRefreshData={() => {
                      setReloadDocuments(Math.random() * 100);
                      onRefreshData();
                    }}
                    opportunity={initValues.id}
                    isEdit={formIsEdit}
                  ></OpportunityDoc>
                ) : null}
                {tabs === "COTI" ? (
                  <QuotationList
                    data={quotations}
                    isEdit={formIsEdit}
                    oportunty={id_row}
                  ></QuotationList>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <CreateNoteOppModal
        show={showNoteModal}
        handleClose={(refresh) => {
          setShowNoteModal(!showNoteModal);
          if (refresh) {
            setReloadGridNotes(Math.random() * 40);
            onRefreshData();
          }
        }}
        oportunityId={Number(id_row)}
      ></CreateNoteOppModal>
      <CreateNoteOppDetailModal
        show={showNoteDetailModal}
        handleClose={(refresh) => {
          setShowNoteDetailModal(!showNoteDetailModal);
        }}
        data={notes}
        oportunityId={id_row}
        isEdit={formIsEdit}
      ></CreateNoteOppDetailModal>

      <CreateCuentaModal
        show={showModalCuenta}
        sellers={sellerData}
        handleClose={(isNew: boolean, newCuenta: any) => {
          if (isNew && newCuenta) {
            setReloadInfoCuenta(Math.random() * 50);
            const { value, label } = newCuenta;
            setCuentaId(value);
            formOportunidad.setFieldValue("cuenta_id", value);
            setCueId({
              value: value,
              label: label,
            });
            setAccName(label);
          }
          setShowModalCuenta(!showModalCuenta);
        }}
      ></CreateCuentaModal>
      <CreateSubCuentaModal
        show={showModalSubCuenta}
        cuentaList={cuentaDataSelect}
        cuentaSelected={cuentaId}
        cuentaObj={cueId}
        handleClose={(isNew: boolean, newSubcuenta: any) => {
          if (isNew && newSubcuenta) {
            setReloadInfoSubCuenta(Math.random() * 50);
            const { value, label } = newSubcuenta;
            formOportunidad.setFieldValue("sub_cuenta_id", value);
            setSubcuentaId(value);
            setSbId({
              value: value,
              label: label,
            });
            setSubAccName(label);
          }
          setShowModalSubCuenta(!showModalSubCuenta);
        }}
      ></CreateSubCuentaModal>
      <CreateContactModal
        show={showModalContact}
        cuenta={cuentaId ? cuentaId : null}
        subcuenta={subcuentaId ? subcuentaId : null}
        nameAcc={accName}
        nameSubAcc={subAccName}
        handleClose={(isNew: boolean, newContact: any) => {
          if (isNew && newContact) {
            setReloadContact(Math.random() * 50);
            const { value, label } = newContact;
            formOportunidad.setFieldValue("nombre_contacto", value);
            setContId({
              value: value,
              label: label,
            });
          }
          setShowModalContact(!showModalContact);
        }}
      ></CreateContactModal>
      <CreateDptoModal
        show={showModalDpto}
        handleClose={(isNew: boolean, newDpto: any) => {
          if (isNew && newDpto) {
            setReloadDpto(Math.random() * 50);
            const { value, label } = newDpto;
            formOportunidad.setFieldValue("departamento_id", value);
            setDptoId({
              value: value,
              label: label,
            });
          }
          setShowModalDpto(!showModalDpto);
        }}
      ></CreateDptoModal>
      <CreateUnitModal
        show={showModalUnit}
        handleClose={(isNew: boolean, newUnit: any) => {
          if (isNew && newUnit) {
            setReloadUnit(Math.random() * 50);
            const { value, label } = newUnit;
            formOportunidad.setFieldValue("unidad_negocio_id", value);
            setUnidadNegId({
              value: value,
              label: label,
            });
          }
          setShowModalUnit(!showModalUnit);
        }}
      ></CreateUnitModal>
    </>
  );
};
export { OpportunityForm };
