import React, { useState, useEffect, useCallback, useRef } from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Collapse } from "react-bootstrap-v5";
import { useHistory } from "react-router-dom";
import CountUp from "react-countup";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";

import {
  KTSVG,
  formatPrice,
  SimpleAlert,
  toAbsoluteUrl,
} from "../../../../_metronic/helpers";
import {
  useSelectCurrencyPackages,
  useSelectGeneric,
  useSelectGenericPlus,
} from "../../catalogos/generico/hooks/genericoHook";
import {
  styleSelect,
  styleSelectPackages,
  styleSelectQuo,
} from "../../../../_metronic/helpers/SelectStyles";
import {
  useActualizarPrecios,
  useCreateEditQuotation,
  useEliminarCotizacion,
  useFinishQuotation,
  useGetDocumentsByQuoId,
  useGetNoteByQuoId,
  useGetOne,
  useGetVersionsByQuoId,
  useSelectAccount,
  useSelectDiscount,
  useSelectSubaccount,
  useSelectSurcharge,
} from "../hooks/QuotationHook";
import { CreateCuentaModal } from "../../oportunidades/_modals/quickAccess/CreateCuentaModal";
import {
  useGetAllVendedoresComerciales,
  useGetOppById,
} from "../../oportunidades/hooks/OportunidadHook";
import { SubaccountModal } from "../_modals/SubaccountModal";
import { CreateContactModal } from "../../oportunidades/_modals/quickAccess/CreateContactModal";
import { useGetContactosFilter } from "../../contacto/hooks/ContactoHook";
import { formatDateTime } from "../../../../_metronic/helpers/FormatDate";
import { NumericFormat } from "react-number-format";
// import { ConceptsList } from './sections/ConceptsList';
import { encryptId } from "../../../../_metronic/helpers";
import { SectionModel } from "../models/QuotationModel";
import { SectionsList } from "./sections/SectionsList";
import { NoteList } from "./sections/NoteList";
import { CreateNoteModal } from "../_modals/CreateNoteModal";
import { CreateNoteDetailModal } from "../_modals/CreateNoteDetailModal";
import { DocumentsList } from "./sections/DocumentsList";
import { VersionList } from "./sections/VersionList";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { QuotationWizard } from "./sections/QuotationWizard";
import { useChangeTypeCurrency } from "../../products/hooks/ProductsHook";

const dateNow = new Date();

type Props = {
  id: number;
  onRefreshHistory: () => void;
  oportunityId: number;
};
const quotationSchema = Yup.object().shape({
  nombre: Yup.string().required("Nombre de la cotización es requerido."),
  unidad_moneda_id: Yup.number()
    .min(1, "Unidad de moneda es requerido")
    .required("Unidad de moneda es requerido"),
  tipo_id: Yup.number()
    .min(1, "Tipo de cotización es requerido")
    .required("Tipo de cotización es requerido"),
  tipo_ajuste_id: Yup.number()
    .min(1, "Tipo de ajuste es requerido")
    .required("Tipo de ajuste es requerido"),
  cuenta_id: Yup.number()
    .min(1, "Cuenta es requerido")
    .required("Cuenta es requerido"),
});

const QuotationForm: React.FC<Props> = ({
  id,
  onRefreshHistory,
  oportunityId,
}) => {
  const [currencySelected, setCurrencySelected] = useState({});
  const { change } = useChangeTypeCurrency(currencySelected);

  const history = useHistory();
  const [isNewVersion, setIsNewVersion] = useState<boolean>(false);
  const [IsDeleteVersion, setIsDeleteVersion] = useState<boolean>(false);

  const { initValues: OportunyData } = useGetOppById(oportunityId, 1);

  const sectionTabs = useRef<HTMLDivElement>(null);
  const [tabs, setTabs] = useState<string | "NOTE" | "VER" | "DOC">("NOTE");

  // NOTAS
  const [reloadGridNotes, setReloadGridNotes] = useState<number>(
    Math.random() * 40
  );
  const { notes, loadingNote } = useGetNoteByQuoId(id, reloadGridNotes);
  const [activeNewNote, setActiveNewNote] = useState<boolean>(false);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [showNoteDetailModal, setShowNoteDetailModal] =
    useState<boolean>(false);

  // DOCUMENTS
  const [reloadDocuments, setReloadDocuments] = useState(Math.random() * 40);
  const { dataDocuments, loadingDocuments } = useGetDocumentsByQuoId(
    Number(id),
    reloadDocuments
  );

  // VERSIONS
  const [reloadGridVersions, setReloadGridVersions] = useState<number>(
    Math.random() * 40
  );
  const { versions, loadingVersions } = useGetVersionsByQuoId(
    id,
    reloadGridVersions
  );

  const [customerOpp, setCustomerOpp] = useState<number>(0);
  const [subAccOpp, setSubAccOpp] = useState<number>(0);
  const [sections, setSections] = useState<SectionModel[]>([]);
  const { initValues, loadingRequest } = useGetOne(Number(id));
  const [costoIndirecto, setCostoIndirecto] = useState<number>(0);
  const [utilidad, setUtilidad] = useState<number>(0);
  const [subtotal, setSubtotal] = useState<number>(0);
  const [taxTotal, setTaxTotal] = useState<number>(0);
  const [surchargeTotal, setSurchargeTotal] = useState<number>(0);
  const [discountTotal, setDiscountTotal] = useState<number>(0);
  const [discountTotalMemo, setDiscountTotalMemo] = useState<any>({
    code: "",
    value: 0,
    tipo_moneda: "",
  });
  const [surchargeTotalMemo, setSurchargeTotalMemo] = useState<any>({
    code: "",
    value: 0,
    tipo_moneda: "",
  });

  const [codeInter, setCodeInter] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  // const [loadingFinish, setLoadingFinish] = useState<boolean>(false);
  const [accountId, setAccountId] = useState<number>(0);
  const [subaccountId, setSubaccountId] = useState<number>(0);
  const [exchangeRate, setExchangeRate] = useState<number>(0);

  // const [conceptsList, setConceptsList] = useState<any[]>([]);
  const [updateCostConcepts, setUpdateCostConcepts] = useState<number>(
    Math.random() * 40
  );
  const [selectAccountAdding, setSelectAccountAdding] =
    useState<boolean>(false);
  const [selectSubaccountAdding, setSelectSubaccountAdding] =
    useState<boolean>(false);
  const [selectContactAdding, setSelectContactAdding] =
    useState<boolean>(false);
  const [openSectionInfoGeneral, setOpenSectionInfoGeneral] =
    useState<boolean>(false);
  const [openSectionQuo, setOpenSectionQuo] = useState<boolean>(false);
  const [openSectionTotals, setOpenSectionTotals] = useState<boolean>(false);
  const [showWizard, setShowWizard] = useState<boolean>(false);

  const [isOpenContactModal, setIsOpenContactModal] = useState<boolean>(false);
  const [isOpenSubaccountModal, setIsOpenSubaccountModal] =
    useState<boolean>(false);
  const [isOpenAccountModal, setIsOpenAccountModal] = useState<boolean>(false);
  const [selectedOptionAccount, setSelectedOptionAccount] = useState<
    any | null
  >(null);
  const [selectedOptionSubaccount, setSelectedOptionSubaccount] = useState<
    any | null
  >(null);
  const [selectedOptionContact, setSelectedOptionContact] = useState<
    any | null
  >(null);
  const [selectedOptionSeller, setSelectedOptionSeller] = useState<any | null>(
    null
  );
  const [reloadAccount, setReloadAccount] = useState(Math.random() * 40);
  const [reloadSubaccountList, setReloadSubaccountList] = useState<number>(
    Math.random() * 40
  );
  const [reloadContact, setReloadContact] = useState<number>(
    Math.random() * 40
  );

  const [unitMeasure, setUnitMeasure] = useState<any>();

  const { sellerData, loadingRequest: loadingSD } =
    useGetAllVendedoresComerciales();
  const { catalog: account, loadingRequest: loadingAccount } =
    useSelectAccount(reloadAccount);
  const { catalog: settingsType } = useSelectGeneric("TACOT");
  const { catalog: indirectCosts, loadingRequest: loadingIC } =
    useSelectGenericPlus("TCOIN");
  const { catalog: utility, loadingRequest: loadingUTI } =
    useSelectGenericPlus("TCOIN");
  const { catalog: quotationType, loadingRequest: loadingQT } =
    useSelectGenericPlus("TCOT");
  const { catalog: unitMeasurement, loadingRequest: loadingUM } =
    useSelectGenericPlus("UNEG");
  const { catalog: currencyUnit, loadingRequest: loadingCU } =
    useSelectCurrencyPackages("UMON");
  const { catalog: subaccount, loadingRequest: loadingSA } =
    useSelectSubaccount(accountId, reloadSubaccountList);

  const [reloadSurcharge, setReloadSurchage] = useState(Math.random() * 40);
  const { catalog: surcharge, loadingRequest: loadingSUR } =
    useSelectSurcharge(reloadSurcharge);

  const [reloadDiscount, setReloadDiscount] = useState(Math.random() * 40);
  const { catalog: discount, loadingRequest: loadingDIS } =
    useSelectDiscount(reloadDiscount);
  // CREA Y EDITA LA COTIZACIÓN
  const {
    createEdit,
    loading: loadingQuotation,
    errorRequest,
  } = useCreateEditQuotation();

  // ELIMINA LA COTIZACÓN
  const { eliminar, isSuccess: isSuccessEliminar } = useEliminarCotizacion();

  // ACTUALIZAR PRECIOS
  const { actualizar } = useActualizarPrecios();

  // FINALIZA LA COTIZACION
  const {
    finish,
    loading: loadingFinish,
    errorRequest: errorRequestFinish,
    isSuccess,
  } = useFinishQuotation();

  const { contDataSelect: contacts } = useGetContactosFilter(
    reloadContact,
    accountId,
    subaccountId,
    1
  );

  // STEPPER
  const [currentSchema, setCurrentSchema] = useState();
  const [textStepper, setTextStepper] = useState("Continuar");
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [validateBack, setValidateBack] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  // SECTIONS NOTES
  const openModal = (isOpen: boolean) => {
    setShowNoteModal(isOpen);
  };
  const openDetailModal = (isOpen: boolean) => {
    setShowNoteDetailModal(isOpen);
  };

  const quotationForm = useFormik({
    initialValues: initValues,
    validationSchema: quotationSchema,
    enableReinitialize: true,
    onSubmit: (values: any, { setSubmitting }) => {
      values.secciones = sections;
      if (sections.length > 0) {
        setLoading(true);
        const id = values.id;
        if (isNewVersion) {
          if (values.version !== "V1") {
            values.cotizacion_origen = id;
            values.cargo_version = true;
            values.id = 0;
          } else {
            values.cotizacion_origen = null;
            values.cargo_version = false;
            values.id = 0;
          }
        }

        // if (!oportunityId) {
        //   setShowWizard(true);
        // }

        createEdit(values);
        values.id = id;
      } else {
        SimpleAlert(
          "Debe agregar al menos un concepto a la cotización.",
          3000,
          "error"
        );
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (unitMeasurement) {
      const filter = unitMeasurement.filter((item) => item.id !== 0);

      setUnitMeasure(filter);
    }
  }, [unitMeasurement]);

  useEffect(() => {
    if (change) {
      const {
        rates: {
          MXN: { rate },
        },
      } = change;
      setExchangeRate(Number(rate));
    }
  }, [change]);

  useEffect(() => {
    const { id, unidad_moneda_id } = initValues;
    // console.log('id', id);
    // console.log('currencyUnit', currencyUnit);
    if (currencyUnit) {
      // console.log('currencyUnit', currencyUnit);
      // console.log('initValues', initValues);
      if (id <= 0) {
        // let currencyUnitDefault = currencyUnit[0];
        let currencyUnitDefault = currencyUnit.filter(
          (item) => item.data.code === "MXN"
        );
        // console.log('currencyUnitDefault', currencyUnitDefault);
        changeCurrencyUnit(currencyUnitDefault[0]);
      } else {
        // console.log('currencyUnit', currencyUnit);

        let currencyUnitEdit = currencyUnit.find(
          (option) => option.value === unidad_moneda_id
        );
        // console.log('currencyUnitEdit', currencyUnitEdit);

        changeCurrencyUnit(currencyUnitEdit);
      }
    }
  }, [currencyUnit, initValues]);

  useEffect(() => {
    if (selectAccountAdding) {
      const lastPositionAccount = account[account.length - 1];
      setSelectedOptionAccount(lastPositionAccount);
      setAccountId(lastPositionAccount.id);
      quotationForm.setFieldValue("cuenta_id", lastPositionAccount.id);
    }
  }, [account]);

  useEffect(() => {
    if (selectSubaccountAdding) {
      const lastPositionSubaccount = subaccount[subaccount.length - 1];
      setSelectedOptionSubaccount(lastPositionSubaccount);
      quotationForm.setFieldValue("subcuenta_id", lastPositionSubaccount.id);
    }
  }, [subaccount]);

  useEffect(() => {
    if (selectContactAdding) {
      const lastPositionContacts = contacts[contacts.length - 1];
      setSelectedOptionContact(lastPositionContacts);
      quotationForm.setFieldValue("contacto_id", lastPositionContacts.id);
    }
  }, [contacts]);

  useEffect(() => {
    if (loadingQuotation) {
      if (errorRequest) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoading(false);
          if (isNewVersion) {
            setIsNewVersion(false);
            window.location.reload();

            // setReloadGridVersions(Math.random() * 40);
          } else {
            history.push(
              oportunityId > 0 ? `/oportunidades` : `/cotizaciones/cotizaciones`
            );
            // history.goBack();
          }
        }, 1000);
      }
    }
  }, [loadingQuotation]);

  useEffect(() => {
    if (settingsType.length) {
      const lastPositionSettings = settingsType[settingsType.length - 1];
      quotationForm.setFieldValue(`tipo_ajuste_id`, lastPositionSettings.id);
      quotationForm.setFieldValue(
        `tipo_ajuste_code`,
        lastPositionSettings.code
      );
    }
  }, [settingsType]);

  useEffect(() => {
    const { id } = initValues;
    if (id > 0) {
      setDiscountTotalMemo({
        code: initValues.descuento_tipo_code,
        value: initValues.descuento_value,
        tipo_moneda: initValues.descuento_tipo_moneda,
      });
      setSurchargeTotalMemo({
        code: initValues.recargo_tipo_code,
        value: initValues.recargo_value,
        tipo_moneda: initValues.recargo_tipo_moneda,
      });
      initValuesFormatConcepts(initValues.secciones);
      // setConceptsList(initValues.concepts);
    }
  }, [initValues]);

  const initValuesFormatConcepts = (secciones: any) => {
    const mappedSections = initValues.secciones.map(
      (section: any, index: number) => ({
        ...section,
        updatePriceConcepts: updatePriceConcepts,
      })
    );
    setSections(mappedSections);
  };

  useEffect(() => {
    const { id } = initValues;
    if (id > 0) {
      if (account) {
        const accountFilter = account.find(
          (account) => account.id === initValues.cuenta_id
        );
        if (initValues.cuenta_id > 0) {
          changeAccount(accountFilter);
        }
      }
    }
  }, [account, initValues]);

  useEffect(() => {
    const { id } = initValues;
    if (id > 0) {
      if (contacts) {
        const contactFilter = contacts.find(
          (contact) => contact.value === initValues.contacto_id
        );
        changeContact(contactFilter);
      }
    }
  }, [contacts, initValues]);

  useEffect(() => {
    const { id } = initValues;
    if (id > 0) {
      if (subaccount) {
        const contactFilter = subaccount.find(
          (subacc) => subacc.value === initValues.subcuenta_id
        );
        setSelectedOptionSubaccount(contactFilter);
      }
    }
  }, [subaccount, initValues]);

  useEffect(() => {
    const { id } = initValues;
    if (id > 0) {
      if (sellerData) {
        const sellerFilter = sellerData.find(
          (seller) => seller.value === initValues.vendedor_asignado_id
        );
        setSelectedOptionSeller(sellerFilter);
      }
    }
  }, [sellerData, initValues]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        history.push(`/cotizaciones/cotizaciones`);
        // history.goBack();
      }, 1000);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessEliminar) {
      if (IsDeleteVersion) {
        setReloadGridVersions(Math.random() * 40);
      } else {
        history.push(`/cotizaciones/cotizaciones`);
      }
    }
  }, [isSuccessEliminar]);

  const changeUnitMeasurement = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      quotationForm.setFieldValue("unidad_negocio_id", value);
    } else {
      quotationForm.setFieldValue("unidad_negocio_id", 0);
    }
  };

  const changeQuoType = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      quotationForm.setFieldValue("tipo_id", value);
    } else {
      quotationForm.setFieldValue("tipo_id", 0);
    }
  };

  const changeContact = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setSelectedOptionContact(selectedOption);
      setSelectContactAdding(false);
      if (value === "new") {
        setIsOpenContactModal(!isOpenContactModal);
        quotationForm.setFieldValue("contacto_id", 0);
      } else {
        quotationForm.setFieldValue("contacto_id", value);
      }
    } else {
      quotationForm.setFieldValue("contacto_id", 0);
      setSelectedOptionContact(null);
    }
  };

  const changeCurrencyUnit = (selectedOption: any) => {
    if (selectedOption) {
      const {
        value,
        data: { code },
      } = selectedOption;
      quotationForm.setFieldValue("unidad_moneda_id", value);
      setCodeInter(code);
      setUpdateCostConcepts(Math.random() * 40);
    } else {
      quotationForm.setFieldValue("unidad_moneda_id", 0);
      setCodeInter("");
    }
  };
  const changeSeller = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      quotationForm.setFieldValue("vendedor_asignado_id", value);
      setSelectedOptionSeller(selectedOption);
    } else {
      quotationForm.setFieldValue("vendedor_asignado_id", 0);
      setSelectedOptionSeller(null);
    }
  };

  const changeAccount = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setSelectedOptionAccount(selectedOption);
      quotationForm.setFieldValue("subcuenta_id", 0);
      setSelectedOptionSubaccount(null);
      quotationForm.setFieldValue("contacto_id", 0);
      setSelectedOptionContact(null);
      setSelectSubaccountAdding(false);
      if (Number(value) === 0) {
        setIsOpenAccountModal(!isOpenAccountModal);
      }
      quotationForm.setFieldValue("cuenta_id", value);
      setAccountId(value);
    } else {
      setAccountId(0);
      quotationForm.setFieldValue("categoria_id", 0);
      quotationForm.setFieldValue("subcuenta_id", 0);
      setSelectedOptionSubaccount(null);
      quotationForm.setFieldValue("contacto_id", 0);
      setSelectedOptionContact(null);
    }
  };

  const changeSubaccount = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setSelectedOptionSubaccount(selectedOption);
      quotationForm.setFieldValue("subcuenta_id", value);
      if (Number(value) === 0) {
        setIsOpenSubaccountModal(!isOpenAccountModal);
      }
      setSubaccountId(value);
    } else {
      quotationForm.setFieldValue("subcuenta_id", 0);
      setSelectedOptionSubaccount(null);
      setSubaccountId(0);
    }
  };

  const calcularRecargoDescuento = (valor: number, code: string) => {
    let costCalculate: number = 0;
    switch (code) {
      case "MXN":
        if (codeInter === "USD") {
          costCalculate = Number(valor / exchangeRate);
        } else if (codeInter === "MXN") {
          costCalculate = valor;
        }
        break;
      case "USD":
        if (codeInter === "USD") {
          costCalculate = valor;
        } else if (codeInter === "MXN") {
          costCalculate = Number(valor * exchangeRate);
        }
        break;
    }
    return Number(costCalculate);
  };

  const changeSurcharge = (selectedOption: any) => {
    if (selectedOption) {
      const {
        value,
        data: {
          valor,
          tipo: { code },
          unidadMoneda: { code: codeUM },
        },
      } = selectedOption;

      let surcharge =
        code === "TCPOR"
          ? subtotal * Number(valor)
          : calcularRecargoDescuento(Number(valor), codeUM);

      quotationForm.setFieldValue("recargo_id", value);
      quotationForm.setFieldValue("recargo_value", Number(valor));
      quotationForm.setFieldValue("recargo_tipo_code", code);
      setSurchargeTotal(surcharge);
      setSurchargeTotalMemo({
        code: code,
        value: valor,
        tipo_moneda: codeUM,
      });
    } else {
      quotationForm.setFieldValue("recargo_id", 0);
      quotationForm.setFieldValue("recargo_value", 0);
      quotationForm.setFieldValue("recargo_tipo_code", "");
      setSurchargeTotal(0);
      setSurchargeTotalMemo({
        code: "",
        value: 0,
        tipo_moneda: "",
      });
    }
  };

  useEffect(() => {
    if (codeInter) {
      if (surcharge.length) {
        const currentData = surcharge.find(
          (option) => option.data.tipo.code === surchargeTotalMemo.code
        );
        changeSurcharge(currentData);
      }

      if (discount.length) {
        const currentData = discount.find(
          (option) => option.data.tipo.code === discountTotalMemo.code
        );
        changeDiscount(currentData);
      }
    }
  }, [surcharge, codeInter]);

  const changeDiscount = (selectedOption: any) => {
    if (selectedOption) {
      const {
        value,
        data: {
          valor,
          tipo: { code },
          unidadMoneda: { code: codeUM },
        },
      } = selectedOption;
      let discount =
        code === "TCPOR"
          ? subtotal * Number(valor)
          : calcularRecargoDescuento(Number(valor), codeUM);
      quotationForm.setFieldValue("descuento_id", value);
      quotationForm.setFieldValue("descuento_value", Number(valor));
      quotationForm.setFieldValue("descuento_tipo_code", code);
      setDiscountTotal(discount);
      setDiscountTotalMemo({
        code: code,
        value: valor,
        tipo_moneda: codeUM,
      });
    } else {
      quotationForm.setFieldValue("descuento_id", 0);
      quotationForm.setFieldValue("descuento_value", 0);
      quotationForm.setFieldValue("descuento_tipo_code", "");
      setDiscountTotal(0);
      setDiscountTotalMemo({
        code: "",
        value: 0,
        tipo_moneda: "",
      });
    }
  };

  // INICIA COSTO INDIRECTO

  const changeIndirectCosts = (selectedOption: any) => {
    setCostoIndirecto(0);
    if (selectedOption) {
      const {
        value,
        data: { code },
      } = selectedOption;
      quotationForm.setFieldValue("costo_indirecto_value", 0);
      quotationForm.setFieldValue("costo_indirecto_id", value);
      quotationForm.setFieldValue("costo_indirecto_code", code);
    } else {
      quotationForm.setFieldValue("costo_indirecto_id", 0);
      quotationForm.setFieldValue("costo_indirecto_code", "");
      quotationForm.setFieldValue("costo_indirecto_value", 0);
    }
  };

  // INICIA UTILIDAD

  const changeUtility = (selectedOption: any) => {
    setUtilidad(0);
    if (selectedOption) {
      const {
        value,
        data: { code },
      } = selectedOption;
      quotationForm.setFieldValue("utilidad_value", 0);
      quotationForm.setFieldValue("utilidad_id", value);
      quotationForm.setFieldValue("utilidad_code", code);
    } else {
      quotationForm.setFieldValue("utilidad_id", 0);
      quotationForm.setFieldValue("utilidad_code", "");
      quotationForm.setFieldValue("utilidad_value", 0);
    }
  };

  const changeIndirectCostsValue = (values: any) => {
    const { floatValue } = values;
    let currentValue = floatValue ? floatValue : 0;

    quotationForm.setFieldValue("costo_indirecto_value", currentValue);
    const costo_indirecto_code = quotationForm.values.costo_indirecto_code;
    let costo_indirecto =
      costo_indirecto_code === "TCPOR"
        ? subtotal * (Number(currentValue) / 100)
        : Number(currentValue);
    setCostoIndirecto(costo_indirecto || 0);
  };
  const changeUtilityValue = (values: any) => {
    const { floatValue } = values;
    let currentValue = floatValue ? floatValue : 0;

    quotationForm.setFieldValue("utilidad_value", currentValue);
    const utilidad_code = quotationForm.values.utilidad_code;
    let utilidad =
      utilidad_code === "TCPOR"
        ? subtotal * (Number(currentValue) / 100)
        : Number(currentValue);
    setUtilidad(utilidad || 0);
  };

  // EVENTO QUE EJECUTA CUANDO SE CAMBIA ALGUNA PROPIEDAD EN EL CONCEPTO
  const updatePriceConcepts = (
    subtotal: number,
    tax: number,
    concepts: any,
    indexSection: number
  ) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[indexSection] = {
        ...updatedSections[indexSection],
        subtotal: Number(subtotal.toFixed(4)),
        tax: Number(tax.toFixed(4)),
        detalle: concepts,
      };
      return updatedSections;
    });
  };

  const sectionRemove = (indexToRemove: number) => {
    setSections((prevSections) =>
      prevSections.filter((_, index) => index !== indexToRemove)
    );
  };

  const calculateSubtotalImporte = useCallback(() => {
    let subtotal: number = 0;
    sections.forEach((section) => {
      section.detalle.forEach((concept) => {
        subtotal += Number(concept.importe_sin_impuesto_conversion);
      });
    });
    setSubtotal(subtotal);

    if (discountTotalMemo) {
      let { code, value, tipo_moneda } = discountTotalMemo;

      let discount =
        code === "TCPOR"
          ? subtotal * Number(value)
          : calcularRecargoDescuento(Number(value), tipo_moneda);
      setDiscountTotal(discount);
    }
    if (surchargeTotalMemo) {
      let { code, value, tipo_moneda } = surchargeTotalMemo;

      let surcharge =
        code === "TCPOR"
          ? subtotal * Number(value)
          : calcularRecargoDescuento(Number(value), tipo_moneda);
      setSurchargeTotal(surcharge);
    }

    const {
      utilidad_code,
      utilidad_value,
      costo_indirecto_value,
      costo_indirecto_code,
    } = quotationForm.values;
    if (utilidad_code) {
      let utilidad =
        utilidad_code === "TCPOR"
          ? subtotal * Number(utilidad_value)
          : Number(utilidad_value);
      setUtilidad(utilidad || 0);
    }
    if (costo_indirecto_code) {
      let costo_indirecto =
        costo_indirecto_code === "TCPOR"
          ? subtotal * Number(costo_indirecto_value)
          : Number(costo_indirecto_value);
      setCostoIndirecto(costo_indirecto || 0);
    }
  }, [sections]);

  const calculateTaxImporte = useCallback(() => {
    let tax: number = 0;
    sections.forEach((section) => {
      section.detalle.forEach((concept) => {
        tax += Number(concept.importe_impuesto_conversion);
      });
    });
    setTaxTotal(tax);
  }, [sections]);

  useEffect(() => {
    calculateSubtotalImporte();
    calculateTaxImporte();
  }, [sections, calculateSubtotalImporte, calculateTaxImporte]);

  const calculateTotal = (
    subtotal: number,
    surcharge: number,
    discount: number,
    tax: number,
    utilidad: number,
    costo_indirecto: number
  ) => {
    let total = subtotal + surcharge;
    total -= discount;
    total += tax;
    total += utilidad;
    total += costo_indirecto;
    return total;
  };

  const addSection = () => {
    const newSection: SectionModel = {
      orden: sections.length + 1,
      nombre: `Título de sección ${sections.length + 1}`,
      subtotal: 0,
      tax: 0,
      detalle: [],
      updatePriceConcepts: updatePriceConcepts,
    };
    setSections([...sections, newSection]);
    SimpleAlert(
      `Se agregó la sección ${sections.length + 1}.`,
      3000,
      "success"
    );
  };

  const updateSectionTitle = (index: number, newTitle: string) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[index] = {
        ...updatedSections[index],
        nombre: newTitle,
      };
      return updatedSections;
    });
  };
  const handleTitleBlur = (index: number) => {
    if (sections[index].nombre.trim() === "") {
      setSections((prevSections) => {
        const updatedSections = [...prevSections];
        updatedSections[index] = {
          ...updatedSections[index],
          nombre: `Título de sección ${index + 1}`,
        };
        return updatedSections;
      });
    }
  };

  const generateVersion = () => {
    Swal.fire({
      title: "¿Desea hacer una versión?",
      html: `
      <div className="text-gray-600">
        Generará la nueva versión con los cambios realizados en la cotización actual.
      </div>
      `,
      showCancelButton: true,
      padding: "2em",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Aceptar",
      reverseButtons: true,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-light me-3",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setIsNewVersion(true);
        quotationForm.submitForm();
      }
    });
  };

  const eliminarCotizacion = (id: number) => {
    Swal.fire({
      title: "¿Desea eliminar la cotización?",
      html: `
      <div className="text-gray-600">
        Si confirma esta acción no podrá recuperar la información de esta cotización.
      </div>
      `,
      showCancelButton: true,
      padding: "2em",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Aceptar",
      reverseButtons: true,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-light me-3",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setIsDeleteVersion(false);
        eliminar(id);
      }
    });
  };

  const eliminarVersion = (id: number) => {
    Swal.fire({
      title: "¿Desea eliminar la versión?",
      html: `
      <div className="text-gray-600">
        Si confirma esta acción no podrá recuperar la información de esta versión.
      </div>
      `,
      showCancelButton: true,
      padding: "2em",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Aceptar",
      reverseButtons: true,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-light me-3",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setIsDeleteVersion(true);
        eliminar(id);
      }
    });
  };

  const actualizarPrecios = () => {
    Swal.fire({
      title: "¿Desea actualizar la cotización?",
      html: `
      <div className="text-gray-600">
        Si confirma esta acción se actualizarán los precios de los conceptos.
      </div>
      `,
      showCancelButton: true,
      padding: "2em",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Aceptar",
      reverseButtons: true,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-light me-3",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        actualizar(id);
      }
    });
  };

  useEffect(() => {
    if (OportunyData) {
      const { id, cuenta_id, contacto, sub_cuenta_id } = OportunyData;

      quotationForm.setFieldValue("oportunidad_id", id);

      if (account && cuenta_id) {
        const accToOpp = account.filter((item) => item.id === cuenta_id);
        setSelectedOptionAccount(accToOpp);
        quotationForm.setFieldValue("cuenta_id", cuenta_id);
        setAccountId(cuenta_id || 0);
      }
      if (subaccount && sub_cuenta_id) {
        const subAccToOpp = subaccount.filter(
          (item) => item.id === sub_cuenta_id
        );
        setSelectedOptionSubaccount(subAccToOpp);
        quotationForm.setFieldValue("subcuenta_id", sub_cuenta_id);
        setSubaccountId(sub_cuenta_id || 0);
        setSubAccOpp(sub_cuenta_id || 0);
      }
      if (contacts && contacto) {
        const { id } = contacto;
        const custToOpp = contacts.filter((item) => item.value === id);
        setSelectedOptionContact(custToOpp);
        quotationForm.setFieldValue("contacto_id", id);
        setCustomerOpp(id || 0);
      }
    }
  }, [OportunyData, account, contacts, subaccount]);

  // const prevStep = () => {
  //   console.log(stepper.current);
  //   if (!stepper.current) {
  //     return;
  //   }

  //   stepper.current?.goPrev();
  //   // setCurrentSchema(stepper.current.currentStepIndex - 1);
  //   switch (stepper.current.currentStepIndex - 1) {
  //     case 1:
  //       setTextStepper("Continuar");
  //       break;
  //     case 2:
  //       setTextStepper("Continuar");
  //       break;
  //     default:
  //       setTextStepper("Continuar");
  //       break;
  //   }
  // };

  return (
    <>
      <form
        id="kt_account_form"
        className="form"
        action="#"
        noValidate
        onSubmit={quotationForm.handleSubmit}
      >
        <div className="card mb-6 mb-xl-9">
          <div className="card-body">
            <div className="row">
              <div className="col-12 p-0">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                  <div className="flex-grow-1">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 p-0">
                        <div className="w-100">
                          <input
                            className="form-control mb-2 w-100 border-0"
                            style={{
                              width: "100%",
                              padding: "15px",
                              fontSize: "24px",
                              boxSizing: "border-box",
                              marginBottom: "20px",
                            }}
                            data-kt-element="input"
                            placeholder="Nombre *"
                            {...quotationForm.getFieldProps("nombre")}
                          ></input>
                          {quotationForm.touched.nombre &&
                            quotationForm.errors.nombre && (
                              <div className="fv-plugins-message-container text-danger ms-5 w-100 mb-3">
                                <span role="alert">
                                  {quotationForm.errors.nombre}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4 p-0">
                        <div className="d-flex justify-content-center ">
                          <div
                            className="w-75 border border-gray-300 rounded mt-8 d-flex justify-content-center align-items-center"
                            style={{ height: "64px" }}
                          >
                            <div className="text-center">
                              <span
                                className="fs-2x fw-bold"
                                style={{ color: "#1B84FF" }}
                              >
                                <h6> Precio total c/impuestos</h6>
                                <CountUp
                                  end={calculateTotal(
                                    subtotal,
                                    surchargeTotal,
                                    discountTotal,
                                    taxTotal,
                                    utilidad,
                                    costoIndirecto
                                  )}
                                  duration={1}
                                  prefix="$"
                                  decimals={2}
                                />
                              </span>

                              <span className="fs-7 fw-semibold opacity-50">
                                {codeInter && (
                                  <>
                                    /{" "}
                                    <span data-kt-element="period">
                                      {codeInter}
                                    </span>
                                  </>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {id > 0 && (
                    <>
                      <div className="flex-grow-2">
                        <div className="d-flex mb-4 justify-content-end me-8">
                          <div className="d-flex justify-content-end flex-shrink-0">
                            <a
                              href="#/"
                              data-kt-menu="true"
                              className="btn btn-sm btn-icon btn-light-primary "
                              data-kt-menu-trigger="hover"
                              data-kt-menu-placement="bottom-end"
                            >
                              <KTSVG
                                path="/media/icons/duotune/general/gen052.svg"
                                className="svg-icon-5 svg-icon-gray-500"
                              />
                            </a>
                            <div
                              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                              data-kt-menu="true"
                            >
                              {/* <div className="menu-item px-3">
                                <Link
                                  className="menu-link px-3"
                                  to={`/cotizaciones/pdf/${encryptId(
                                    id.toString()
                                  )}`}
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/general/pdf-ic.svg"
                                    className="svg-icon-5 svg-icon-gray-500 mr-05"
                                  />
                                  Generar PDF
                                </Link>
                              </div>
                              <div className="menu-item px-3">
                                <div
                                  className="menu-link px-3"
                                  onClick={() => {
                                    SimpleAlert(
                                      'Se generó la plantilla exitosamente.',
                                      3000,
                                      'success'
                                    );
                                  }}
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/layouts/lay009.svg"
                                    className="svg-icon-5 svg-icon-gray-500 mr-05"
                                  />
                                  Generar plantilla
                                </div>
                              </div>
                              <div className="menu-item px-3">
                                <div
                                  className="menu-link px-3"
                                  onClick={generateVersion}
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/general/gen028.svg"
                                    className="svg-icon-5 svg-icon-gray-500 mr-05"
                                  />
                                  Hacer una versión
                                </div>
                              </div> */}
                              <div className="menu-item px-3">
                                <div
                                  className="menu-link px-3"
                                  onClick={() => {
                                    eliminarCotizacion(id);
                                  }}
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/general/gen027.svg"
                                    className="svg-icon-5 svg-icon-gray-500 mr-05"
                                  />
                                  Borrar
                                </div>
                              </div>
                              <div className="menu-item px-3">
                                <div
                                  className="menu-link px-3"
                                  onClick={actualizarPrecios}
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/files/fil011.svg"
                                    className="svg-icon-5 svg-icon-gray-500 mr-05"
                                  />
                                  Actualizar precios
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">
                      Unidad de negocio
                    </label>
                  </div>
                  <Select
                    value={
                      quotationForm.values.unidad_negocio_id
                        ? unitMeasure.find(
                            (option: any) =>
                              option.value ===
                              quotationForm.values.unidad_negocio_id
                          )
                        : null
                    }
                    options={unitMeasure}
                    styles={styleSelect}
                    placeholder={"Seleccione"}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{"No se encontraron registros"}</span>;
                    }}
                    onChange={changeUnitMeasurement}
                    isLoading={loadingUM}
                    isClearable
                    backspaceRemovesValue
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto required">Cuenta</label>
                  </div>
                  <Select
                    isDisabled={oportunityId > 0}
                    options={account}
                    value={selectedOptionAccount}
                    styles={styleSelectPackages}
                    placeholder={"Seleccione"}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{"No se encontraron registros"}</span>;
                    }}
                    onChange={changeAccount}
                    isLoading={loadingAccount}
                    backspaceRemovesValue
                  />
                  {quotationForm.touched.cuenta_id &&
                    quotationForm.errors.cuenta_id && (
                      <div className="fv-plugins-message-container text-danger w-100 mt-3">
                        <span role="alert">
                          {quotationForm.errors.cuenta_id}
                        </span>
                      </div>
                    )}
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Subcuenta</label>
                  </div>
                  <Select
                    isDisabled={
                      quotationForm.values.cuenta_id <= 0 ||
                      (oportunityId > 0 && subAccOpp > 0)
                    }
                    options={subaccount}
                    styles={styleSelectPackages}
                    placeholder={"Seleccione"}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{"No se encontraron registros"}</span>;
                    }}
                    onChange={changeSubaccount}
                    value={selectedOptionSubaccount}
                    isLoading={loadingSA}
                    backspaceRemovesValue
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Contacto</label>
                  </div>
                  <Select
                    isDisabled={oportunityId > 0 && customerOpp > 0}
                    value={selectedOptionContact}
                    options={contacts}
                    styles={styleSelect}
                    placeholder={"Seleccione"}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{"No se encontraron registros"}</span>;
                    }}
                    onChange={changeContact}
                    // isLoading={loadingUM}
                    isClearable
                    backspaceRemovesValue
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required form-label w-auto">
                      Tipo de cotización
                    </label>
                  </div>
                  <Select
                    value={
                      quotationForm.values.tipo_id
                        ? quotationType.find(
                            (option) =>
                              option.value === quotationForm.values.tipo_id
                          )
                        : null
                    }
                    options={quotationType}
                    styles={styleSelect}
                    placeholder={"Seleccione"}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{"No se encontraron registros"}</span>;
                    }}
                    onChange={changeQuoType}
                    isLoading={loadingQT}
                    isClearable
                    backspaceRemovesValue
                  />
                  {quotationForm.touched.tipo_id &&
                    quotationForm.errors.tipo_id && (
                      <div className="fv-plugins-message-container text-danger w-100 mt-3">
                        <span role="alert">{quotationForm.errors.tipo_id}</span>
                      </div>
                    )}
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Tipo de moneda</label>
                  </div>
                  <Select
                    options={currencyUnit}
                    value={
                      quotationForm.values.unidad_moneda_id
                        ? currencyUnit.find(
                            (option) =>
                              option.value ===
                              quotationForm.values.unidad_moneda_id
                          )
                        : null
                    }
                    styles={styleSelect}
                    placeholder={"Seleccione"}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{"No se encontraron registros"}</span>;
                    }}
                    onChange={changeCurrencyUnit}
                    isLoading={loadingCU}
                    backspaceRemovesValue
                  />
                  {quotationForm.touched.unidad_moneda_id &&
                    quotationForm.errors.unidad_moneda_id && (
                      <div className="fv-plugins-message-container text-danger w-100 mt-3">
                        <span role="alert">
                          {quotationForm.errors.unidad_moneda_id}
                        </span>
                      </div>
                    )}
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Asignado a</label>
                  </div>
                  <Select
                    options={sellerData}
                    styles={{
                      placeholder: (provided: any, state: any) => ({
                        ...provided,
                        color: "#181c32",
                        textAlign: "center",
                      }),
                      indicatorSeparator: (provided: any, state: any) => ({
                        ...provided,
                        display: "none",
                      }),
                      dropdownIndicator: (provided: any, state: any) => ({
                        ...provided,
                        color: "#9096EC",
                        display: "none",
                      }),
                      control: (provided: any, state: any) => ({
                        ...provided,
                        border: "none",
                        borderRadius: "6px",
                        padding: "3px",
                        fontSize: "1rem",
                        boxShadow: "none",
                        backgroundColor: state.isDisabled ? "#EFF2F5" : "white",
                      }),
                      singleValue: (provided: any, state: any) => ({
                        ...provided,
                        color: state.isDisabled ? "#181c32" : "inherit",
                        fontWeight: 500,
                      }),
                      option: (provided: any, state: any) => ({
                        ...provided,
                        fontSize: "1.0rem",
                        textAlign: "center",
                      }),
                    }}
                    formatOptionLabel={(option) => (
                      <div
                        className="d-flex"
                        style={{ alignItems: "center", width: "100%" }}
                      >
                        <div className="symbol symbol-30px">
                          <span
                            className="symbol-label fs-7 fw-bold bg-light-primary text-primary fw-bold"
                            title={option.label}
                          >
                            {option?.nombre[0]}
                            {option?.apellidoP[0]}
                          </span>
                        </div>
                        <span className="ms-3 ">
                          {option.nombre} {option.apellidoP}
                        </span>
                      </div>
                    )}
                    placeholder={
                      <span className="symbol symbol-30px cursor-pointer">
                        <div className="symbol-label">
                          <KTSVG
                            path="/media/icons/duotune/communication/com006.svg"
                            className="svg-icon-1"
                          />
                        </div>
                      </span>
                    }
                    isSearchable={false}
                    noOptionsMessage={() => {
                      return <span>{"No se encontraron registros"}</span>;
                    }}
                    onChange={changeSeller}
                    value={selectedOptionSeller}
                    isClearable
                    backspaceRemovesValue
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className=" form-label w-auto">
                      Fecha de elaboración
                    </label>
                  </div>
                  <div className="text-gray-700 mt-2">
                    {formatDateTime(dateNow)}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className=" form-label w-auto">Versión</label>
                  </div>
                  <div className={`mt-2 badge badge-light-success`}>
                    {quotationForm.values.codigo_estatus !== "COTFN"
                      ? quotationForm.values.version || "V1"
                      : "Finalizada"}
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3 mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">
                      Última actualización
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3 mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Fecha de envío</label>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-12 mt-5">
                <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap ms-2 ">
                  <li className="nav-item cursor-pointer ">
                    <div className={`nav-link me-6 text-active-primary active`}>
                      <span className="margin-left-05">Cotización</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-6 mb-xl-9">
          <div
            className="card-header cursor-pointer"
            onClick={() => {
              setOpenSectionInfoGeneral(!openSectionInfoGeneral);
              setOpenSectionQuo(false);
              setOpenSectionTotals(false);
            }}
            aria-controls="collapseGeneral"
            // aria-expanded={openSection}
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Datos Generales</h3>
            </div>
            <div className="d-flex align-items-center">
              <div
                className={`align-self-center`}
                style={{
                  transform: openSectionInfoGeneral
                    ? "translateX(10%) rotate(-180deg)"
                    : "",
                  transition: "transform 0.3s ease",
                }}
              >
                <KTSVG
                  path={"/media/icons/duotune/arrows/arr072.svg"}
                  className={`svg-icon-1`}
                />
              </div>
            </div>
          </div>
          <Collapse in={openSectionInfoGeneral}>
            <div id="collapseGeneral">
              <div className="card-body border-top p-9">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                    <div
                      className="w-100 pe-5 h-100"
                      style={{ borderRight: "2px solid #ECECEC" }}
                    >
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">
                          Tipo de ajuste
                        </label>
                      </div>
                      <div className="fv-row mt-3">
                        {settingsType.map((item, index) => (
                          <div
                            className="form-check form-check-custom form-check-solid mt-4"
                            key={item.id}
                          >
                            <input
                              className="form-check-input me-3"
                              type="radio"
                              id={`radio-${item.id}`}
                              value={item.id}
                              name="settings"
                              onChange={(event) => {
                                const settingsTypeId =
                                  event.currentTarget.value;

                                if (settingsTypeId) {
                                  quotationForm.setFieldValue(
                                    `tipo_ajuste_id`,
                                    settingsTypeId
                                  );
                                  quotationForm.setFieldValue(
                                    `tipo_ajuste_code`,
                                    item.code
                                  );
                                }
                              }}
                              checked={
                                Number(item.id) ===
                                Number(quotationForm.values.tipo_ajuste_id)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`radio-${item.id}`}
                            >
                              <div className="text-gray-700">{item.name}</div>
                            </label>
                          </div>
                        ))}
                        <div className="form-check form-check-custom form-check-solid mt-4">
                          <input
                            className="form-check-input me-3"
                            disabled={true}
                            type="radio"
                            name="settings"
                            onChange={(event) => {}}
                            checked={false}
                          />
                          <label className="form-check-label">
                            <div className="text-gray-700">
                              Cotizar precio compra
                            </div>
                          </label>
                        </div>
                      </div>
                      {quotationForm.touched.tipo_ajuste_id &&
                        quotationForm.errors.tipo_ajuste_id && (
                          <div className="fv-plugins-message-container text-danger w-100 mt-3">
                            <span role="alert">
                              {quotationForm.errors.tipo_ajuste_id}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                    <div
                      className="w-100 pe-5"
                      style={{ borderRight: "2px solid #ECECEC" }}
                    >
                      <div className="w-100 d-flex">
                        <label className="form-label w-auto">
                          Recargo / Descuento{" "}
                        </label>
                      </div>
                      <div className="mt-3">
                        <div className="w-75">
                          <Select
                            value={
                              quotationForm.values.recargo_id
                                ? surcharge.find(
                                    (option) =>
                                      option.value ===
                                      quotationForm.values.recargo_id
                                  )
                                : null
                            }
                            options={surcharge}
                            styles={styleSelectQuo}
                            placeholder={"Seleccione"}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{"No se encontraron registros"}</span>
                              );
                            }}
                            onChange={changeSurcharge}
                            isLoading={loadingSUR}
                            isClearable
                            backspaceRemovesValue
                          />
                        </div>
                        <div className="mt-2 w-75">
                          <Select
                            value={
                              quotationForm.values.descuento_id
                                ? discount.find(
                                    (option) =>
                                      option.value ===
                                      quotationForm.values.descuento_id
                                  )
                                : null
                            }
                            options={discount}
                            styles={styleSelectQuo}
                            placeholder={"Seleccione"}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{"No se encontraron registros"}</span>
                              );
                            }}
                            onChange={changeDiscount}
                            isLoading={loadingDIS}
                            isClearable
                            backspaceRemovesValue
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="form-label w-auto">
                          Costo indirecto
                        </label>
                      </div>
                      <div className="mt-3">
                        <div className="w-75">
                          <Select
                            value={
                              quotationForm.values.costo_indirecto_id
                                ? indirectCosts.find(
                                    (option) =>
                                      option.value ===
                                      quotationForm.values.costo_indirecto_id
                                  )
                                : null
                            }
                            options={indirectCosts}
                            styles={styleSelectQuo}
                            placeholder={"Seleccione"}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{"No se encontraron registros"}</span>
                              );
                            }}
                            onChange={changeIndirectCosts}
                            isClearable
                            isDisabled={
                              quotationForm.values.tipo_ajuste_code !== "COPC"
                            }
                            isLoading={loadingIC}
                            backspaceRemovesValue
                          />
                        </div>
                        <div className="mt-2 w-75">
                          <NumericFormat
                            type="text"
                            autoComplete="off"
                            className="form-control mb-3"
                            value={quotationForm.values.costo_indirecto_value}
                            // {...quotationForm.getFieldProps(
                            //   'costo_indirecto_value'
                            // )}
                            decimalScale={2}
                            decimalSeparator="."
                            fixedDecimalScale
                            placeholder={
                              quotationForm.values.costo_indirecto_code ===
                              "TCPOR"
                                ? "0.00 %"
                                : "$ 0.00"
                            }
                            disabled={
                              quotationForm.values.costo_indirecto_code === ""
                                ? true
                                : false
                            }
                            suffix={
                              quotationForm.values.costo_indirecto_code ===
                              "TCPOR"
                                ? " %"
                                : ""
                            }
                            isAllowed={(values: any) => {
                              const { floatValue } = values;
                              let allow = false;
                              if (
                                quotationForm.values.costo_indirecto_code ===
                                "TCPOR"
                              ) {
                                allow =
                                  floatValue <= 100 || floatValue === undefined;
                              } else {
                                const integerPart = Math.floor(floatValue);
                                allow = integerPart.toString().length <= 9;
                              }
                              return allow;
                            }}
                            prefix={
                              quotationForm.values.costo_indirecto_code ===
                              "TCPOR"
                                ? ""
                                : "$ "
                            }
                            allowNegative={false}
                            thousandSeparator={
                              quotationForm.values.costo_indirecto_code ===
                              "TCPOR"
                                ? ""
                                : ","
                            }
                            onValueChange={changeIndirectCostsValue}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="form-label w-auto">Utilidad</label>
                      </div>
                      <div className="mt-3">
                        <div className="w-75">
                          <Select
                            value={
                              quotationForm.values.utilidad_id
                                ? utility.find(
                                    (option) =>
                                      option.value ===
                                      quotationForm.values.utilidad_id
                                  )
                                : null
                            }
                            options={utility}
                            isDisabled={true}
                            styles={styleSelectQuo}
                            placeholder={"Seleccione"}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{"No se encontraron registros"}</span>
                              );
                            }}
                            onChange={changeUtility}
                            isClearable
                            isLoading={loadingUTI}
                            backspaceRemovesValue
                          />
                        </div>
                        <div className="mt-2 w-75">
                          <NumericFormat
                            type="text"
                            autoComplete="off"
                            className="form-control mb-3"
                            value={quotationForm.values.utilidad_value}
                            decimalScale={0}
                            decimalSeparator="."
                            fixedDecimalScale
                            placeholder={
                              quotationForm.values.utilidad_code === "TCPOR"
                                ? "0 %"
                                : `$ 0.00 ${codeInter}`
                            }
                            disabled={
                              quotationForm.values.utilidad_code === ""
                                ? true
                                : false
                            }
                            suffix={
                              quotationForm.values.utilidad_code === "TCPOR"
                                ? " %"
                                : ` ${codeInter}`
                            }
                            isAllowed={(values: any) => {
                              const { floatValue } = values;
                              let allow = false;
                              if (
                                quotationForm.values.utilidad_code === "TCPOR"
                              ) {
                                allow =
                                  floatValue <= 100 || floatValue === undefined;
                              } else {
                                // allow = true;
                                const integerPart = Math.floor(floatValue);
                                allow = integerPart.toString().length <= 9;
                              }
                              return allow;
                            }}
                            prefix={
                              quotationForm.values.utilidad_code === "TCPOR"
                                ? ""
                                : "$ "
                            }
                            allowNegative={false}
                            thousandSeparator={
                              quotationForm.values.utilidad_code === "TCPOR"
                                ? ""
                                : ","
                            }
                            onValueChange={changeUtilityValue}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        <div className="d-flex align-items-center">
          <div
            className="btn btn-primary btn-sm mb-10 ms-auto"
            onClick={addSection}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr075.svg"
              className="svg-icon-5"
            />
            Agregar Sección
          </div>
        </div>
        <SectionsList
          sections={sections}
          updateCostConcepts={updateCostConcepts}
          codeInter={codeInter}
          exchangeRate={exchangeRate}
          updateSectionTitle={updateSectionTitle}
          handleTitleBlur={handleTitleBlur}
          removeSection={sectionRemove}
        ></SectionsList>

        {/* <div className="card mb-6 mb-xl-9">
          <div
            className="card-header cursor-pointer"
            onClick={() => {
              setOpenSectionQuo(!openSectionQuo);
              setOpenSectionTotals(false);
              setOpenSectionInfoGeneral(false);
            }}
            aria-controls="collapseQuo"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Sección 1</h3>
            </div>
            <div className="d-flex align-items-center">
              <div className="text-center me-5">
                <span className="fs-1 fw-bold" style={{ color: '#1B84FF' }}>
                  <CountUp
                    end={subtotal}
                    duration={1}
                    prefix="$"
                    decimals={2}
                  />
                </span>

                <span className="fs-7 fw-semibold opacity-50">
                  {codeInter && (
                    <>
                      / <span data-kt-element="period">{codeInter}</span>
                    </>
                  )}
                </span>
              </div>

              <div
                className={`align-self-center`}
                style={{
                  transform: openSectionQuo
                    ? 'translateX(10%) rotate(-180deg)'
                    : '',
                  transition: 'transform 0.3s ease',
                }}
              >
                <KTSVG
                  path={'/media/icons/duotune/arrows/arr072.svg'}
                  className={`svg-icon-1`}
                />
              </div>
            </div>
          </div>
          <Collapse in={openSectionQuo}>
            <div id="collapseQuo">
              <div className="card-body border-top p-9">
                <ConceptsList
                  data={conceptsList}
                  onChangePriceConcepts={updatePriceConcepts}
                  codeCurrency={codeInter}
                  exchangeRate={exchangeRate}
                  updateCostConcepts={updateCostConcepts}
                />
              </div>
            </div>
          </Collapse>
        </div> */}
        <div className="card mb-6 mb-xl-9">
          <div
            className="card-header cursor-pointer"
            onClick={() => {
              setOpenSectionTotals(!openSectionTotals);
              setOpenSectionQuo(false);
              setOpenSectionInfoGeneral(false);
            }}
            aria-controls="collapseTotals"
            // aria-expanded={openSectionTotals}
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Totales</h3>
            </div>
            <div className="d-flex align-items-center">
              <div className="text-center me-5">
                <span className="fs-1 fw-bold" style={{ color: "#1B84FF" }}>
                  <CountUp
                    end={calculateTotal(
                      subtotal,
                      surchargeTotal,
                      discountTotal,
                      taxTotal,
                      utilidad,
                      costoIndirecto
                    )}
                    duration={1}
                    prefix="$"
                    decimals={2}
                  />
                </span>

                <span className="fs-7 fw-semibold opacity-50">
                  {codeInter && (
                    <>
                      / <span data-kt-element="period">{codeInter}</span>
                    </>
                  )}
                </span>
              </div>
              <div
                className={`align-self-center`}
                style={{
                  transform: openSectionTotals
                    ? "translateX(10%) rotate(-180deg)"
                    : "",
                  transition: "transform 0.3s ease",
                }}
              >
                <KTSVG
                  path={"/media/icons/duotune/arrows/arr072.svg"}
                  className={`svg-icon-1`}
                />
              </div>
            </div>
          </div>
          <Collapse in={openSectionTotals}>
            <div id="collapseTotals">
              <div className="card-body border-top p-9">
                <div className="d-flex justify-content-end">
                  <div className="w-300px">
                    <div className="d-flex flex-stack mb-3">
                      <div className="fw-semibold pe-10 text-gray-600 fs-7">
                        Subtotal:
                      </div>

                      <div className="text-end fw-bold fs-6 text-gray-800">
                        {`${formatPrice(subtotal, "es-MX", "MXN", 2, 2)}`}
                      </div>
                    </div>

                    <div className="d-flex flex-stack mb-3">
                      <div className="fw-semibold pe-10 text-gray-600 fs-7">
                        Recargo:
                      </div>

                      <div className="text-end fw-bold fs-6 text-gray-800">
                        {`${formatPrice(surchargeTotal, "es-MX", "MXN", 2, 2)}`}
                      </div>
                    </div>

                    <div className="d-flex flex-stack mb-3">
                      <div className="fw-semibold pe-10 text-gray-600 fs-7">
                        Descuento:
                      </div>

                      <div className="text-end fw-bold fs-6 text-gray-800">
                        {`${formatPrice(discountTotal, "es-MX", "MXN", 2, 2)}`}
                      </div>
                    </div>

                    <div className="d-flex flex-stack mb-3">
                      <div className="fw-semibold pe-10 text-gray-600 fs-7">
                        Indirectos:
                      </div>

                      <div className="text-end fw-bold fs-6 text-gray-800">
                        {`${formatPrice(costoIndirecto, "es-MX", "MXN", 2, 2)}`}
                      </div>
                    </div>

                    <div className="d-flex flex-stack mb-3">
                      <div className="fw-semibold pe-10 text-gray-600 fs-7">
                        Utilidad:
                      </div>

                      <div className="text-end fw-bold fs-6 text-gray-800">
                        {`${formatPrice(utilidad, "es-MX", "MXN", 2, 2)}`}
                      </div>
                    </div>

                    <div className="d-flex flex-stack mb-3">
                      <div className="fw-semibold pe-10 text-gray-600 fs-7">
                        Impuestos:
                      </div>

                      <div className="text-end fw-bold fs-6 text-gray-800">
                        {`${formatPrice(taxTotal, "es-MX", "MXN", 2, 2)}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>

        <div className="card mb-6 mb-xl-9">
          <div className="card-body">
            <div className="row">
              <div className="d-flex justify-content-end">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                  onClick={() => {
                    history.push(`/cotizaciones/cotizaciones`);
                    // history.goBack();
                  }}
                >
                  Cancelar
                </button>

                {quotationForm.values.codigo_estatus !== "COTFN" && (
                  <button
                    type="submit"
                    className="btn btn-primary me-3"
                    data-kt-users-modal-action="submit"
                    disabled={loading}
                  >
                    {!loading && (
                      <span className="indicator-label">Guardar</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Espere por favor...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                )}

                {quotationForm.values.codigo_estatus !== "COTFN" && id > 0 && (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        finish(id);
                      }}
                      disabled={loadingFinish}
                    >
                      {!loadingFinish && (
                        <span className="indicator-label">
                          Finalizar cotización
                        </span>
                      )}
                      {loadingFinish && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      {id && !loadingRequest ? (
        <div className="row g-6 g-xl-9" ref={sectionTabs}>
          <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
            <div className="card card-flush p-5">
              <div className="row mb-5">
                <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                  <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === "NOTE" ? "text-active-primary active" : ""
                        } `}
                        onClick={() => {
                          setTabs("NOTE");
                        }}
                      >
                        <span className="margin-left-05">Notas</span>
                      </div>
                    </li>
                    {/* <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'VER' ? 'text-active-primary active' : ''
                        } `}
                        onClick={() => {
                          setTabs('VER');
                        }}
                      >
                        <span className="margin-left-05">Versiones</span>
                      </div>
                    </li> */}
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === "DOC" ? "text-active-primary active" : ""
                        } `}
                        onClick={() => {
                          setTabs("DOC");
                        }}
                      >
                        <span className="margin-left-05">Documentos</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                {tabs === "NOTE" ? (
                  <NoteList
                    data={notes}
                    onOpenModal={openModal}
                    active={activeNewNote}
                    onOpenDetailModal={openDetailModal}
                  ></NoteList>
                ) : null}

                {/* {tabs === 'VER' ? (
                  <VersionList
                    data={versions}
                    eliminarVersion={eliminarVersion}
                  ></VersionList>
                ) : null} */}
                {tabs === "DOC" ? (
                  <>
                    <DocumentsList
                      documents={dataDocuments || []}
                      onRefreshData={() => {
                        setReloadDocuments(Math.random() * 100);
                        onRefreshHistory();
                      }}
                      id={id}
                      isEdit={false}
                    ></DocumentsList>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <CreateNoteModal
        show={showNoteModal}
        handleClose={(refresh) => {
          setShowNoteModal(!showNoteModal);
          if (refresh) {
            setReloadGridNotes(Math.random() * 40);
            onRefreshHistory();
          }
        }}
        cotizacion_id={Number(id)}
      ></CreateNoteModal>
      <CreateNoteDetailModal
        show={showNoteDetailModal}
        handleClose={(refresh) => {
          setShowNoteDetailModal(!showNoteDetailModal);
          onRefreshHistory();
        }}
        data={notes}
        id={id}
      ></CreateNoteDetailModal>
      <CreateCuentaModal
        show={isOpenAccountModal}
        sellers={sellerData}
        handleClose={(isNew: boolean, newCuenta: any) => {
          if (isNew) {
            setReloadAccount(Math.random() * 40);
            setSelectAccountAdding(true);
          } else {
            setSelectedOptionAccount(null);
            setAccountId(0);
            quotationForm.setFieldValue("cuenta_id", 0);
          }
          setIsOpenAccountModal(!isOpenAccountModal);
        }}
      ></CreateCuentaModal>
      <SubaccountModal
        show={isOpenSubaccountModal}
        handleClose={(isNew: boolean) => {
          if (isNew) {
            setReloadSubaccountList(Math.random() * 40);
            setSelectSubaccountAdding(true);
          } else {
            setSelectedOptionSubaccount(null);
            quotationForm.setFieldValue("subcuenta_id", 0);
          }
          setIsOpenSubaccountModal(!isOpenSubaccountModal);
        }}
        account={selectedOptionAccount}
      ></SubaccountModal>
      <CreateContactModal
        show={isOpenContactModal}
        cuenta={selectedOptionAccount && selectedOptionAccount.value}
        subcuenta={selectedOptionSubaccount && selectedOptionSubaccount.value}
        nameAcc={selectedOptionAccount && selectedOptionAccount.label}
        nameSubAcc={selectedOptionSubaccount && selectedOptionSubaccount.label}
        handleClose={(isNew: boolean, newContact: any) => {
          if (isNew) {
            setReloadContact(Math.random() * 40);
            setSelectContactAdding(true);
          } else {
            setSelectedOptionContact(null);
            quotationForm.setFieldValue("contacto_id", 0);
          }
          setIsOpenContactModal(!isOpenContactModal);
        }}
      ></CreateContactModal>

      <QuotationWizard show={showWizard}></QuotationWizard>
    </>
  );
};
export { QuotationForm };
