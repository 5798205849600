import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Collapse } from "react-bootstrap-v5";
import { useHistory } from "react-router-dom";
import CountUp from "react-countup";
import { useFormik } from "formik";
import Select from "react-select";
import {
  KTSVG,
  SimpleAlert,
  formatPrice,
  formatTotalSingle,
} from "../../../../_metronic/helpers";
import Swal from "sweetalert2";
import {
  useSelectCurrencyPackages,
  useSelectGeneric,
  useSelectGenericPlus,
} from "../../catalogos/generico/hooks/genericoHook";
import {
  styleSelect,
  styleSelectPackages,
} from "../../../../_metronic/helpers/SelectStyles";
import { ProductsList } from "./sections/ProductsList";
import {
  useCreateEditPackage,
  useCreatePackageCopy,
  useDeletePackageCurrent,
  useGetDocumentsByPackageId,
  useGetNoteByPackageId,
  useGetOne,
  useSelectCategories,
  useSelectSubcategories,
  useUpdateCostConcepts,
} from "../hooks/PackagesHook";
import { CategoryModal } from "./_modals/CategoryModal";
import { SubcategoryModal } from "./_modals/SubcategoryModal";
import { ServicesList } from "./sections/ServicesList";
import { GenericCatalogModel } from "../../../../_metronic/helpers/models/GenericModel";
import { NoteList } from "./sections/NoteList";
import { CreateNotePackageModal } from "./_modals/CreateNoteModal";
import { CreateNoteDetailModal } from "./_modals/CreateNoteDetailModal";
import { DocumentsList } from "./sections/DocumentsList";
import { useChangeTypeCurrency } from "../../products/hooks/ProductsHook";

type Props = {
  id: number;
  onRefreshHistory: () => void;
};
const packagesSchema = Yup.object().shape({
  nombre: Yup.string().required("Nombre del paquete es requerido."),
  unidad_moneda_id: Yup.number()
    .min(1, "Tipo de moneda es requerido")
    .required("Tipo de moneda es requerido"),
  clasif_paquete_id: Yup.number()
    .min(1, "Clasificación es requerido")
    .required("Clasificación es requerido"),
  categoria_id: Yup.number()
    .min(1, "Categoría es requerido")
    .required("Categoría es requerido"),
});

const PackagesForm: React.FC<Props> = ({ id, onRefreshHistory }) => {
  const history = useHistory();
  const sectionTabs = useRef<HTMLDivElement>(null);
  const [tabs, setTabs] = useState<string | "NOTE" | "DOC">("NOTE");
  const [activeNewNote, setActiveNewNote] = useState<boolean>(false);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [showNoteDetailModal, setShowNoteDetailModal] =
    useState<boolean>(false);
  const isOpenHistoryString = localStorage.getItem("isOpenHistory");
  const [currentId, setCurrentId] = useState<number>(0);
  const { initValues, loadingRequest } = useGetOne(Number(currentId));

  const [selectCategoryAdding, setSelectCategoryAdding] =
    useState<boolean>(false);
  const [selectSubcategoryAdding, setSelectSubcategoryAdding] =
    useState<boolean>(false);
  const [costOfProducts, setCostOfProducts] = useState<number>(0);
  const [taxOfProducts, setTaxOfProducts] = useState<number>(0);
  const [costOfServices, setCostOfServices] = useState<number>(0);
  const [taxOfServices, setTaxOfServices] = useState<number>(0);
  const [showAvailableBtn, setShowAvailableBtn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [codeInter, setCodeInter] = useState<string>("");
  const [currencySelected, setCurrencySelected] = useState({});
  const { change } = useChangeTypeCurrency(currencySelected);
  const [exchangeRate, setExchangeRate] = useState<number>(0);
  const [updateCostProductos, setUpdateCostProductos] = useState<number>(
    Math.random() * 40
  );
  const [updateCostServices, setUpdateCostServices] = useState<number>(
    Math.random() * 40
  );
  const [productsList, setProductsList] = useState<any[]>([]);
  const [servicesList, setServicesList] = useState<any[]>([]);

  const [openSection, setOpenSection] = useState<boolean>(false);
  const [openSectionService, setOpenSectionService] = useState<boolean>(false);
  const [openSectionTotals, setOpenSectionTotals] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>(
    process.env.PUBLIC_URL + "/media/avatars/photo.png"
  );
  const [categoryId, setCategoryId] = useState<number>(0);
  const [subCategoryId, setSubCategoryId] = useState<number>(0);
  const [unitMeasure, setUnitMeasure] = useState<any>();
  //

  const [isOpenCategoryModal, setIsOpenCategoryModal] =
    useState<boolean>(false);
  const [isOpenSubcategoryModal, setIsOpenSubcategoryModal] =
    useState<boolean>(false);

  const [selectedOptionCurrency, setSelectedOptionCurrency] = useState<
    any | null
  >(null);
  const [selectedOptionCategory, setSelectedOptionCategory] = useState<
    any | null
  >(null);
  const [selectedOptionSubcategory, setSelectedOptionSubcategory] = useState<
    any | null
  >(null);
  const { createEditPackages, loadingPackages, errorRequest } =
    useCreateEditPackage();

  const {
    createPackageCopy,
    loadingPackages: loadingCopy,
    errorRequest: errorRequestCopy,
  } = useCreatePackageCopy();
  const {
    deletePackageCurrent,
    loading: loadingDEL,
    errorRequest: errorRequestDEL,
    isSuccess,
  } = useDeletePackageCurrent();
  const {
    updateCostConceptsH,
    loadingPackages: loadingCC,
    errorRequest: errorRequestCC,
  } = useUpdateCostConcepts();

  const [reloadCategoryList, setReloadCategoryList] = useState<number>(
    Math.random() * 40
  );
  const [reloadSubcategoryList, setReloadSubcategoryList] = useState<number>(
    Math.random() * 40
  );

  const { catalog: PackagesClasification } = useSelectGeneric("CLPACK");
  const { catalog: PackagesType, loadingRequest: loadingPT } =
    useSelectGenericPlus("TPACK");
  const { catalog: unitMeasurement, loadingRequest: loadingUM } =
    useSelectGenericPlus("UNEG");
  const { catalog: currencyUnit, loadingRequest: loadingCU } =
    useSelectCurrencyPackages("UMON");
  const { catalog: categories, loadingRequest: loadingCategories } =
    useSelectCategories(reloadCategoryList);
  const { catalog: subcategory, loadingRequest: loadingSC } =
    useSelectSubcategories(categoryId, reloadSubcategoryList);

  // NOTAS
  const [reloadGridNotes, setReloadGridNotes] = useState<number>(
    Math.random() * 40
  );
  const { notes, loadingNote } = useGetNoteByPackageId(id, reloadGridNotes);

  // DOCUMENTS
  const [reloadDocuments, setReloadDocuments] = useState(Math.random() * 40);
  const { dataDocuments, loadingDocuments } = useGetDocumentsByPackageId(
    id,
    reloadDocuments
  );

  const packagesForm = useFormik({
    initialValues: initValues,
    validationSchema: packagesSchema,
    enableReinitialize: true,
    onSubmit: (values: any, { setSubmitting }) => {
      values.products = productsList.filter((item) => item.id !== 0);
      values.services = servicesList.filter((item) => item.id !== 0);

      const countList = productsList.length + servicesList.length;
      if (countList > 0) {
        setLoading(true);
        createEditPackages(values);
      } else {
        SimpleAlert(
          "Debe agregar un producto/servicio al paquete actual",
          3000,
          "error"
        );
        setLoading(false);
      }
    },
  });

  const changeCategory = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setSelectedOptionCategory(selectedOption);

      packagesForm.setFieldValue("sub_categoria_id", 0);
      setSelectedOptionSubcategory(null);
      setSelectSubcategoryAdding(false);
      if (value <= 1) {
        if (Number(value) === 0) {
          setIsOpenCategoryModal(!isOpenCategoryModal);
        }
      }
      packagesForm.setFieldValue("categoria_id", value);
      setCategoryId(value);
    } else {
      setCategoryId(0);
      packagesForm.setFieldValue("categoria_id", 0);
      packagesForm.setFieldValue("sub_categoria_id", 0);
      setSelectedOptionSubcategory(null);
    }
  };
  const changeSubcategory = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setSelectedOptionSubcategory(selectedOption);
      packagesForm.setFieldValue("sub_categoria_id", value);
      if (Number(value) === 0) {
        setIsOpenSubcategoryModal(!isOpenSubcategoryModal);
      }
    } else {
      packagesForm.setFieldValue("sub_categoria_id", 0);
      setSelectedOptionSubcategory(null);
    }
  };
  const changeUnitMeasurement = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      packagesForm.setFieldValue("unidad_negocio_id", value);
    } else {
      packagesForm.setFieldValue("unidad_negocio_id", 0);
    }
  };
  const changePackagesType = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      packagesForm.setFieldValue("tipo_paquete_id", value);
    } else {
      packagesForm.setFieldValue("tipo_paquete_id", 0);
    }
  };
  const changeCurrencyUnit = (selectedOption: any) => {
    if (selectedOption) {
      const {
        value,
        data: { code },
      } = selectedOption;
      setSelectedOptionCurrency(selectedOption);
      packagesForm.setFieldValue("unidad_moneda_id", value);
      setCodeInter(code);
      setUpdateCostProductos(Math.random() * 40);
      setUpdateCostServices(Math.random() * 40);
    } else {
      packagesForm.setFieldValue("unidad_moneda_id", 0);
      setCodeInter("");
    }
  };

  const updatePriceProducts = (total: number, tax: number, productos: any) => {
    const totalFormatt = formatTotalSingle(total, 2);
    setCostOfProducts(totalFormatt);
    setTaxOfProducts(formatTotalSingle(tax, 2));
    setProductsList(productos);
  };

  const updatePriceServices = (total: number, tax: number, services: any) => {
    const totalFormatt = formatTotalSingle(total, 2);
    setCostOfServices(totalFormatt);
    const t = formatTotalSingle(tax, 2);
    setTaxOfServices(Number(t));
    setServicesList(services);
  };

  // SECTIONS NOTES
  const openModal = (isOpen: boolean) => {
    setShowNoteModal(isOpen);
  };
  const openDetailModal = (isOpen: boolean) => {
    setShowNoteDetailModal(isOpen);
  };
  const generateCopy = (id: number) => {
    createPackageCopy(id);
  };
  const updateCostConcepts = (id: number) => {
    updateCostConceptsH(id);
  };
  const deletePackage = (id: number) => {
    Swal.fire({
      title: "¿Desea eliminar el paquete?",
      html: `
      <div className="text-gray-600">
        Una vez
        eliminado ya no se podrá modificar ningún dato de este
        paquete.
      </div>
      `,
      showCancelButton: true,
      padding: "2em",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Aceptar",
      reverseButtons: true,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-light me-3",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deletePackageCurrent(id);
      }
    });
  };

  useEffect(() => {
    if (id) {
      setCurrentId(id);
    } else {
      setCurrentId(0);
    }
  }, [id]);

  useEffect(() => {
    if (isSuccess) {
      history.goBack();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (unitMeasurement) {
      const filter = unitMeasurement.filter((item) => item.id !== 0);

      setUnitMeasure(filter);
    }
  }, [unitMeasurement]);

  useEffect(() => {
    const { id, unidad_moneda_id } = initValues;

    if (currencyUnit) {
      if (id <= 0) {
        let currencyUnitDefault = currencyUnit.filter(
          (item) => item.data.code === "MXN"
        );

        setSelectedOptionCurrency(currencyUnitDefault);
        changeCurrencyUnit(currencyUnitDefault[0]);
      } else {
        let currencyUnitEdit = currencyUnit.find(
          (option) => option.value === unidad_moneda_id
        );

        setSelectedOptionCurrency(currencyUnitEdit);
        changeCurrencyUnit(currencyUnitEdit);
      }
    }
  }, [initValues, currencyUnit]);

  useEffect(() => {
    if (loadingPackages) {
      if (errorRequest) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoading(false);
          history.goBack();
        }, 1000);
      }
    }
  }, [loadingPackages]);

  useEffect(() => {
    if (subCategoryId) {
      const currentSubCategory = subcategory.filter(
        (item: any) => item.id === subCategoryId
      );
      setSelectedOptionSubcategory(currentSubCategory);
    }
  }, [subCategoryId, subcategory]);

  useEffect(() => {
    if (!initValues.id && PackagesClasification) {
      const defaultValueClasf = PackagesClasification.filter(
        (item: GenericCatalogModel) => item.code == "CPFIJ"
      );
      if (defaultValueClasf.length) {
        const { id } = defaultValueClasf[0];
        packagesForm.setFieldValue(`clasif_paquete_id`, id);
      }
    }
  }, [initValues, PackagesClasification]);

  useEffect(() => {
    const {
      id,
      package_photo,
      categoria_id,
      sub_categoria_id,
      products,
      services,
    } = initValues;
    if (id > 0) {
      setPreviewImage(package_photo);
      // setProductsList(products);
      // setServicesList(services);
      // setUpdateCostProductos(Math.random() * 40);

      if (categoria_id) {
        setCategoryId(categoria_id);
        if (sub_categoria_id) {
          setSubCategoryId(sub_categoria_id);
        }
      }
    } else {
      const defaultaValueCat = categories.filter(
        (option: any) => option.data.codigo == "SNCAT"
      );

      if (defaultaValueCat.length) {
        const { id } = defaultaValueCat[0];
        packagesForm.setFieldValue(`categoria_id`, id);
      }
    }
  }, [initValues, categories]);

  useEffect(() => {
    if (initValues.existUpdatesProducts || initValues.existUpdatesServices) {
      setShowAvailableBtn(true);
    } else {
      setShowAvailableBtn(false);
    }
  }, [initValues]);

  useEffect(() => {
    if (selectCategoryAdding) {
      const lastPositionCategory = categories[categories.length - 1];
      setSelectedOptionCategory(lastPositionCategory);
      setCategoryId(lastPositionCategory.id);

      packagesForm.setFieldValue("categoria_id", lastPositionCategory.id);
    }
  }, [categories, selectCategoryAdding]);

  useEffect(() => {
    if (selectSubcategoryAdding) {
      const lastPositionSubcategory = subcategory[subcategory.length - 1];
      setSelectedOptionSubcategory(lastPositionSubcategory);
      packagesForm.setFieldValue(
        "sub_categoria_id",
        lastPositionSubcategory.id
      );
    }
  }, [subcategory]);

  useEffect(() => {
    if (currentId > 0) {
      const { products, services } = initValues;
      setProductsList(products);
      setServicesList(services);
    }
  }, [initValues, currentId]);

  useEffect(() => {
    if (change) {
      const {
        rates: {
          MXN: { rate },
        },
      } = change;

      setExchangeRate(Number(rate));
    }
  }, [change]);

  useEffect(() => {
    // Esta función de limpieza se ejecutará cuando el componente se desmonte
    return () => {
      setCurrentId(0);
    };
  }, []);
  return (
    <>
      <form
        id="kt_account_form"
        className="form"
        action="#"
        noValidate
        onSubmit={packagesForm.handleSubmit}
      >
        <div className="card mb-6 mb-xl-9">
          <div className="card-body">
            {showAvailableBtn ? (
              <>
                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-12 p-6">
                  <KTSVG
                    path="/media/icons/duotune/general/gen044.svg"
                    className="svg-icon-2tx svg-icon-primary me-4"
                  />

                  <div className="d-flex flex-stack flex-grow-1 ">
                    <div className=" fw-semibold">
                      <h4 className="text-gray-900 fw-bold">
                        ¡Actualización en el paquete!
                      </h4>

                      <div className="fs-6 text-gray-700 ">
                        Los datos de productos o servicios que contiene este
                        paquete han cambiado. ¿Te gustaría actualizar los datos
                        de los productos o servicios?
                      </div>
                      <div className="d-flex flex-center flex-wrap">
                        <button
                          className="btn btn-sm btn-primary ms-5"
                          type="button"
                          data-kt-element="send"
                          disabled={loadingCC}
                          onClick={() => {
                            updateCostConcepts(id);
                          }}
                        >
                          {!loadingCC && (
                            <span className="indicator-label-5">
                              Actualizar
                            </span>
                          )}
                          {loadingCC && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              Espere por favor...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                          {/* <span className="indicator-label-5">Actualizar</span> */}
                        </button>
                        <button
                          type="reset"
                          className="btn btn-light me-3 ms-4"
                          data-kt-users-modal-action="cancel"
                          onClick={() => {
                            setShowAvailableBtn(false);
                          }}
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <div className="row">
              <div className="col-12 p-0">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                  <div
                    className="image-input image-input-outline me-10"
                    data-kt-image-input="true"
                  >
                    <label htmlFor="imageUpload">
                      <img
                        src={previewImage ? previewImage : ""}
                        className="image-input-wrapper w-175px h-175px cursor-pointer"
                        crossOrigin="anonymous"
                        alt="Paquetes"
                      />
                    </label>

                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title="Cambiar imagen"
                    >
                      <i className="bi bi-pencil-fill fs-7"></i>
                      <input
                        type="file"
                        id="imageUpload"
                        name="img"
                        accept=".png, .jpg, .jpeg"
                        onChange={(event) => {
                          if (event.currentTarget.files) {
                            let file = event.currentTarget.files[0];
                            let reader = new FileReader();
                            reader.onload = () => {
                              const csv: string = reader.result as string;
                              setPreviewImage(csv);
                            };
                            reader.readAsDataURL(file);
                            packagesForm.setFieldValue("image", file);
                          }
                        }}
                      />
                    </label>
                  </div>
                  <div className="flex-grow-1">
                    <div className="row">
                      <div
                        className={`col-sm-12 col-md-12 col-lg-12  p-0 ${
                          isOpenHistoryString === "true"
                            ? "col-xl-12 col-xxl-12"
                            : "col-xl-7 col-xxl-7"
                        }`}
                      >
                        <div className="w-100">
                          <input
                            className="form-control mb-2 w-100 border-0"
                            style={{
                              width: "100%",
                              padding: "15px",
                              fontSize: "24px",
                              boxSizing: "border-box",
                              marginBottom: "20px",
                            }}
                            data-kt-element="input"
                            placeholder="Sin nombre *"
                            maxLength={40}
                            {...packagesForm.getFieldProps("nombre")}
                          ></input>
                          {packagesForm.touched.nombre &&
                            packagesForm.errors.nombre && (
                              <div className="fv-plugins-message-container text-danger  w-100 mb-3">
                                <span role="alert">
                                  {packagesForm.errors.nombre}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                      <div
                        className={`col-sm-12 col-md-12 col-lg-12  p-0 ${
                          isOpenHistoryString === "true"
                            ? "col-xl-12 col-xxl-12"
                            : "col-xl-7 col-xxl-7"
                        }`}
                      >
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="form-label w-auto">
                              Descripción
                            </label>
                          </div>
                          <textarea
                            className="form-control w-100"
                            data-kt-element="input"
                            rows={2}
                            style={{ resize: "none" }}
                            placeholder="Agregue una descripción"
                            maxLength={255}
                            {...packagesForm.getFieldProps("descripcion")}
                          ></textarea>
                        </div>
                      </div>
                      <div
                        className={`col-sm-12 col-md-12 col-lg-12 p-0 ${
                          isOpenHistoryString === "true"
                            ? "col-xl-12 col-xxl-12"
                            : "col-xl-5 col-xxl-5"
                        }`}
                      >
                        <div className="d-flex justify-content-center ">
                          <div
                            className="w-75 border border-gray-300 rounded mt-8 d-flex justify-content-center align-items-center"
                            style={{ height: "64px" }}
                          >
                            <div className="text-center">
                              <span
                                className="fs-2x fw-bold"
                                style={{ color: "#1B84FF" }}
                              >
                                <CountUp
                                  end={
                                    formatTotalSingle(costOfProducts, 2) +
                                    formatTotalSingle(costOfServices, 2)
                                  }
                                  duration={1}
                                  prefix="$"
                                  decimals={2}
                                />
                              </span>

                              <span className="fs-7 fw-semibold opacity-50">
                                {codeInter && (
                                  <>
                                    /{" "}
                                    <span data-kt-element="period">
                                      {codeInter}
                                    </span>
                                  </>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {id > 0 && (
                    <div className="flex-grow-2">
                      <div className="d-flex mb-4 justify-content-end me-8">
                        <div className="d-flex justify-content-end flex-shrink-0">
                          <a
                            href="#/"
                            data-kt-menu="true"
                            className="btn btn-sm btn-icon btn-light-primary "
                            data-kt-menu-trigger="hover"
                            data-kt-menu-placement="bottom-end"
                          >
                            <KTSVG
                              path="/media/icons/duotune/general/gen052.svg"
                              className="svg-icon-5 svg-icon-gray-500 me-1"
                            />
                          </a>
                          <div
                            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                            data-kt-menu="true"
                          >
                            <div className="menu-item px-3">
                              <a
                                className="menu-link px-3"
                                data-kt-users-table-filter="delete_row"
                                onClick={() => {
                                  // if (
                                  //   initValues.existUpdatesProducts ||
                                  //   initValues.existUpdatesServices
                                  // ) {
                                  //   updateCostConcepts(id);
                                  // } else {
                                  //   SimpleAlert(
                                  //     "El paquete está actualizado.",
                                  //     3000,
                                  //     "success"
                                  //   );
                                  // }
                                  updateCostConcepts(id);
                                }}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/arrows/arr029.svg"
                                  className="svg-icon-6 mr-05"
                                />
                                Actualizar precios
                              </a>
                            </div>

                            <div className="menu-item px-3">
                              <a
                                className="menu-link px-3"
                                data-kt-users-table-filter="delete_row"
                                onClick={() => {
                                  generateCopy(id);
                                }}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/general/gen054.svg"
                                  className="svg-icon-6 mr-05"
                                />
                                Hacer una copia
                              </a>
                            </div>
                            <div className="menu-item px-3">
                              <a
                                className="menu-link px-3"
                                data-kt-users-table-filter="delete_row"
                                onClick={() => {
                                  deletePackage(id);
                                }}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/general/gen027.svg"
                                  className="svg-icon-6 mr-05 "
                                />
                                Borrar
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`mt-5 col-sm-12 col-xxl-4 ${
                  isOpenHistoryString === "true"
                    ? "col-md-6 col-lg-6 col-xl-6"
                    : "col-md-4 col-lg-4 col-xl-4"
                }`}
              >
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">
                      Unidad de negocio
                    </label>
                  </div>
                  <Select
                    value={
                      packagesForm.values.unidad_negocio_id
                        ? unitMeasure.find(
                            (option: any) =>
                              option.value ===
                              packagesForm.values.unidad_negocio_id
                          )
                        : null
                    }
                    options={unitMeasure}
                    styles={styleSelect}
                    placeholder={"Seleccione"}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{"No se encontraron registros"}</span>;
                    }}
                    onChange={changeUnitMeasurement}
                    isLoading={loadingUM}
                    isClearable
                    backspaceRemovesValue
                  />
                </div>
              </div>
              <div
                className={`mt-5 col-sm-12 col-xxl-4 ${
                  isOpenHistoryString === "true"
                    ? "col-md-6 col-lg-6 col-xl-6"
                    : "col-md-4 col-lg-4 col-xl-4"
                }`}
              >
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required form-label w-auto">
                      Categoría
                    </label>
                  </div>
                  <Select
                    options={categories}
                    value={
                      packagesForm.values.categoria_id
                        ? categories.find(
                            (option) =>
                              option.value === packagesForm.values.categoria_id
                          )
                        : null
                    }
                    // value={selectedOptionCategory}
                    styles={styleSelectPackages}
                    placeholder={"Seleccione"}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{"No se encontraron registros"}</span>;
                    }}
                    onChange={changeCategory}
                    isLoading={loadingCategories}
                    backspaceRemovesValue
                  />
                  {packagesForm.touched.categoria_id &&
                    packagesForm.errors.categoria_id && (
                      <div className="fv-plugins-message-container text-danger w-100 mt-3">
                        <span role="alert">
                          {packagesForm.errors.categoria_id}
                        </span>
                      </div>
                    )}
                </div>
              </div>
              <div
                className={`mt-5 col-sm-12 col-xxl-4 ${
                  isOpenHistoryString === "true"
                    ? "col-md-6 col-lg-6 col-xl-6"
                    : "col-md-4 col-lg-4 col-xl-4"
                }`}
              >
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Subcategoría</label>
                  </div>
                  <Select
                    isDisabled={packagesForm.values.categoria_id <= 1}
                    options={subcategory}
                    styles={styleSelectPackages}
                    placeholder={"Seleccione"}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{"No se encontraron registros"}</span>;
                    }}
                    onChange={changeSubcategory}
                    value={selectedOptionSubcategory}
                    isLoading={loadingSC}
                    backspaceRemovesValue
                  />
                </div>
              </div>
              <div
                className={`mt-5 col-sm-12 col-xxl-4 ${
                  isOpenHistoryString === "true"
                    ? "col-md-6 col-lg-6 col-xl-6"
                    : "col-md-4 col-lg-4 col-xl-4"
                }`}
              >
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Tipo de paquete</label>
                  </div>
                  <Select
                    value={
                      packagesForm.values.tipo_paquete_id
                        ? PackagesType.find(
                            (option) =>
                              option.value ===
                              packagesForm.values.tipo_paquete_id
                          )
                        : null
                    }
                    options={PackagesType}
                    styles={styleSelect}
                    placeholder={"Seleccione"}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{"No se encontraron registros"}</span>;
                    }}
                    onChange={changePackagesType}
                    isLoading={loadingPT}
                    isClearable
                    backspaceRemovesValue
                  />
                </div>
              </div>
              <div
                className={`mt-5 col-sm-12 col-xxl-4 ${
                  isOpenHistoryString === "true"
                    ? "col-md-6 col-lg-6 col-xl-6"
                    : "col-md-4 col-lg-4 col-xl-4"
                }`}
              >
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required form-label w-auto">
                      Tipo de moneda
                    </label>
                  </div>
                  <Select
                    options={currencyUnit}
                    // value={selectedOptionCurrency}
                    value={
                      packagesForm.values.unidad_moneda_id
                        ? currencyUnit.find(
                            (option) =>
                              option.value ===
                              packagesForm.values.unidad_moneda_id
                          )
                        : null
                    }
                    styles={styleSelect}
                    placeholder={"Seleccione"}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{"No se encontraron registros"}</span>;
                    }}
                    onChange={changeCurrencyUnit}
                    isLoading={loadingCU}
                    // isClearable
                    backspaceRemovesValue
                  />
                  {packagesForm.touched.unidad_moneda_id &&
                    packagesForm.errors.unidad_moneda_id && (
                      <div className="fv-plugins-message-container text-danger w-100 mt-3">
                        <span role="alert">
                          {packagesForm.errors.unidad_moneda_id}
                        </span>
                      </div>
                    )}
                </div>
              </div>
              <div
                className={`col-sm-12 col-md-12 col-lg-12 col-xxl-4 mt-5 ${
                  isOpenHistoryString === "true" ? "col-xl-12" : "col-xl-4"
                }`}
              >
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required form-label w-auto">
                      Clasificación del paquete
                    </label>
                  </div>
                  <div className="d-flex fv-row mt-3">
                    {PackagesClasification.map((item, index) => (
                      <div
                        className="form-check form-check-custom form-check-solid ms-10"
                        key={item.id}
                      >
                        <input
                          className="form-check-input me-3"
                          type="radio"
                          id={`radio-${item.id}`}
                          value={item.id}
                          name="roles"
                          onChange={(event) => {
                            const clasificationId = event.currentTarget.value;
                            if (clasificationId) {
                              packagesForm.setFieldValue(
                                `clasif_paquete_id`,
                                clasificationId
                              );
                            }
                          }}
                          checked={
                            Number(item.id) ===
                            Number(packagesForm.values.clasif_paquete_id)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`radio-${item.id}`}
                        >
                          <div className="fw-bolder text-gray-800">
                            {item.name}
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                  {packagesForm.touched.clasif_paquete_id &&
                    packagesForm.errors.clasif_paquete_id && (
                      <div className="fv-plugins-message-container text-danger w-100 mt-6">
                        <span role="alert">
                          {packagesForm.errors.clasif_paquete_id}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-6 mb-xl-9">
          <div
            className="card-header cursor-pointer"
            onClick={() => {
              setOpenSection(!openSection);
              setOpenSectionService(false);
              setOpenSectionTotals(false);
            }}
            aria-controls="collapseProducts"
            aria-expanded={openSection}
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Productos</h3>
            </div>
            <div className="d-flex align-items-center">
              <div className="text-center me-5">
                <span className="fs-1 fw-bold" style={{ color: "#1B84FF" }}>
                  <CountUp
                    end={formatTotalSingle(costOfProducts, 2)}
                    duration={1}
                    prefix="$"
                    decimals={2}
                  />
                </span>

                <span className="fs-7 fw-semibold opacity-50">
                  {codeInter && (
                    <>
                      / <span data-kt-element="period">{codeInter}</span>
                    </>
                  )}
                </span>
              </div>
              <div
                className={`align-self-center`}
                style={{
                  transform: openSection
                    ? "translateX(10%) rotate(-180deg)"
                    : "",
                  transition: "transform 0.3s ease",
                }}
              >
                <KTSVG
                  path={"/media/icons/duotune/arrows/arr072.svg"}
                  className={`svg-icon-1`}
                />
              </div>
            </div>
          </div>
          <Collapse in={openSection}>
            <div id="collapseProducts">
              <div className="card-body border-top p-9">
                <ProductsList
                  data={productsList}
                  onChangePriceProducts={updatePriceProducts}
                  codeCurrency={codeInter}
                  exchangeRate={exchangeRate}
                  updateCostProductos={updateCostProductos}
                  packageId={currentId}
                  formik={packagesForm}
                />
              </div>
            </div>
          </Collapse>
        </div>
        <div className="card mb-6 mb-xl-9">
          <div
            className="card-header cursor-pointer"
            onClick={() => {
              setOpenSectionService(!openSectionService);
              setOpenSection(false);
              setOpenSectionTotals(false);
            }}
            aria-controls="collapseServices"
            aria-expanded={openSectionService}
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Servicios</h3>
            </div>
            <div className="d-flex align-items-center">
              <div className="text-center me-5">
                <span className="fs-1 fw-bold" style={{ color: "#1B84FF" }}>
                  <CountUp
                    end={formatTotalSingle(costOfServices, 2)}
                    duration={1}
                    prefix="$"
                    decimals={2}
                  />
                </span>

                <span className="fs-7 fw-semibold opacity-50">
                  {codeInter && (
                    <>
                      / <span data-kt-element="period">{codeInter}</span>
                    </>
                  )}
                </span>
              </div>

              <div
                className={`align-self-center`}
                style={{
                  transform: openSectionService
                    ? "translateX(10%) rotate(-180deg)"
                    : "",
                  transition: "transform 0.3s ease",
                }}
              >
                <KTSVG
                  path={"/media/icons/duotune/arrows/arr072.svg"}
                  className={`svg-icon-1`}
                />
              </div>
            </div>
          </div>
          <Collapse in={openSectionService}>
            <div id="collapseServices">
              <div className="card-body border-top p-9">
                <ServicesList
                  data={servicesList}
                  onChangePriceServices={updatePriceServices}
                  codeCurrency={codeInter}
                  exchangeRate={exchangeRate}
                  updateCostServices={updateCostServices}
                  packageId={currentId}
                  formik={packagesForm}
                />
              </div>
            </div>
          </Collapse>
        </div>
        <div className="card mb-6 mb-xl-9">
          <div
            className="card-header cursor-pointer"
            onClick={() => {
              setOpenSectionTotals(!openSectionTotals);
              setOpenSectionService(false);
              setOpenSection(false);
            }}
            aria-controls="collapseTotals"
            aria-expanded={openSectionTotals}
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Totales</h3>
            </div>
            <div className="d-flex align-items-center">
              <div className="text-center me-5">
                <span className="fs-1 fw-bold" style={{ color: "#1B84FF" }}>
                  <CountUp
                    end={
                      formatTotalSingle(costOfProducts, 2) +
                      formatTotalSingle(costOfServices, 2)
                    }
                    duration={1}
                    prefix="$"
                    decimals={2}
                  />
                </span>

                <span className="fs-7 fw-semibold opacity-50">
                  {codeInter && (
                    <>
                      / <span data-kt-element="period">{codeInter}</span>
                    </>
                  )}
                </span>
              </div>
              <div
                className={`align-self-center`}
                style={{
                  transform: openSectionTotals
                    ? "translateX(10%) rotate(-180deg)"
                    : "",
                  transition: "transform 0.3s ease",
                }}
              >
                <KTSVG
                  path={"/media/icons/duotune/arrows/arr072.svg"}
                  className={`svg-icon-1`}
                />
              </div>
            </div>
          </div>
          <Collapse in={openSectionTotals}>
            <div id="collapseTotals">
              <div className="card-body border-top p-9">
                <div className="d-flex justify-content-end">
                  <div className="w-300px">
                    <div className="d-flex flex-stack mb-3">
                      <div className="fw-semibold pe-10 text-gray-600 fs-7">
                        Subtotal productos:
                      </div>

                      <div className="text-end fw-bold fs-6 text-gray-800">
                        {`${formatPrice(
                          Number((costOfProducts - taxOfProducts).toFixed(2)),
                          "es-MX",
                          "MXN",
                          2,
                          2
                        )}`}
                      </div>
                    </div>

                    <div className="d-flex flex-stack mb-3">
                      <div className="fw-semibold pe-10 text-gray-600 fs-7">
                        Subtotal servicios:
                      </div>

                      <div className="text-end fw-bold fs-6 text-gray-800">
                        {`${formatPrice(
                          Number((costOfServices - taxOfServices).toFixed(2)),
                          "es-MX",
                          "MXN",
                          2,
                          2
                        )}`}
                      </div>
                    </div>

                    <div className="d-flex flex-stack mb-3">
                      <div className="fw-semibold pe-10 text-gray-600 fs-7">
                        Impuestos:
                      </div>

                      <div className="text-end fw-bold fs-6 text-gray-800">
                        {`${formatPrice(
                          Number((taxOfProducts + taxOfServices).toFixed(2)),
                          "es-MX",
                          "MXN",
                          2,
                          2
                        )}`}
                      </div>
                    </div>

                    <div className="d-flex flex-stack">
                      <div className="fw-semibold pe-10 text-gray-600 fs-7">
                        Total:
                      </div>

                      <div className="text-end fw-bold fs-6 text-gray-800">
                        {`${formatPrice(
                          Number((costOfProducts + costOfServices).toFixed(2)),
                          "es-MX",
                          "MXN",
                          2,
                          2
                        )}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        <div className="card mb-6 mb-xl-9">
          <div className="card-body">
            <div className="row">
              <div className="d-flex justify-content-end">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  disabled={loading}
                >
                  {!loading && <span className="indicator-label">Guardar</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      {id && !loadingRequest ? (
        <div className="row g-6 g-xl-9" ref={sectionTabs}>
          <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
            <div className="card card-flush p-5">
              <div className="row mb-5">
                <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                  <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === "NOTE" ? "text-active-primary active" : ""
                        } `}
                        onClick={() => {
                          setTabs("NOTE");
                        }}
                      >
                        <span className="margin-left-05">Notas</span>
                      </div>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === "DOC" ? "text-active-primary active" : ""
                        } `}
                        onClick={() => {
                          setTabs("DOC");
                        }}
                      >
                        <span className="margin-left-05">Documentos</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                {tabs === "NOTE" ? (
                  <NoteList
                    data={notes}
                    onOpenModal={openModal}
                    active={activeNewNote}
                    onOpenDetailModal={openDetailModal}
                  ></NoteList>
                ) : null}
                {tabs === "DOC" ? (
                  <>
                    <DocumentsList
                      documents={dataDocuments || []}
                      onRefreshData={() => {
                        setReloadDocuments(Math.random() * 100);
                        onRefreshHistory();
                      }}
                      id={initValues.id}
                      isEdit={false}
                    ></DocumentsList>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <CreateNoteDetailModal
        show={showNoteDetailModal}
        handleClose={(refresh) => {
          setShowNoteDetailModal(!showNoteDetailModal);
          onRefreshHistory();
        }}
        data={notes}
        id={id}
      ></CreateNoteDetailModal>
      <CategoryModal
        show={isOpenCategoryModal}
        handleClose={(isNew: boolean) => {
          if (isNew) {
            setReloadCategoryList(Math.random() * 40);
            setSelectCategoryAdding(true);
          } else {
            setSelectedOptionCategory(null);
            setCategoryId(0);
            packagesForm.setFieldValue("categoria_id", 0);
          }
          setIsOpenCategoryModal(!isOpenCategoryModal);
        }}
      ></CategoryModal>
      <SubcategoryModal
        show={isOpenSubcategoryModal}
        handleClose={(isNew: boolean) => {
          if (isNew) {
            setReloadSubcategoryList(Math.random() * 40);
            setSelectSubcategoryAdding(true);
          } else {
            setSelectedOptionSubcategory(null);
            packagesForm.setFieldValue("sub_categoria_id", 0);
          }
          setIsOpenSubcategoryModal(!isOpenSubcategoryModal);
        }}
        category={selectedOptionCategory}
      ></SubcategoryModal>
      <CreateNotePackageModal
        show={showNoteModal}
        handleClose={(refresh) => {
          setShowNoteModal(!showNoteModal);
          if (refresh) {
            setReloadGridNotes(Math.random() * 40);
            onRefreshHistory();
          }
        }}
        id={Number(id)}
      ></CreateNotePackageModal>
    </>
  );
};
export { PackagesForm };
