import { FC } from 'react';
import { PageTitle, PageLink } from '../../../_metronic/layout/core';
import { View } from './components/View';
// import { ViewAddEdit } from './components/ViewAddEdit';
import { useParams } from 'react-router-dom';
import { decryptId } from '../../../_metronic/helpers';

const NotePackagePage: FC = () => {
  const { id_package, id } = useParams<{ id_package: string; id: string }>();

  const noteId = decryptId(id);
  const packageId = decryptId(id_package);
  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Paquetes',
      path: '/cotizaciones/paquetes/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Editar',
      path: `/cotizaciones/paquetes/edit/${id_package}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Notas',
      path: '#/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {noteId ? 'Editar nota' : 'Nueva nota'}
      </PageTitle>

      <View
        id={noteId ? noteId : '0'}
        packageId={packageId ? packageId : '0'}
      />
    </>
  );
};

export { NotePackagePage };
