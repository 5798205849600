import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import { Collapse } from "react-bootstrap-v5";
import { KTSVG } from "../../../../../_metronic/helpers";
import { ConceptsList } from "./ConceptsList";

type Props = {
  sections: any[];
  updateCostConcepts: number;
  codeInter: string;
  exchangeRate: number;
  updateSectionTitle: (index: number, title: string) => void;
  handleTitleBlur: (index: number) => void;
  removeSection: (index: number) => void;
};

type OpenSections = {
  [key: number]: boolean;
};

const SectionsList: React.FC<Props> = ({
  sections,
  updateCostConcepts,
  codeInter,
  exchangeRate,
  updateSectionTitle,
  handleTitleBlur,
  removeSection,
}) => {
  const [openSections, setOpenSections] = useState<OpenSections>({});

  const toggleSection = (index: number) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <>
      {sections.map((section: any, index: number) => (
        <div className="card mb-6 mb-xl-9" key={index}>
          <div
            className="card-header cursor-pointer"
            onClick={() => {
              toggleSection(index);
            }}
            aria-controls={`collapseSection${index}`}
          >
            <div className="card-title w-75 m-0">
              <div className="fw-bolder m-0 fs-3 me-2">{section.orden}</div>

              <input
                type="text"
                value={section.nombre}
                placeholder="Nombre de sección"
                style={{ border: "none", paddingLeft: 0 }}
                onChange={(e) => updateSectionTitle(index, e.target.value)}
                onKeyDown={handleKeyDown}
                onBlur={() => handleTitleBlur(index)}
                className="form-control w-100 fw-bolder m-0 fs-3"
              />
            </div>

            <div className="d-flex align-items-center">
              <a
                className="btn btn-sm  btn-outline-danger btn-active-light-danger"
                title="Eliminar sección"
                onClick={(event: any) => {
                  event.preventDefault();
                  removeSection(index);
                }}
              >
                Eliminar
                {/* <KTSVG
                  path="/media/icons/duotune/general/gen027.svg"
                  className="svg-icon-4"
                /> */}
              </a>
            </div>
            <div className="d-flex align-items-center">
              <div className="text-center me-5 ms-3">
                <span className="fs-1 fw-bold" style={{ color: "#1B84FF" }}>
                  <CountUp
                    end={section.subtotal}
                    duration={1}
                    prefix="$"
                    decimals={2}
                  />
                </span>
                <span className="fs-7 fw-semibold opacity-50">
                  {codeInter && (
                    <>
                      / <span data-kt-element="period">{codeInter}</span>
                    </>
                  )}
                </span>
              </div>
              <div
                className={`align-self-center`}
                style={{
                  transform: openSections[index]
                    ? "translateX(10%) rotate(-180deg)"
                    : "",
                  transition: "transform 0.3s ease",
                }}
              >
                <KTSVG
                  path={"/media/icons/duotune/arrows/arr072.svg"}
                  className={`svg-icon-1`}
                />
              </div>
            </div>
          </div>
          <Collapse in={openSections[index]}>
            <div id={`collapseSection${index}`}>
              <div className="card-body border-top p-9">
                <ConceptsList
                  indexSection={index}
                  data={section.detalle}
                  onChangePriceConcepts={section.updatePriceConcepts}
                  codeCurrency={codeInter}
                  exchangeRate={exchangeRate}
                  updateCostConcepts={updateCostConcepts}
                />
              </div>
            </div>
          </Collapse>
        </div>
      ))}
    </>
  );
};

export { SectionsList };
